const nazioni = [
  "ITALIA",
  "ALBANIA",
  "ANDORRA",
  "AUSTRIA",
  "BELGIO",
  "BULGARIA",
  "DANIMARCA",
  "FINLANDIA",
  "FRANCIA",
  "GERMANIA",
  "REGNO UNITO",
  "GRECIA",
  "IRLANDA",
  "ISLANDA",
  "LIECHTENSTEIN",
  "LUSSEMBURGO",
  "MALTA",
  "MONACO",
  "NORVEGIA",
  "PAESI BASSI",
  "POLONIA",
  "PORTOGALLO",
  "ROMANIA",
  "SAN MARINO",
  "SPAGNA",
  "SVEZIA",
  "SVIZZERA",
  "UCRAINA",
  "UNGHERIA",
  "FEDERAZIONE RUSSA",
  "STATO DELLA CITTÀ DEL VATICANO",
  "ESTONIA",
  "LETTONIA",
  "LITUANIA",
  "CROAZIA",
  "SLOVENIA",
  "BOSNIA-ERZEGOVINA",
  "MACEDONIA DEL NORD",
  "MOLDOVA",
  "SLOVACCHIA",
  "BIELORUSSIA",
  "REPUBBLICA CECA",
  "MONTENEGRO",
  "SERBIA",
  "KOSOVO",
  "AFGHANISTAN",
  "ARABIA SAUDITA",
  "BAHREIN",
  "BANGLADESH",
  "BHUTAN",
  "MYANMAR/BIRMANIA",
  "BRUNEI DARUSSALAM",
  "CAMBOGIA",
  "SRI LANKA",
  "CINA",
  "CIPRO",
  "COREA DEL NORD",
  "COREA DEL SUD",
  "EMIRATI ARABI UNITI",
  "FILIPPINE",
  "PALESTINA",
  "GIAPPONE",
  "GIORDANIA",
  "INDIA",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "ISRAELE",
  "KUWAIT",
  "LAOS",
  "LIBANO",
  "TIMOR LESTE",
  "MALDIVE",
  "MALAYSIA",
  "MONGOLIA",
  "NEPAL",
  "OMAN",
  "PAKISTAN",
  "QATAR",
  "SINGAPORE",
  "SIRIA",
  "THAILANDIA",
  "TURCHIA",
  "VIETNAM",
  "YEMEN",
  "KAZAKHSTAN",
  "UZBEKISTAN",
  "ARMENIA",
  "AZERBAIGIAN",
  "GEORGIA",
  "KIRGHIZISTAN",
  "TAGIKISTAN",
  "TAIWAN",
  "TURKMENISTAN",
  "ALGERIA",
  "ANGOLA",
  "COSTA D'AVORIO",
  "BENIN",
  "BOTSWANA",
  "BURKINA FASO",
  "BURUNDI",
  "CAMERUN",
  "CAPO VERDE",
  "REPUBBLICA CENTRAFRICANA",
  "CIAD",
  "COMORE",
  "CONGO",
  "EGITTO",
  "ETIOPIA",
  "GABON",
  "GAMBIA",
  "GHANA",
  "GIBUTI",
  "GUINEA",
  "GUINEA-BISSAU",
  "GUINEA EQUATORIALE",
  "KENYA",
  "LESOTHO",
  "LIBERIA",
  "LIBIA",
  "MADAGASCAR",
  "MALAWI",
  "MALI",
  "MAROCCO",
  "MAURITANIA",
  "MAURIZIO",
  "MOZAMBICO",
  "NAMIBIA",
  "NIGER",
  "NIGERIA",
  "RUANDA",
  "SAO TOMÉ E PRINCIPE",
  "SEYCHELLES",
  "SENEGAL",
  "SIERRA LEONE",
  "SOMALIA",
  "SUDAFRICA",
  "SUDAN",
  "ESWATINI",
  "TANZANIA",
  "TOGO",
  "TUNISIA",
  "UGANDA",
  "REPUBBLICA DEMOCRATICA DEL CONGO",
  "ZAMBIA",
  "ZIMBABWE",
  "ERITREA",
  "SUD SUDAN",
  "ANTIGUA E BARBUDA",
  "BAHAMAS",
  "BARBADOS",
  "BELIZE",
  "CANADA",
  "COSTA RICA",
  "CUBA",
  "DOMINICA",
  "REPUBBLICA DOMINICANA",
  "EL SALVADOR",
  "GIAMAICA",
  "GRENADA",
  "GUATEMALA",
  "HAITI",
  "HONDURAS",
  "MESSICO",
  "NICARAGUA",
  "PANAMA",
  "SANTA LUCIA",
  "SAINT VINCENT E GRENADINE",
  "SAINT KITTS E NEVIS",
  "STATI UNITI D'AMERICA",
  "ARGENTINA",
  "BOLIVIA",
  "BRASILE",
  "CILE",
  "COLOMBIA",
  "ECUADOR",
  "GUYANA",
  "PARAGUAY",
  "PERÙ",
  "SURINAME",
  "TRINIDAD E TOBAGO",
  "URUGUAY",
  "VENEZUELA",
  "AUSTRALIA",
  "FIGI",
  "KIRIBATI",
  "ISOLE MARSHALL",
  "STATI FEDERATI DI MICRONESIA",
  "NAURU",
  "NUOVA ZELANDA",
  "PALAU",
  "PAPUA NUOVA GUINEA",
  "ISOLE SALOMONE",
  "SAMOA",
  "TONGA",
  "TUVALU",
  "VANUATU",
  "NUOVA CALEDONIA",
  "SAINT-MARTIN (FR)",
  "SAHARA OCCIDENTALE",
  "SAINT-BARTHÉLEMY",
  "BERMUDA",
  "ISOLE COOK (NZ)",
  "GIBILTERRA",
  "ISOLE CAYMAN",
  "ANGUILLA",
  "POLINESIA FRANCESE",
  "ISOLE FÆR ØER",
  "JERSEY",
  "ARUBA",
  "SINT MAARTEN (NL)",
  "GROENLANDIA",
  "SARK",
  "GUERNSEY",
  "ISOLE FALKLAND (MALVINE)",
  "ISOLA DI MAN",
  "MONTSERRAT",
  "CURAÇAO",
  "ISOLE PITCAIRN",
  "SAINT PIERRE E MIQUELON",
  "SANT'ELENA",
  "TERRE AUSTRALI E ANTARTICHE FRANCESI",
  "ISOLE TURKS E CAICOS",
  "ISOLE VERGINI BRITANNICHE",
  "WALLIS E FUTUNA",
];

const comuni = [
  "ABANO TERME",
  "ABBADIA CERRETO",
  "ABBADIA LARIANA",
  "ABBADIA SAN SALVATORE",
  "ABBASANTA",
  "ABBATEGGIO",
  "ABBIATEGRASSO",
  "ABETONE CUTIGLIANO",
  "ABRIOLA",
  "ACATE",
  "ACCADIA",
  "ACCEGLIO",
  "ACCETTURA",
  "ACCIANO",
  "ACCUMOLI",
  "ACERENZA",
  "ACERNO",
  "ACERRA",
  "ACI BONACCORSI",
  "ACI CASTELLO",
  "ACI CATENA",
  "ACI SANT'ANTONIO",
  "ACIREALE",
  "ACQUAFONDATA",
  "ACQUAFORMOSA",
  "ACQUAFREDDA",
  "ACQUALAGNA",
  "ACQUANEGRA CREMONESE",
  "ACQUANEGRA SUL CHIESE",
  "ACQUAPENDENTE",
  "ACQUAPPESA",
  "ACQUARO",
  "ACQUASANTA TERME",
  "ACQUASPARTA",
  "ACQUAVIVA COLLECROCE",
  "ACQUAVIVA PICENA",
  "ACQUAVIVA PLATANI",
  "ACQUAVIVA D'ISERNIA",
  "ACQUAVIVA DELLE FONTI",
  "ACQUEDOLCI",
  "ACQUI TERME",
  "ACRI",
  "ACUTO",
  "ADELFIA",
  "ADRANO",
  "ADRARA SAN MARTINO",
  "ADRARA SAN ROCCO",
  "ADRIA",
  "ADRO",
  "AFFI",
  "AFFILE",
  "AFRAGOLA",
  "AFRICO",
  "AGAZZANO",
  "AGEROLA",
  "AGGIUS",
  "AGIRA",
  "AGLIANA",
  "AGLIANO TERME",
  "AGLIENTU",
  "AGLIÈ",
  "AGNA",
  "AGNADELLO",
  "AGNANA CALABRA",
  "AGNONE",
  "AGNOSINE",
  "AGORDO",
  "AGOSTA",
  "AGRA",
  "AGRATE BRIANZA",
  "AGRATE CONTURBIA",
  "AGRIGENTO",
  "AGROPOLI",
  "AGUGLIANO",
  "AGUGLIARO",
  "AICURZIO",
  "AIDOMAGGIORE",
  "AIDONE",
  "AIELLI",
  "AIELLO CALABRO",
  "AIELLO DEL FRIULI",
  "AIELLO DEL SABATO",
  "AIETA",
  "AILANO",
  "AILOCHE",
  "AIRASCA",
  "AIROLA",
  "AIROLE",
  "AIRUNO",
  "AISONE",
  "ALA",
  "ALA DI STURA",
  "ALAGNA",
  "ALAGNA VALSESIA",
  "ALANNO",
  "ALANO DI PIAVE",
  "ALASSIO",
  "ALATRI",
  "ALBA",
  "ALBA ADRIATICA",
  "ALBAGIARA",
  "ALBAIRATE",
  "ALBANELLA",
  "ALBANO LAZIALE",
  "ALBANO SANT'ALESSANDRO",
  "ALBANO VERCELLESE",
  "ALBANO DI LUCANIA",
  "ALBAREDO ARNABOLDI",
  "ALBAREDO D'ADIGE",
  "ALBAREDO PER SAN MARCO",
  "ALBARETO",
  "ALBARETTO DELLA TORRE",
  "ALBAVILLA",
  "ALBENGA",
  "ALBERA LIGURE",
  "ALBEROBELLO",
  "ALBERONA",
  "ALBESE CON CASSANO",
  "ALBETTONE",
  "ALBI",
  "ALBIANO",
  "ALBIANO D'IVREA",
  "ALBIATE",
  "ALBIDONA",
  "ALBIGNASEGO",
  "ALBINEA",
  "ALBINO",
  "ALBIOLO",
  "ALBISOLA SUPERIORE",
  "ALBISSOLA MARINA",
  "ALBIZZATE",
  "ALBONESE",
  "ALBOSAGGIA",
  "ALBUGNANO",
  "ALBUZZANO",
  "ALCAMO",
  "ALCARA LI FUSI",
  "ALDENO",
  "ALDINO",
  "ALES",
  "ALESSANDRIA",
  "ALESSANDRIA DEL CARRETTO",
  "ALESSANDRIA DELLA ROCCA",
  "ALESSANO",
  "ALEZIO",
  "ALFANO",
  "ALFEDENA",
  "ALFIANELLO",
  "ALFIANO NATTA",
  "ALFONSINE",
  "ALGHERO",
  "ALGUA",
  "ALIA",
  "ALIANO",
  "ALICE BEL COLLE",
  "ALICE CASTELLO",
  "ALIFE",
  "ALIMENA",
  "ALIMINUSA",
  "ALLAI",
  "ALLEGHE",
  "ALLEIN",
  "ALLERONA",
  "ALLISTE",
  "ALLUMIERE",
  "ALLUVIONI PIOVERA",
  "ALMENNO SAN BARTOLOMEO",
  "ALMENNO SAN SALVATORE",
  "ALMESE",
  "ALMÈ",
  "ALONTE",
  "ALPAGO",
  "ALPETTE",
  "ALPIGNANO",
  "ALSENO",
  "ALSERIO",
  "ALTA VAL TIDONE",
  "ALTA VALLE INTELVI",
  "ALTAMURA",
  "ALTARE",
  "ALTAVALLE",
  "ALTAVILLA IRPINA",
  "ALTAVILLA MILICIA",
  "ALTAVILLA MONFERRATO",
  "ALTAVILLA SILENTINA",
  "ALTAVILLA VICENTINA",
  "ALTIDONA",
  "ALTILIA",
  "ALTINO",
  "ALTISSIMO",
  "ALTIVOLE",
  "ALTO",
  "ALTO RENO TERME",
  "ALTO SERMENZA",
  "ALTOFONTE",
  "ALTOMONTE",
  "ALTOPASCIO",
  "ALTOPIANO DELLA VIGOLANA",
  "ALVIANO",
  "ALVIGNANO",
  "ALVITO",
  "ALZANO LOMBARDO",
  "ALZANO SCRIVIA",
  "ALZATE BRIANZA",
  "ALÀ DEI SARDI",
  "ALÌ",
  "ALÌ TERME",
  "AMALFI",
  "AMANDOLA",
  "AMANTEA",
  "AMARO",
  "AMARONI",
  "AMASENO",
  "AMATO",
  "AMATRICE",
  "AMBIVERE",
  "AMBLAR-DON",
  "AMEGLIA",
  "AMELIA",
  "AMENDOLARA",
  "AMENO",
  "AMOROSI",
  "AMPEZZO",
  "ANACAPRI",
  "ANAGNI",
  "ANCARANO",
  "ANCONA",
  "ANDALI",
  "ANDALO",
  "ANDALO VALTELLINO",
  "ANDEZENO",
  "ANDORA",
  "ANDORNO MICCA",
  "ANDRANO",
  "ANDRATE",
  "ANDREIS",
  "ANDRETTA",
  "ANDRIA",
  "ANDRIANO",
  "ANELA",
  "ANFO",
  "ANGERA",
  "ANGHIARI",
  "ANGIARI",
  "ANGOLO TERME",
  "ANGRI",
  "ANGROGNA",
  "ANGUILLARA SABAZIA",
  "ANGUILLARA VENETA",
  "ANNICCO",
  "ANNONE VENETO",
  "ANNONE DI BRIANZA",
  "ANOIA",
  "ANTEGNATE",
  "ANTERIVO",
  "ANTEY-SAINT-ANDRÉ",
  "ANTICOLI CORRADO",
  "ANTIGNANO",
  "ANTILLO",
  "ANTONIMINA",
  "ANTRODOCO",
  "ANTRONA SCHIERANCO",
  "ANVERSA DEGLI ABRUZZI",
  "ANZANO DEL PARCO",
  "ANZANO DI PUGLIA",
  "ANZI",
  "ANZIO",
  "ANZOLA D'OSSOLA",
  "ANZOLA DELL'EMILIA",
  "AOSTA",
  "APECCHIO",
  "APICE",
  "APIRO",
  "APOLLOSA",
  "APPIANO GENTILE",
  "APPIANO SULLA STRADA DEL VINO",
  "APPIGNANO",
  "APPIGNANO DEL TRONTO",
  "APRICA",
  "APRICALE",
  "APRICENA",
  "APRIGLIANO",
  "APRILIA",
  "AQUARA",
  "AQUILA D'ARROSCIA",
  "AQUILEIA",
  "AQUILONIA",
  "AQUINO",
  "ARADEO",
  "ARAGONA",
  "ARAMENGO",
  "ARBA",
  "ARBOREA",
  "ARBORIO",
  "ARBUS",
  "ARCADE",
  "ARCE",
  "ARCENE",
  "ARCEVIA",
  "ARCHI",
  "ARCIDOSSO",
  "ARCINAZZO ROMANO",
  "ARCISATE",
  "ARCO",
  "ARCOLA",
  "ARCOLE",
  "ARCONATE",
  "ARCORE",
  "ARCUGNANO",
  "ARDARA",
  "ARDAULI",
  "ARDEA",
  "ARDENNO",
  "ARDESIO",
  "ARDORE",
  "ARENA",
  "ARENA PO",
  "ARENZANO",
  "ARESE",
  "AREZZO",
  "ARGEGNO",
  "ARGELATO",
  "ARGENTA",
  "ARGENTERA",
  "ARGUELLO",
  "ARGUSTO",
  "ARI",
  "ARIANO IRPINO",
  "ARIANO NEL POLESINE",
  "ARICCIA",
  "ARIELLI",
  "ARIENZO",
  "ARIGNANO",
  "ARITZO",
  "ARIZZANO",
  "ARLENA DI CASTRO",
  "ARLUNO",
  "ARMENO",
  "ARMENTO",
  "ARMO",
  "ARMUNGIA",
  "ARNAD",
  "ARNARA",
  "ARNASCO",
  "ARNESANO",
  "AROLA",
  "ARONA",
  "AROSIO",
  "ARPAIA",
  "ARPAISE",
  "ARPINO",
  "ARQUATA SCRIVIA",
  "ARQUATA DEL TRONTO",
  "ARQUÀ PETRARCA",
  "ARQUÀ POLESINE",
  "ARRE",
  "ARRONE",
  "ARSAGO SEPRIO",
  "ARSIERO",
  "ARSITA",
  "ARSIÈ",
  "ARSOLI",
  "ARTA TERME",
  "ARTEGNA",
  "ARTENA",
  "ARTOGNE",
  "ARVIER",
  "ARZACHENA",
  "ARZAGO D'ADDA",
  "ARZANA",
  "ARZANO",
  "ARZERGRANDE",
  "ARZIGNANO",
  "ASCEA",
  "ASCIANO",
  "ASCOLI PICENO",
  "ASCOLI SATRIANO",
  "ASCREA",
  "ASIAGO",
  "ASIGLIANO VENETO",
  "ASIGLIANO VERCELLESE",
  "ASOLA",
  "ASOLO",
  "ASSAGO",
  "ASSEMINI",
  "ASSISI",
  "ASSO",
  "ASSOLO",
  "ASSORO",
  "ASTI",
  "ASUNI",
  "ATELETA",
  "ATELLA",
  "ATENA LUCANA",
  "ATESSA",
  "ATINA",
  "ATRANI",
  "ATRI",
  "ATRIPALDA",
  "ATTIGLIANO",
  "ATTIMIS",
  "ATZARA",
  "AUGUSTA",
  "AULETTA",
  "AULLA",
  "AURANO",
  "AURIGO",
  "AURONZO DI CADORE",
  "AUSONIA",
  "AUSTIS",
  "AVEGNO",
  "AVELENGO",
  "AVELLA",
  "AVELLINO",
  "AVERARA",
  "AVERSA",
  "AVETRANA",
  "AVEZZANO",
  "AVIANO",
  "AVIATICO",
  "AVIGLIANA",
  "AVIGLIANO",
  "AVIGLIANO UMBRO",
  "AVIO",
  "AVISE",
  "AVOLA",
  "AVOLASCA",
  "AYAS",
  "AYMAVILLES",
  "AZEGLIO",
  "AZZANELLO",
  "AZZANO DECIMO",
  "AZZANO MELLA",
  "AZZANO SAN PAOLO",
  "AZZANO D'ASTI",
  "AZZATE",
  "AZZIO",
  "AZZONE",
  "BACENO",
  "BACOLI",
  "BADALUCCO",
  "BADESI",
  "BADIA",
  "BADIA CALAVENA",
  "BADIA PAVESE",
  "BADIA POLESINE",
  "BADIA TEDALDA",
  "BADOLATO",
  "BAGALADI",
  "BAGHERIA",
  "BAGNACAVALLO",
  "BAGNARA CALABRA",
  "BAGNARA DI ROMAGNA",
  "BAGNARIA",
  "BAGNARIA ARSA",
  "BAGNASCO",
  "BAGNATICA",
  "BAGNI DI LUCCA",
  "BAGNO A RIPOLI",
  "BAGNO DI ROMAGNA",
  "BAGNOLI IRPINO",
  "BAGNOLI DEL TRIGNO",
  "BAGNOLI DI SOPRA",
  "BAGNOLO CREMASCO",
  "BAGNOLO MELLA",
  "BAGNOLO PIEMONTE",
  "BAGNOLO SAN VITO",
  "BAGNOLO DEL SALENTO",
  "BAGNOLO DI PO",
  "BAGNOLO IN PIANO",
  "BAGNONE",
  "BAGNOREGIO",
  "BAGOLINO",
  "BAIA E LATINA",
  "BAIANO",
  "BAIRO",
  "BAISO",
  "BAJARDO",
  "BALANGERO",
  "BALDICHIERI D'ASTI",
  "BALDISSERO CANAVESE",
  "BALDISSERO TORINESE",
  "BALDISSERO D'ALBA",
  "BALESTRATE",
  "BALESTRINO",
  "BALLABIO",
  "BALLAO",
  "BALME",
  "BALMUCCIA",
  "BALOCCO",
  "BALSORANO",
  "BALVANO",
  "BALZOLA",
  "BANARI",
  "BANCHETTE",
  "BANNIO ANZINO",
  "BANZI",
  "BAONE",
  "BARADILI",
  "BARAGIANO",
  "BARANELLO",
  "BARANO D'ISCHIA",
  "BARANZATE",
  "BARASSO",
  "BARATILI SAN PIETRO",
  "BARBANIA",
  "BARBARA",
  "BARBARANO MOSSANO",
  "BARBARANO ROMANO",
  "BARBARESCO",
  "BARBARIGA",
  "BARBATA",
  "BARBERINO TAVARNELLE",
  "BARBERINO DI MUGELLO",
  "BARBIANELLO",
  "BARBIANO",
  "BARBONA",
  "BARCELLONA POZZO DI GOTTO",
  "BARCIS",
  "BARD",
  "BARDELLO CON MALGESSO E BREGANO",
  "BARDI",
  "BARDINETO",
  "BARDOLINO",
  "BARDONECCHIA",
  "BAREGGIO",
  "BARENGO",
  "BARESSA",
  "BARETE",
  "BARGA",
  "BARGAGLI",
  "BARGE",
  "BARGHE",
  "BARI",
  "BARI SARDO",
  "BARIANO",
  "BARICELLA",
  "BARILE",
  "BARISCIANO",
  "BARLASSINA",
  "BARLETTA",
  "BARNI",
  "BAROLO",
  "BARONE CANAVESE",
  "BARONISSI",
  "BARRAFRANCA",
  "BARRALI",
  "BARREA",
  "BARUMINI",
  "BARZAGO",
  "BARZANA",
  "BARZANÒ",
  "BARZIO",
  "BASALUZZO",
  "BASCAPÈ",
  "BASCHI",
  "BASCIANO",
  "BASELGA DI PINÈ",
  "BASELICE",
  "BASIANO",
  "BASICÒ",
  "BASIGLIO",
  "BASILIANO",
  "BASSANO BRESCIANO",
  "BASSANO ROMANO",
  "BASSANO DEL GRAPPA",
  "BASSANO IN TEVERINA",
  "BASSIANO",
  "BASSIGNANA",
  "BASTIA MONDOVÌ",
  "BASTIA UMBRA",
  "BASTIDA PANCARANA",
  "BASTIGLIA",
  "BATTAGLIA TERME",
  "BATTIFOLLO",
  "BATTIPAGLIA",
  "BATTUDA",
  "BAUCINA",
  "BAULADU",
  "BAUNEI",
  "BAVENO",
  "BEDERO VALCUVIA",
  "BEDIZZOLE",
  "BEDOLLO",
  "BEDONIA",
  "BEDULITA",
  "BEE",
  "BEINASCO",
  "BEINETTE",
  "BELCASTRO",
  "BELFIORE",
  "BELFORTE MONFERRATO",
  "BELFORTE ALL'ISAURO",
  "BELFORTE DEL CHIENTI",
  "BELGIOIOSO",
  "BELGIRATE",
  "BELLA",
  "BELLAGIO",
  "BELLANO",
  "BELLANTE",
  "BELLARIA-IGEA MARINA",
  "BELLEGRA",
  "BELLINO",
  "BELLINZAGO LOMBARDO",
  "BELLINZAGO NOVARESE",
  "BELLIZZI",
  "BELLONA",
  "BELLOSGUARDO",
  "BELLUNO",
  "BELLUSCO",
  "BELMONTE CALABRO",
  "BELMONTE CASTELLO",
  "BELMONTE MEZZAGNO",
  "BELMONTE PICENO",
  "BELMONTE DEL SANNIO",
  "BELMONTE IN SABINA",
  "BELPASSO",
  "BELSITO",
  "BELVEDERE LANGHE",
  "BELVEDERE MARITTIMO",
  "BELVEDERE OSTRENSE",
  "BELVEDERE DI SPINELLO",
  "BELVEGLIO",
  "BELVÌ",
  "BEMA",
  "BENE LARIO",
  "BENE VAGIENNA",
  "BENESTARE",
  "BENETUTTI",
  "BENEVELLO",
  "BENEVENTO",
  "BENNA",
  "BENTIVOGLIO",
  "BERBENNO",
  "BERBENNO DI VALTELLINA",
  "BERCETO",
  "BERCHIDDA",
  "BEREGAZZO CON FIGLIARO",
  "BEREGUARDO",
  "BERGAMASCO",
  "BERGAMO",
  "BERGANTINO",
  "BERGEGGI",
  "BERGOLO",
  "BERLINGO",
  "BERNALDA",
  "BERNAREGGIO",
  "BERNATE TICINO",
  "BERNEZZO",
  "BERTINORO",
  "BERTIOLO",
  "BERTONICO",
  "BERZANO DI SAN PIETRO",
  "BERZANO DI TORTONA",
  "BERZO DEMO",
  "BERZO INFERIORE",
  "BERZO SAN FERMO",
  "BESANA IN BRIANZA",
  "BESANO",
  "BESATE",
  "BESENELLO",
  "BESENZONE",
  "BESNATE",
  "BESOZZO",
  "BESSUDE",
  "BETTOLA",
  "BETTONA",
  "BEURA-CARDEZZA",
  "BEVAGNA",
  "BEVERINO",
  "BEVILACQUA",
  "BIANCAVILLA",
  "BIANCHI",
  "BIANCO",
  "BIANDRATE",
  "BIANDRONNO",
  "BIANZANO",
  "BIANZONE",
  "BIANZÈ",
  "BIASSONO",
  "BIBBIANO",
  "BIBBIENA",
  "BIBBONA",
  "BIBIANA",
  "BICCARI",
  "BICINICCO",
  "BIDONÌ",
  "BIELLA",
  "BIENNO",
  "BIENO",
  "BIENTINA",
  "BINAGO",
  "BINASCO",
  "BINETTO",
  "BIOGLIO",
  "BIONAZ",
  "BIONE",
  "BIRORI",
  "BISACCIA",
  "BISACQUINO",
  "BISCEGLIE",
  "BISEGNA",
  "BISENTI",
  "BISIGNANO",
  "BISTAGNO",
  "BISUSCHIO",
  "BITETTO",
  "BITONTO",
  "BITRITTO",
  "BITTI",
  "BIVONA",
  "BIVONGI",
  "BIZZARONE",
  "BLEGGIO SUPERIORE",
  "BLELLO",
  "BLERA",
  "BLESSAGNO",
  "BLEVIO",
  "BLUFI",
  "BOARA PISANI",
  "BOBBIO",
  "BOBBIO PELLICE",
  "BOCA",
  "BOCCHIGLIERO",
  "BOCCIOLETO",
  "BOCENAGO",
  "BODIO LOMNAGO",
  "BOFFALORA D'ADDA",
  "BOFFALORA SOPRA TICINO",
  "BOGLIASCO",
  "BOGNANCO",
  "BOGOGNO",
  "BOISSANO",
  "BOJANO",
  "BOLANO",
  "BOLGARE",
  "BOLLATE",
  "BOLLENGO",
  "BOLOGNA",
  "BOLOGNANO",
  "BOLOGNETTA",
  "BOLOGNOLA",
  "BOLOTANA",
  "BOLSENA",
  "BOLTIERE",
  "BOLZANO",
  "BOLZANO NOVARESE",
  "BOLZANO VICENTINO",
  "BOMARZO",
  "BOMBA",
  "BOMPENSIERE",
  "BOMPIETRO",
  "BOMPORTO",
  "BONARCADO",
  "BONASSOLA",
  "BONATE SOPRA",
  "BONATE SOTTO",
  "BONAVIGO",
  "BONDENO",
  "BONDONE",
  "BONEA",
  "BONEFRO",
  "BONEMERSE",
  "BONIFATI",
  "BONITO",
  "BONNANARO",
  "BONO",
  "BONORVA",
  "BONVICINO",
  "BORBONA",
  "BORCA DI CADORE",
  "BORDANO",
  "BORDIGHERA",
  "BORDOLANO",
  "BORE",
  "BORETTO",
  "BORGARELLO",
  "BORGARO TORINESE",
  "BORGETTO",
  "BORGHETTO LODIGIANO",
  "BORGHETTO SANTO SPIRITO",
  "BORGHETTO D'ARROSCIA",
  "BORGHETTO DI BORBERA",
  "BORGHETTO DI VARA",
  "BORGHI",
  "BORGIA",
  "BORGIALLO",
  "BORGIO VEREZZI",
  "BORGO CHIESE",
  "BORGO LARES",
  "BORGO MANTOVANO",
  "BORGO PACE",
  "BORGO PRIOLO",
  "BORGO SAN DALMAZZO",
  "BORGO SAN GIACOMO",
  "BORGO SAN GIOVANNI",
  "BORGO SAN LORENZO",
  "BORGO SAN MARTINO",
  "BORGO SAN SIRO",
  "BORGO TICINO",
  "BORGO TOSSIGNANO",
  "BORGO VAL DI TARO",
  "BORGO VALBELLUNA",
  "BORGO VALSUGANA",
  "BORGO VELINO",
  "BORGO VENETO",
  "BORGO VERCELLI",
  "BORGO VIRGILIO",
  "BORGO A MOZZANO",
  "BORGO D'ALE",
  "BORGO D'ANAUNIA",
  "BORGO DI TERZO",
  "BORGOCARBONARA",
  "BORGOFRANCO D'IVREA",
  "BORGOLAVEZZARO",
  "BORGOMALE",
  "BORGOMANERO",
  "BORGOMARO",
  "BORGOMASINO",
  "BORGOMEZZAVALLE",
  "BORGONE SUSA",
  "BORGONOVO VAL TIDONE",
  "BORGORATTO ALESSANDRINO",
  "BORGORATTO MORMOROLO",
  "BORGORICCO",
  "BORGOROSE",
  "BORGOSATOLLO",
  "BORGOSESIA",
  "BORMIDA",
  "BORMIO",
  "BORNASCO",
  "BORNO",
  "BORONEDDU",
  "BORORE",
  "BORRELLO",
  "BORRIANA",
  "BORSO DEL GRAPPA",
  "BORTIGALI",
  "BORTIGIADAS",
  "BORUTTA",
  "BORZONASCA",
  "BOSA",
  "BOSARO",
  "BOSCHI SANT'ANNA",
  "BOSCO CHIESANUOVA",
  "BOSCO MARENGO",
  "BOSCONERO",
  "BOSCOREALE",
  "BOSCOTRECASE",
  "BOSIA",
  "BOSIO",
  "BOSISIO PARINI",
  "BOSNASCO",
  "BOSSICO",
  "BOSSOLASCO",
  "BOTRICELLO",
  "BOTRUGNO",
  "BOTTANUCO",
  "BOTTICINO",
  "BOTTIDDA",
  "BOVA",
  "BOVA MARINA",
  "BOVALINO",
  "BOVEGNO",
  "BOVES",
  "BOVEZZO",
  "BOVILLE ERNICA",
  "BOVINO",
  "BOVISIO-MASCIAGO",
  "BOVOLENTA",
  "BOVOLONE",
  "BOZZOLE",
  "BOZZOLO",
  "BRA",
  "BRACCA",
  "BRACCIANO",
  "BRACIGLIANO",
  "BRAIES",
  "BRALLO DI PREGOLA",
  "BRANCALEONE",
  "BRANDICO",
  "BRANDIZZO",
  "BRANZI",
  "BRAONE",
  "BREBBIA",
  "BREDA DI PIAVE",
  "BREGANZE",
  "BREGNANO",
  "BREMBATE",
  "BREMBATE DI SOPRA",
  "BREMBIO",
  "BREME",
  "BRENDOLA",
  "BRENNA",
  "BRENNERO",
  "BRENO",
  "BRENTA",
  "BRENTINO BELLUNO",
  "BRENTONICO",
  "BRENZONE SUL GARDA",
  "BRESCELLO",
  "BRESCIA",
  "BRESIMO",
  "BRESSANA BOTTARONE",
  "BRESSANONE",
  "BRESSANVIDO",
  "BRESSO",
  "BREZZO DI BEDERO",
  "BRIAGLIA",
  "BRIATICO",
  "BRICHERASIO",
  "BRIENNO",
  "BRIENZA",
  "BRIGA ALTA",
  "BRIGA NOVARESE",
  "BRIGNANO GERA D'ADDA",
  "BRIGNANO-FRASCATA",
  "BRINDISI",
  "BRINDISI MONTAGNA",
  "BRINZIO",
  "BRIONA",
  "BRIONE",
  "BRIOSCO",
  "BRISIGHELLA",
  "BRISSAGO-VALTRAVAGLIA",
  "BRISSOGNE",
  "BRITTOLI",
  "BRIVIO",
  "BROCCOSTELLA",
  "BROGLIANO",
  "BROGNATURO",
  "BROLO",
  "BRONDELLO",
  "BRONI",
  "BRONTE",
  "BRONZOLO",
  "BROSSASCO",
  "BROSSO",
  "BROVELLO-CARPUGNINO",
  "BROZOLO",
  "BRUGHERIO",
  "BRUGINE",
  "BRUGNATO",
  "BRUGNERA",
  "BRUINO",
  "BRUMANO",
  "BRUNATE",
  "BRUNELLO",
  "BRUNICO",
  "BRUNO",
  "BRUSAPORTO",
  "BRUSASCO",
  "BRUSCIANO",
  "BRUSIMPIANO",
  "BRUSNENGO",
  "BRUSSON",
  "BRUZOLO",
  "BRUZZANO ZEFFIRIO",
  "BUBBIANO",
  "BUBBIO",
  "BUCCHERI",
  "BUCCHIANICO",
  "BUCCIANO",
  "BUCCINASCO",
  "BUCCINO",
  "BUCINE",
  "BUDDUSÒ",
  "BUDOIA",
  "BUDONI",
  "BUDRIO",
  "BUGGERRU",
  "BUGGIANO",
  "BUGLIO IN MONTE",
  "BUGNARA",
  "BUGUGGIATE",
  "BUJA",
  "BULCIAGO",
  "BULGAROGRASSO",
  "BULTEI",
  "BULZI",
  "BUONABITACOLO",
  "BUONALBERGO",
  "BUONCONVENTO",
  "BUONVICINO",
  "BURAGO DI MOLGORA",
  "BURCEI",
  "BURGIO",
  "BURGOS",
  "BURIASCO",
  "BUROLO",
  "BURONZO",
  "BUSACHI",
  "BUSALLA",
  "BUSANO",
  "BUSCA",
  "BUSCATE",
  "BUSCEMI",
  "BUSETO PALIZZOLO",
  "BUSNAGO",
  "BUSSERO",
  "BUSSETO",
  "BUSSI SUL TIRINO",
  "BUSSO",
  "BUSSOLENGO",
  "BUSSOLENO",
  "BUSTO ARSIZIO",
  "BUSTO GAROLFO",
  "BUTERA",
  "BUTI",
  "BUTTAPIETRA",
  "BUTTIGLIERA ALTA",
  "BUTTIGLIERA D'ASTI",
  "BUTTRIO",
  "CABELLA LIGURE",
  "CABIATE",
  "CABRAS",
  "CACCAMO",
  "CACCURI",
  "CADEGLIANO-VICONAGO",
  "CADELBOSCO DI SOPRA",
  "CADEO",
  "CADERZONE TERME",
  "CADONEGHE",
  "CADORAGO",
  "CADREZZATE CON OSMATE",
  "CAERANO DI SAN MARCO",
  "CAFASSE",
  "CAGGIANO",
  "CAGLI",
  "CAGLIARI",
  "CAGLIO",
  "CAGNANO AMITERNO",
  "CAGNANO VARANO",
  "CAIANELLO",
  "CAIAZZO",
  "CAINES",
  "CAINO",
  "CAIOLO",
  "CAIRANO",
  "CAIRATE",
  "CAIRO MONTENOTTE",
  "CAIVANO",
  "CALABRITTO",
  "CALALZO DI CADORE",
  "CALAMANDRANA",
  "CALAMONACI",
  "CALANGIANUS",
  "CALANNA",
  "CALASCA-CASTIGLIONE",
  "CALASCIBETTA",
  "CALASCIO",
  "CALASETTA",
  "CALATABIANO",
  "CALATAFIMI-SEGESTA",
  "CALCATA",
  "CALCERANICA AL LAGO",
  "CALCI",
  "CALCIANO",
  "CALCINAIA",
  "CALCINATE",
  "CALCINATO",
  "CALCIO",
  "CALCO",
  "CALDARO SULLA STRADA DEL VINO",
  "CALDAROLA",
  "CALDERARA DI RENO",
  "CALDES",
  "CALDIERO",
  "CALDOGNO",
  "CALDONAZZO",
  "CALENDASCO",
  "CALENZANO",
  "CALESTANO",
  "CALICE LIGURE",
  "CALICE AL CORNOVIGLIO",
  "CALIMERA",
  "CALITRI",
  "CALIZZANO",
  "CALLABIANA",
  "CALLIANO",
  "CALLIANO MONFERRATO",
  "CALOLZIOCORTE",
  "CALOPEZZATI",
  "CALOSSO",
  "CALOVETO",
  "CALTABELLOTTA",
  "CALTAGIRONE",
  "CALTANISSETTA",
  "CALTAVUTURO",
  "CALTIGNAGA",
  "CALTO",
  "CALTRANO",
  "CALUSCO D'ADDA",
  "CALUSO",
  "CALVAGESE DELLA RIVIERA",
  "CALVANICO",
  "CALVATONE",
  "CALVELLO",
  "CALVENE",
  "CALVENZANO",
  "CALVERA",
  "CALVI",
  "CALVI RISORTA",
  "CALVI DELL'UMBRIA",
  "CALVIGNANO",
  "CALVIGNASCO",
  "CALVISANO",
  "CALVIZZANO",
  "CAMAGNA MONFERRATO",
  "CAMAIORE",
  "CAMANDONA",
  "CAMASTRA",
  "CAMBIAGO",
  "CAMBIANO",
  "CAMBIASCA",
  "CAMBURZANO",
  "CAMERANA",
  "CAMERANO",
  "CAMERANO CASASCO",
  "CAMERATA CORNELLO",
  "CAMERATA NUOVA",
  "CAMERATA PICENA",
  "CAMERI",
  "CAMERINO",
  "CAMEROTA",
  "CAMIGLIANO",
  "CAMINI",
  "CAMINO",
  "CAMINO AL TAGLIAMENTO",
  "CAMISANO",
  "CAMISANO VICENTINO",
  "CAMMARATA",
  "CAMOGLI",
  "CAMPAGNA",
  "CAMPAGNA LUPIA",
  "CAMPAGNANO DI ROMA",
  "CAMPAGNATICO",
  "CAMPAGNOLA CREMASCA",
  "CAMPAGNOLA EMILIA",
  "CAMPANA",
  "CAMPARADA",
  "CAMPEGINE",
  "CAMPELLO SUL CLITUNNO",
  "CAMPERTOGNO",
  "CAMPI BISENZIO",
  "CAMPI SALENTINA",
  "CAMPIGLIA CERVO",
  "CAMPIGLIA MARITTIMA",
  "CAMPIGLIA DEI BERICI",
  "CAMPIGLIONE FENILE",
  "CAMPIONE D'ITALIA",
  "CAMPITELLO DI FASSA",
  "CAMPLI",
  "CAMPO CALABRO",
  "CAMPO LIGURE",
  "CAMPO SAN MARTINO",
  "CAMPO TURES",
  "CAMPO DI GIOVE",
  "CAMPO DI TRENS",
  "CAMPO NELL'ELBA",
  "CAMPOBASSO",
  "CAMPOBELLO DI LICATA",
  "CAMPOBELLO DI MAZARA",
  "CAMPOCHIARO",
  "CAMPODARSEGO",
  "CAMPODENNO",
  "CAMPODIMELE",
  "CAMPODIPIETRA",
  "CAMPODOLCINO",
  "CAMPODORO",
  "CAMPOFELICE DI FITALIA",
  "CAMPOFELICE DI ROCCELLA",
  "CAMPOFILONE",
  "CAMPOFIORITO",
  "CAMPOFORMIDO",
  "CAMPOFRANCO",
  "CAMPOGALLIANO",
  "CAMPOLATTARO",
  "CAMPOLI APPENNINO",
  "CAMPOLI DEL MONTE TABURNO",
  "CAMPOLIETO",
  "CAMPOLONGO MAGGIORE",
  "CAMPOLONGO TAPOGLIANO",
  "CAMPOMAGGIORE",
  "CAMPOMARINO",
  "CAMPOMORONE",
  "CAMPONOGARA",
  "CAMPORA",
  "CAMPOREALE",
  "CAMPORGIANO",
  "CAMPOROSSO",
  "CAMPOROTONDO ETNEO",
  "CAMPOROTONDO DI FIASTRONE",
  "CAMPOSAMPIERO",
  "CAMPOSANO",
  "CAMPOSANTO",
  "CAMPOSPINOSO",
  "CAMPOTOSTO",
  "CAMUGNANO",
  "CANAL SAN BOVO",
  "CANALE",
  "CANALE MONTERANO",
  "CANALE D'AGORDO",
  "CANARO",
  "CANAZEI",
  "CANCELLARA",
  "CANCELLO ED ARNONE",
  "CANDA",
  "CANDELA",
  "CANDELO",
  "CANDIA CANAVESE",
  "CANDIA LOMELLINA",
  "CANDIANA",
  "CANDIDA",
  "CANDIDONI",
  "CANDIOLO",
  "CANEGRATE",
  "CANELLI",
  "CANEPINA",
  "CANEVA",
  "CANICATTINI BAGNI",
  "CANICATTÌ",
  "CANINO",
  "CANISCHIO",
  "CANISTRO",
  "CANNA",
  "CANNALONGA",
  "CANNARA",
  "CANNERO RIVIERA",
  "CANNETO PAVESE",
  "CANNETO SULL'OGLIO",
  "CANNOBIO",
  "CANNOLE",
  "CANOLO",
  "CANONICA D'ADDA",
  "CANOSA SANNITA",
  "CANOSA DI PUGLIA",
  "CANOSIO",
  "CANOSSA",
  "CANSANO",
  "CANTAGALLO",
  "CANTALICE",
  "CANTALUPA",
  "CANTALUPO LIGURE",
  "CANTALUPO IN SABINA",
  "CANTALUPO NEL SANNIO",
  "CANTARANA",
  "CANTELLO",
  "CANTERANO",
  "CANTIANO",
  "CANTOIRA",
  "CANTÙ",
  "CANZANO",
  "CANZO",
  "CAORLE",
  "CAORSO",
  "CAPACCIO PAESTUM",
  "CAPACI",
  "CAPALBIO",
  "CAPANNOLI",
  "CAPANNORI",
  "CAPENA",
  "CAPERGNANICA",
  "CAPESTRANO",
  "CAPIAGO INTIMIANO",
  "CAPISTRANO",
  "CAPISTRELLO",
  "CAPITIGNANO",
  "CAPIZZI",
  "CAPIZZONE",
  "CAPO D'ORLANDO",
  "CAPO DI PONTE",
  "CAPODIMONTE",
  "CAPODRISE",
  "CAPOLIVERI",
  "CAPOLONA",
  "CAPONAGO",
  "CAPORCIANO",
  "CAPOSELE",
  "CAPOTERRA",
  "CAPOVALLE",
  "CAPPADOCIA",
  "CAPPELLA CANTONE",
  "CAPPELLA MAGGIORE",
  "CAPPELLA DE' PICENARDI",
  "CAPPELLE SUL TAVO",
  "CAPRACOTTA",
  "CAPRAIA ISOLA",
  "CAPRAIA E LIMITE",
  "CAPRALBA",
  "CAPRANICA",
  "CAPRANICA PRENESTINA",
  "CAPRARICA DI LECCE",
  "CAPRAROLA",
  "CAPRAUNA",
  "CAPRESE MICHELANGELO",
  "CAPREZZO",
  "CAPRI",
  "CAPRI LEONE",
  "CAPRIANA",
  "CAPRIANO DEL COLLE",
  "CAPRIATA D'ORBA",
  "CAPRIATE SAN GERVASIO",
  "CAPRIATI A VOLTURNO",
  "CAPRIE",
  "CAPRIGLIA IRPINA",
  "CAPRIGLIO",
  "CAPRILE",
  "CAPRINO BERGAMASCO",
  "CAPRINO VERONESE",
  "CAPRIOLO",
  "CAPRIVA DEL FRIULI",
  "CAPUA",
  "CAPURSO",
  "CARAFFA DEL BIANCO",
  "CARAFFA DI CATANZARO",
  "CARAGLIO",
  "CARAMAGNA PIEMONTE",
  "CARAMANICO TERME",
  "CARAPELLE",
  "CARAPELLE CALVISIO",
  "CARASCO",
  "CARASSAI",
  "CARATE BRIANZA",
  "CARATE URIO",
  "CARAVAGGIO",
  "CARAVATE",
  "CARAVINO",
  "CARAVONICA",
  "CARBOGNANO",
  "CARBONARA SCRIVIA",
  "CARBONARA AL TICINO",
  "CARBONARA DI NOLA",
  "CARBONATE",
  "CARBONE",
  "CARBONERA",
  "CARBONIA",
  "CARCARE",
  "CARCERI",
  "CARCOFORO",
  "CARDANO AL CAMPO",
  "CARDEDU",
  "CARDETO",
  "CARDINALE",
  "CARDITO",
  "CARDÈ",
  "CAREGGINE",
  "CAREMA",
  "CARENNO",
  "CARENTINO",
  "CARERI",
  "CARESANA",
  "CARESANABLOT",
  "CAREZZANO",
  "CARFIZZI",
  "CARGEGHE",
  "CARIATI",
  "CARIFE",
  "CARIGNANO",
  "CARIMATE",
  "CARINARO",
  "CARINI",
  "CARINOLA",
  "CARISIO",
  "CARISOLO",
  "CARLANTINO",
  "CARLAZZO",
  "CARLENTINI",
  "CARLINO",
  "CARLOFORTE",
  "CARLOPOLI",
  "CARMAGNOLA",
  "CARMIANO",
  "CARMIGNANO",
  "CARMIGNANO DI BRENTA",
  "CARNAGO",
  "CARNATE",
  "CAROBBIO DEGLI ANGELI",
  "CAROLEI",
  "CARONA",
  "CARONIA",
  "CARONNO PERTUSELLA",
  "CARONNO VARESINO",
  "CAROSINO",
  "CAROVIGNO",
  "CAROVILLI",
  "CARPANETO PIACENTINO",
  "CARPANZANO",
  "CARPEGNA",
  "CARPENEDOLO",
  "CARPENETO",
  "CARPI",
  "CARPIANO",
  "CARPIGNANO SALENTINO",
  "CARPIGNANO SESIA",
  "CARPINETI",
  "CARPINETO ROMANO",
  "CARPINETO SINELLO",
  "CARPINETO DELLA NORA",
  "CARPINO",
  "CARPINONE",
  "CARRARA",
  "CARREGA LIGURE",
  "CARRO",
  "CARRODANO",
  "CARROSIO",
  "CARRÈ",
  "CARRÙ",
  "CARSOLI",
  "CARTIGLIANO",
  "CARTIGNANO",
  "CARTOCETO",
  "CARTOSIO",
  "CARTURA",
  "CARUGATE",
  "CARUGO",
  "CARUNCHIO",
  "CARVICO",
  "CARZANO",
  "CASABONA",
  "CASACALENDA",
  "CASACANDITELLA",
  "CASAGIOVE",
  "CASAL CERMELLI",
  "CASAL VELINO",
  "CASAL DI PRINCIPE",
  "CASALANGUIDA",
  "CASALATTICO",
  "CASALBELTRAME",
  "CASALBORDINO",
  "CASALBORE",
  "CASALBORGONE",
  "CASALBUONO",
  "CASALBUTTANO ED UNITI",
  "CASALCIPRANO",
  "CASALDUNI",
  "CASALE CORTE CERRO",
  "CASALE CREMASCO-VIDOLASCO",
  "CASALE LITTA",
  "CASALE MARITTIMO",
  "CASALE MONFERRATO",
  "CASALE DI SCODOSIA",
  "CASALE SUL SILE",
  "CASALECCHIO DI RENO",
  "CASALEGGIO BOIRO",
  "CASALEGGIO NOVARA",
  "CASALEONE",
  "CASALETTO CEREDANO",
  "CASALETTO LODIGIANO",
  "CASALETTO SPARTANO",
  "CASALETTO VAPRIO",
  "CASALETTO DI SOPRA",
  "CASALFIUMANESE",
  "CASALGRANDE",
  "CASALGRASSO",
  "CASALI DEL MANCO",
  "CASALINCONTRADA",
  "CASALINO",
  "CASALMAGGIORE",
  "CASALMAIOCCO",
  "CASALMORANO",
  "CASALMORO",
  "CASALNOCETO",
  "CASALNUOVO MONTEROTARO",
  "CASALNUOVO DI NAPOLI",
  "CASALOLDO",
  "CASALPUSTERLENGO",
  "CASALROMANO",
  "CASALSERUGO",
  "CASALUCE",
  "CASALVECCHIO SICULO",
  "CASALVECCHIO DI PUGLIA",
  "CASALVIERI",
  "CASALVOLONE",
  "CASALZUIGNO",
  "CASAMARCIANO",
  "CASAMASSIMA",
  "CASAMICCIOLA TERME",
  "CASANDRINO",
  "CASANOVA ELVO",
  "CASANOVA LERRONE",
  "CASANOVA LONATI",
  "CASAPE",
  "CASAPESENNA",
  "CASAPINTA",
  "CASAPROTA",
  "CASAPULLA",
  "CASARANO",
  "CASARGO",
  "CASARILE",
  "CASARSA DELLA DELIZIA",
  "CASARZA LIGURE",
  "CASASCO",
  "CASATENOVO",
  "CASATISMA",
  "CASAVATORE",
  "CASAZZA",
  "CASCIA",
  "CASCIAGO",
  "CASCIANA TERME LARI",
  "CASCINA",
  "CASCINETTE D'IVREA",
  "CASEI GEROLA",
  "CASELETTE",
  "CASELLA",
  "CASELLE LANDI",
  "CASELLE LURANI",
  "CASELLE TORINESE",
  "CASELLE IN PITTARI",
  "CASERTA",
  "CASIER",
  "CASIGNANA",
  "CASINA",
  "CASIRATE D'ADDA",
  "CASLINO D'ERBA",
  "CASNATE CON BERNATE",
  "CASNIGO",
  "CASOLA VALSENIO",
  "CASOLA DI NAPOLI",
  "CASOLA IN LUNIGIANA",
  "CASOLE D'ELSA",
  "CASOLI",
  "CASORATE PRIMO",
  "CASORATE SEMPIONE",
  "CASOREZZO",
  "CASORIA",
  "CASORZO MONFERRATO",
  "CASPERIA",
  "CASPOGGIO",
  "CASSACCO",
  "CASSAGO BRIANZA",
  "CASSANO IRPINO",
  "CASSANO MAGNAGO",
  "CASSANO SPINOLA",
  "CASSANO VALCUVIA",
  "CASSANO ALL'IONIO",
  "CASSANO D'ADDA",
  "CASSANO DELLE MURGE",
  "CASSARO",
  "CASSIGLIO",
  "CASSINA RIZZARDI",
  "CASSINA VALSASSINA",
  "CASSINA DE' PECCHI",
  "CASSINASCO",
  "CASSINE",
  "CASSINELLE",
  "CASSINETTA DI LUGAGNANO",
  "CASSINO",
  "CASSOLA",
  "CASSOLNOVO",
  "CASTAGNARO",
  "CASTAGNETO CARDUCCI",
  "CASTAGNETO PO",
  "CASTAGNITO",
  "CASTAGNOLE MONFERRATO",
  "CASTAGNOLE PIEMONTE",
  "CASTAGNOLE DELLE LANZE",
  "CASTANA",
  "CASTANO PRIMO",
  "CASTEGGIO",
  "CASTEGNATO",
  "CASTEGNERO",
  "CASTEL BARONIA",
  "CASTEL BOGLIONE",
  "CASTEL BOLOGNESE",
  "CASTEL CAMPAGNANO",
  "CASTEL CASTAGNA",
  "CASTEL CONDINO",
  "CASTEL FOCOGNANO",
  "CASTEL FRENTANO",
  "CASTEL GABBIANO",
  "CASTEL GANDOLFO",
  "CASTEL GIORGIO",
  "CASTEL GOFFREDO",
  "CASTEL GUELFO DI BOLOGNA",
  "CASTEL IVANO",
  "CASTEL MADAMA",
  "CASTEL MAGGIORE",
  "CASTEL MELLA",
  "CASTEL MORRONE",
  "CASTEL RITALDI",
  "CASTEL ROCCHERO",
  "CASTEL ROZZONE",
  "CASTEL SAN GIORGIO",
  "CASTEL SAN GIOVANNI",
  "CASTEL SAN LORENZO",
  "CASTEL SAN NICCOLÒ",
  "CASTEL SAN PIETRO ROMANO",
  "CASTEL SAN PIETRO TERME",
  "CASTEL SAN VINCENZO",
  "CASTEL SANT'ANGELO",
  "CASTEL SANT'ELIA",
  "CASTEL VISCARDO",
  "CASTEL VITTORIO",
  "CASTEL VOLTURNO",
  "CASTEL D'AIANO",
  "CASTEL D'ARIO",
  "CASTEL D'AZZANO",
  "CASTEL DEL GIUDICE",
  "CASTEL DEL MONTE",
  "CASTEL DEL PIANO",
  "CASTEL DEL RIO",
  "CASTEL DI CASIO",
  "CASTEL DI IERI",
  "CASTEL DI IUDICA",
  "CASTEL DI LAMA",
  "CASTEL DI LUCIO",
  "CASTEL DI SANGRO",
  "CASTEL DI SASSO",
  "CASTEL DI TORA",
  "CASTELBALDO",
  "CASTELBELFORTE",
  "CASTELBELLINO",
  "CASTELBELLO-CIARDES",
  "CASTELBIANCO",
  "CASTELBOTTACCIO",
  "CASTELBUONO",
  "CASTELCIVITA",
  "CASTELCOVATI",
  "CASTELCUCCO",
  "CASTELDACCIA",
  "CASTELDELCI",
  "CASTELDELFINO",
  "CASTELDIDONE",
  "CASTELFIDARDO",
  "CASTELFIORENTINO",
  "CASTELFORTE",
  "CASTELFRANCI",
  "CASTELFRANCO EMILIA",
  "CASTELFRANCO PIANDISCÒ",
  "CASTELFRANCO VENETO",
  "CASTELFRANCO DI SOTTO",
  "CASTELFRANCO IN MISCANO",
  "CASTELGERUNDO",
  "CASTELGOMBERTO",
  "CASTELGRANDE",
  "CASTELGUGLIELMO",
  "CASTELGUIDONE",
  "CASTELL'ALFERO",
  "CASTELL'ARQUATO",
  "CASTELL'AZZARA",
  "CASTELL'UMBERTO",
  "CASTELLABATE",
  "CASTELLAFIUME",
  "CASTELLALTO",
  "CASTELLAMMARE DEL GOLFO",
  "CASTELLAMMARE DI STABIA",
  "CASTELLAMONTE",
  "CASTELLANA GROTTE",
  "CASTELLANA SICULA",
  "CASTELLANETA",
  "CASTELLANIA COPPI",
  "CASTELLANZA",
  "CASTELLAR GUIDOBONO",
  "CASTELLARANO",
  "CASTELLARO",
  "CASTELLAZZO BORMIDA",
  "CASTELLAZZO NOVARESE",
  "CASTELLEONE",
  "CASTELLEONE DI SUASA",
  "CASTELLERO",
  "CASTELLETTO CERVO",
  "CASTELLETTO MERLI",
  "CASTELLETTO MOLINA",
  "CASTELLETTO MONFERRATO",
  "CASTELLETTO STURA",
  "CASTELLETTO UZZONE",
  "CASTELLETTO D'ERRO",
  "CASTELLETTO D'ORBA",
  "CASTELLETTO DI BRANDUZZO",
  "CASTELLETTO SOPRA TICINO",
  "CASTELLI",
  "CASTELLI CALEPIO",
  "CASTELLINA MARITTIMA",
  "CASTELLINA IN CHIANTI",
  "CASTELLINALDO D'ALBA",
  "CASTELLINO TANARO",
  "CASTELLINO DEL BIFERNO",
  "CASTELLIRI",
  "CASTELLO CABIAGLIO",
  "CASTELLO TESINO",
  "CASTELLO D'AGOGNA",
  "CASTELLO D'ARGILE",
  "CASTELLO DEL MATESE",
  "CASTELLO DELL'ACQUA",
  "CASTELLO DI ANNONE",
  "CASTELLO DI BRIANZA",
  "CASTELLO DI CISTERNA",
  "CASTELLO DI GODEGO",
  "CASTELLO-MOLINA DI FIEMME",
  "CASTELLUCCHIO",
  "CASTELLUCCIO INFERIORE",
  "CASTELLUCCIO SUPERIORE",
  "CASTELLUCCIO VALMAGGIORE",
  "CASTELLUCCIO DEI SAURI",
  "CASTELMAGNO",
  "CASTELMARTE",
  "CASTELMASSA",
  "CASTELMAURO",
  "CASTELMEZZANO",
  "CASTELMOLA",
  "CASTELNOVETTO",
  "CASTELNOVO BARIANO",
  "CASTELNOVO DEL FRIULI",
  "CASTELNOVO DI SOTTO",
  "CASTELNOVO NE' MONTI",
  "CASTELNUOVO",
  "CASTELNUOVO BELBO",
  "CASTELNUOVO BERARDENGA",
  "CASTELNUOVO BOCCA D'ADDA",
  "CASTELNUOVO BORMIDA",
  "CASTELNUOVO BOZZENTE",
  "CASTELNUOVO CALCEA",
  "CASTELNUOVO CILENTO",
  "CASTELNUOVO DON BOSCO",
  "CASTELNUOVO MAGRA",
  "CASTELNUOVO NIGRA",
  "CASTELNUOVO PARANO",
  "CASTELNUOVO RANGONE",
  "CASTELNUOVO SCRIVIA",
  "CASTELNUOVO DEL GARDA",
  "CASTELNUOVO DELLA DAUNIA",
  "CASTELNUOVO DI CEVA",
  "CASTELNUOVO DI CONZA",
  "CASTELNUOVO DI FARFA",
  "CASTELNUOVO DI GARFAGNANA",
  "CASTELNUOVO DI PORTO",
  "CASTELNUOVO DI VAL DI CECINA",
  "CASTELPAGANO",
  "CASTELPETROSO",
  "CASTELPIZZUTO",
  "CASTELPLANIO",
  "CASTELPOTO",
  "CASTELRAIMONDO",
  "CASTELROTTO",
  "CASTELSANTANGELO SUL NERA",
  "CASTELSARACENO",
  "CASTELSARDO",
  "CASTELSEPRIO",
  "CASTELSILANO",
  "CASTELSPINA",
  "CASTELTERMINI",
  "CASTELVECCANA",
  "CASTELVECCHIO CALVISIO",
  "CASTELVECCHIO SUBEQUO",
  "CASTELVECCHIO DI ROCCA BARBENA",
  "CASTELVENERE",
  "CASTELVERDE",
  "CASTELVERRINO",
  "CASTELVETERE IN VAL FORTORE",
  "CASTELVETERE SUL CALORE",
  "CASTELVETRANO",
  "CASTELVETRO PIACENTINO",
  "CASTELVETRO DI MODENA",
  "CASTELVISCONTI",
  "CASTENASO",
  "CASTENEDOLO",
  "CASTIADAS",
  "CASTIGLION FIBOCCHI",
  "CASTIGLION FIORENTINO",
  "CASTIGLIONE CHIAVARESE",
  "CASTIGLIONE COSENTINO",
  "CASTIGLIONE FALLETTO",
  "CASTIGLIONE MESSER MARINO",
  "CASTIGLIONE MESSER RAIMONDO",
  "CASTIGLIONE OLONA",
  "CASTIGLIONE TINELLA",
  "CASTIGLIONE TORINESE",
  "CASTIGLIONE A CASAURIA",
  "CASTIGLIONE D'ADDA",
  "CASTIGLIONE D'ORCIA",
  "CASTIGLIONE DEI PEPOLI",
  "CASTIGLIONE DEL GENOVESI",
  "CASTIGLIONE DEL LAGO",
  "CASTIGLIONE DELLA PESCAIA",
  "CASTIGLIONE DELLE STIVIERE",
  "CASTIGLIONE DI GARFAGNANA",
  "CASTIGLIONE DI SICILIA",
  "CASTIGLIONE IN TEVERINA",
  "CASTIGNANO",
  "CASTILENTI",
  "CASTINO",
  "CASTIONE ANDEVENNO",
  "CASTIONE DELLA PRESOLANA",
  "CASTIONS DI STRADA",
  "CASTIRAGA VIDARDO",
  "CASTO",
  "CASTORANO",
  "CASTREZZATO",
  "CASTRI DI LECCE",
  "CASTRIGNANO DE' GRECI",
  "CASTRIGNANO DEL CAPO",
  "CASTRO",
  "CASTRO DEI VOLSCI",
  "CASTROCARO TERME E TERRA DEL SOLE",
  "CASTROCIELO",
  "CASTROFILIPPO",
  "CASTROLIBERO",
  "CASTRONNO",
  "CASTRONOVO DI SICILIA",
  "CASTRONUOVO DI SANT'ANDREA",
  "CASTROPIGNANO",
  "CASTROREALE",
  "CASTROREGIO",
  "CASTROVILLARI",
  "CATANIA",
  "CATANZARO",
  "CATENANUOVA",
  "CATIGNANO",
  "CATTOLICA",
  "CATTOLICA ERACLEA",
  "CAULONIA",
  "CAUTANO",
  "CAVA MANARA",
  "CAVA DE' TIRRENI",
  "CAVAGLIETTO",
  "CAVAGLIO D'AGOGNA",
  "CAVAGLIÀ",
  "CAVAGNOLO",
  "CAVAION VERONESE",
  "CAVALESE",
  "CAVALLERLEONE",
  "CAVALLERMAGGIORE",
  "CAVALLINO",
  "CAVALLINO-TREPORTI",
  "CAVALLIRIO",
  "CAVARENO",
  "CAVARGNA",
  "CAVARIA CON PREMEZZO",
  "CAVARZERE",
  "CAVASO DEL TOMBA",
  "CAVASSO NUOVO",
  "CAVATORE",
  "CAVAZZO CARNICO",
  "CAVE",
  "CAVEDAGO",
  "CAVEDINE",
  "CAVENAGO D'ADDA",
  "CAVENAGO DI BRIANZA",
  "CAVERNAGO",
  "CAVEZZO",
  "CAVIZZANA",
  "CAVOUR",
  "CAVRIAGO",
  "CAVRIANA",
  "CAVRIGLIA",
  "CAZZAGO BRABBIA",
  "CAZZAGO SAN MARTINO",
  "CAZZANO SANT'ANDREA",
  "CAZZANO DI TRAMIGNA",
  "CECCANO",
  "CECIMA",
  "CECINA",
  "CEDEGOLO",
  "CEDRASCO",
  "CEFALÀ DIANA",
  "CEFALÙ",
  "CEGGIA",
  "CEGLIE MESSAPICA",
  "CELANO",
  "CELENZA VALFORTORE",
  "CELENZA SUL TRIGNO",
  "CELICO",
  "CELLA DATI",
  "CELLA MONTE",
  "CELLAMARE",
  "CELLARA",
  "CELLARENGO",
  "CELLATICA",
  "CELLE ENOMONDO",
  "CELLE LIGURE",
  "CELLE DI BULGHERIA",
  "CELLE DI MACRA",
  "CELLE DI SAN VITO",
  "CELLENO",
  "CELLERE",
  "CELLINO ATTANASIO",
  "CELLINO SAN MARCO",
  "CELLIO CON BREIA",
  "CELLOLE",
  "CEMBRA LISIGNAGO",
  "CENADI",
  "CENATE SOPRA",
  "CENATE SOTTO",
  "CENCENIGHE AGORDINO",
  "CENE",
  "CENESELLI",
  "CENGIO",
  "CENTALLO",
  "CENTO",
  "CENTOLA",
  "CENTRACHE",
  "CENTRO VALLE INTELVI",
  "CENTURIPE",
  "CEPAGATTI",
  "CEPPALONI",
  "CEPPO MORELLI",
  "CEPRANO",
  "CERAMI",
  "CERANESI",
  "CERANO",
  "CERANO D'INTELVI",
  "CERANOVA",
  "CERASO",
  "CERCEMAGGIORE",
  "CERCENASCO",
  "CERCEPICCOLA",
  "CERCHIARA DI CALABRIA",
  "CERCHIO",
  "CERCINO",
  "CERCIVENTO",
  "CERCOLA",
  "CERDA",
  "CEREA",
  "CEREGNANO",
  "CERENZIA",
  "CERES",
  "CERESARA",
  "CERESETO",
  "CERESOLE ALBA",
  "CERESOLE REALE",
  "CERETE",
  "CERETTO LOMELLINA",
  "CERGNAGO",
  "CERIALE",
  "CERIANA",
  "CERIANO LAGHETTO",
  "CERIGNALE",
  "CERIGNOLA",
  "CERISANO",
  "CERMENATE",
  "CERMES",
  "CERMIGNANO",
  "CERNOBBIO",
  "CERNUSCO LOMBARDONE",
  "CERNUSCO SUL NAVIGLIO",
  "CERRETO GRUE",
  "CERRETO GUIDI",
  "CERRETO LAZIALE",
  "CERRETO SANNITA",
  "CERRETO D'ASTI",
  "CERRETO D'ESI",
  "CERRETO DI SPOLETO",
  "CERRETTO LANGHE",
  "CERRINA MONFERRATO",
  "CERRIONE",
  "CERRO MAGGIORE",
  "CERRO TANARO",
  "CERRO VERONESE",
  "CERRO AL LAMBRO",
  "CERRO AL VOLTURNO",
  "CERSOSIMO",
  "CERTALDO",
  "CERTOSA DI PAVIA",
  "CERVA",
  "CERVARA DI ROMA",
  "CERVARESE SANTA CROCE",
  "CERVARO",
  "CERVASCA",
  "CERVATTO",
  "CERVENO",
  "CERVERE",
  "CERVESINA",
  "CERVETERI",
  "CERVIA",
  "CERVICATI",
  "CERVIGNANO D'ADDA",
  "CERVIGNANO DEL FRIULI",
  "CERVINARA",
  "CERVINO",
  "CERVO",
  "CERZETO",
  "CESA",
  "CESANA BRIANZA",
  "CESANA TORINESE",
  "CESANO BOSCONE",
  "CESANO MADERNO",
  "CESARA",
  "CESARÒ",
  "CESATE",
  "CESENA",
  "CESENATICO",
  "CESINALI",
  "CESIO",
  "CESIOMAGGIORE",
  "CESSALTO",
  "CESSANITI",
  "CESSAPALOMBO",
  "CESSOLE",
  "CETARA",
  "CETO",
  "CETONA",
  "CETRARO",
  "CEVA",
  "CEVO",
  "CHALLAND-SAINT-ANSELME",
  "CHALLAND-SAINT-VICTOR",
  "CHAMBAVE",
  "CHAMOIS",
  "CHAMPDEPRAZ",
  "CHAMPORCHER",
  "CHARVENSOD",
  "CHERASCO",
  "CHEREMULE",
  "CHIALAMBERTO",
  "CHIAMPO",
  "CHIANCHE",
  "CHIANCIANO TERME",
  "CHIANNI",
  "CHIANOCCO",
  "CHIARAMONTE GULFI",
  "CHIARAMONTI",
  "CHIARANO",
  "CHIARAVALLE",
  "CHIARAVALLE CENTRALE",
  "CHIARI",
  "CHIAROMONTE",
  "CHIAUCI",
  "CHIAVARI",
  "CHIAVENNA",
  "CHIAVERANO",
  "CHIENES",
  "CHIERI",
  "CHIES D'ALPAGO",
  "CHIESA IN VALMALENCO",
  "CHIESANUOVA",
  "CHIESINA UZZANESE",
  "CHIETI",
  "CHIEUTI",
  "CHIEVE",
  "CHIGNOLO PO",
  "CHIGNOLO D'ISOLA",
  "CHIOGGIA",
  "CHIOMONTE",
  "CHIONS",
  "CHIOPRIS-VISCONE",
  "CHITIGNANO",
  "CHIUDUNO",
  "CHIUPPANO",
  "CHIURO",
  "CHIUSA",
  "CHIUSA SCLAFANI",
  "CHIUSA DI PESIO",
  "CHIUSA DI SAN MICHELE",
  "CHIUSAFORTE",
  "CHIUSANICO",
  "CHIUSANO D'ASTI",
  "CHIUSANO DI SAN DOMENICO",
  "CHIUSAVECCHIA",
  "CHIUSDINO",
  "CHIUSI",
  "CHIUSI DELLA VERNA",
  "CHIVASSO",
  "CHÂTILLON",
  "CIAMPINO",
  "CIANCIANA",
  "CIBIANA DI CADORE",
  "CICAGNA",
  "CICALA",
  "CICCIANO",
  "CICERALE",
  "CICILIANO",
  "CICOGNOLO",
  "CICONIO",
  "CIGLIANO",
  "CIGLIÈ",
  "CIGOGNOLA",
  "CIGOLE",
  "CILAVEGNA",
  "CIMADOLMO",
  "CIMBERGO",
  "CIMINNA",
  "CIMINÀ",
  "CIMITILE",
  "CIMOLAIS",
  "CIMONE",
  "CINAGLIO",
  "CINETO ROMANO",
  "CINGIA DE' BOTTI",
  "CINGOLI",
  "CINIGIANO",
  "CINISELLO BALSAMO",
  "CINISI",
  "CINO",
  "CINQUEFRONDI",
  "CINTANO",
  "CINTE TESINO",
  "CINTO CAOMAGGIORE",
  "CINTO EUGANEO",
  "CINZANO",
  "CIORLANO",
  "CIPRESSA",
  "CIRCELLO",
  "CIRIGLIANO",
  "CIRIMIDO",
  "CIRIÈ",
  "CIRÒ",
  "CIRÒ MARINA",
  "CIS",
  "CISANO BERGAMASCO",
  "CISANO SUL NEVA",
  "CISERANO",
  "CISLAGO",
  "CISLIANO",
  "CISON DI VALMARINO",
  "CISSONE",
  "CISTERNA D'ASTI",
  "CISTERNA DI LATINA",
  "CISTERNINO",
  "CITERNA",
  "CITTADELLA",
  "CITTADUCALE",
  "CITTANOVA",
  "CITTAREALE",
  "CITTIGLIO",
  "CITTÀ SANT'ANGELO",
  "CITTÀ DELLA PIEVE",
  "CITTÀ DI CASTELLO",
  "CIVATE",
  "CIVEZZA",
  "CIVEZZANO",
  "CIVIASCO",
  "CIVIDALE DEL FRIULI",
  "CIVIDATE CAMUNO",
  "CIVIDATE AL PIANO",
  "CIVITA",
  "CIVITA CASTELLANA",
  "CIVITA D'ANTINO",
  "CIVITACAMPOMARANO",
  "CIVITALUPARELLA",
  "CIVITANOVA MARCHE",
  "CIVITANOVA DEL SANNIO",
  "CIVITAQUANA",
  "CIVITAVECCHIA",
  "CIVITELLA ALFEDENA",
  "CIVITELLA CASANOVA",
  "CIVITELLA MESSER RAIMONDO",
  "CIVITELLA PAGANICO",
  "CIVITELLA ROVETO",
  "CIVITELLA SAN PAOLO",
  "CIVITELLA D'AGLIANO",
  "CIVITELLA DEL TRONTO",
  "CIVITELLA DI ROMAGNA",
  "CIVITELLA IN VAL DI CHIANA",
  "CIVO",
  "CLAINO CON OSTENO",
  "CLAUT",
  "CLAUZETTO",
  "CLAVESANA",
  "CLAVIERE",
  "CLES",
  "CLETO",
  "CLIVIO",
  "CLUSONE",
  "COASSOLO TORINESE",
  "COAZZE",
  "COAZZOLO",
  "COCCAGLIO",
  "COCCONATO",
  "COCQUIO-TREVISAGO",
  "COCULLO",
  "CODEVIGO",
  "CODEVILLA",
  "CODIGORO",
  "CODOGNO",
  "CODOGNÈ",
  "CODROIPO",
  "CODRONGIANOS",
  "COGGIOLA",
  "COGLIATE",
  "COGNE",
  "COGOLETO",
  "COGOLLO DEL CENGIO",
  "COGORNO",
  "COLAZZA",
  "COLCERESA",
  "COLERE",
  "COLFELICE",
  "COLI",
  "COLICO",
  "COLLALTO SABINO",
  "COLLARMELE",
  "COLLAZZONE",
  "COLLE BRIANZA",
  "COLLE SAN MAGNO",
  "COLLE SANNITA",
  "COLLE SANTA LUCIA",
  "COLLE UMBERTO",
  "COLLE D'ANCHISE",
  "COLLE DI TORA",
  "COLLE DI VAL D'ELSA",
  "COLLEBEATO",
  "COLLECCHIO",
  "COLLECORVINO",
  "COLLEDARA",
  "COLLEDIMACINE",
  "COLLEDIMEZZO",
  "COLLEFERRO",
  "COLLEGIOVE",
  "COLLEGNO",
  "COLLELONGO",
  "COLLEPARDO",
  "COLLEPASSO",
  "COLLEPIETRO",
  "COLLERETTO CASTELNUOVO",
  "COLLERETTO GIACOSA",
  "COLLESALVETTI",
  "COLLESANO",
  "COLLETORTO",
  "COLLEVECCHIO",
  "COLLI VERDI",
  "COLLI A VOLTURNO",
  "COLLI AL METAURO",
  "COLLI DEL TRONTO",
  "COLLI SUL VELINO",
  "COLLIANO",
  "COLLINAS",
  "COLLIO",
  "COLLOBIANO",
  "COLLOREDO DI MONTE ALBANO",
  "COLMURANO",
  "COLOBRARO",
  "COLOGNA VENETA",
  "COLOGNE",
  "COLOGNO MONZESE",
  "COLOGNO AL SERIO",
  "COLOGNOLA AI COLLI",
  "COLONNA",
  "COLONNELLA",
  "COLONNO",
  "COLORINA",
  "COLORNO",
  "COLOSIMI",
  "COLTURANO",
  "COLVERDE",
  "COLZATE",
  "COMABBIO",
  "COMACCHIO",
  "COMANO",
  "COMANO TERME",
  "COMAZZO",
  "COMEGLIANS",
  "COMELICO SUPERIORE",
  "COMERIO",
  "COMEZZANO-CIZZAGO",
  "COMIGNAGO",
  "COMISO",
  "COMITINI",
  "COMIZIANO",
  "COMMESSAGGIO",
  "COMMEZZADURA",
  "COMO",
  "COMPIANO",
  "COMUN NUOVO",
  "COMUNANZA",
  "CONA",
  "CONCA CASALE",
  "CONCA DEI MARINI",
  "CONCA DELLA CAMPANIA",
  "CONCAMARISE",
  "CONCERVIANO",
  "CONCESIO",
  "CONCORDIA SAGITTARIA",
  "CONCORDIA SULLA SECCHIA",
  "CONCOREZZO",
  "CONDOFURI",
  "CONDOVE",
  "CONDRÒ",
  "CONEGLIANO",
  "CONFIENZA",
  "CONFIGNI",
  "CONFLENTI",
  "CONIOLO",
  "CONSELICE",
  "CONSELVE",
  "CONTESSA ENTELLINA",
  "CONTIGLIANO",
  "CONTRADA",
  "CONTROGUERRA",
  "CONTRONE",
  "CONTURSI TERME",
  "CONTÀ",
  "CONVERSANO",
  "CONZA DELLA CAMPANIA",
  "CONZANO",
  "COPERTINO",
  "COPIANO",
  "COPPARO",
  "CORANA",
  "CORATO",
  "CORBARA",
  "CORBETTA",
  "CORBOLA",
  "CORCHIANO",
  "CORCIANO",
  "CORDENONS",
  "CORDIGNANO",
  "CORDOVADO",
  "COREGLIA ANTELMINELLI",
  "COREGLIA LIGURE",
  "CORENO AUSONIO",
  "CORFINIO",
  "CORI",
  "CORIANO",
  "CORIGLIANO D'OTRANTO",
  "CORIGLIANO-ROSSANO",
  "CORINALDO",
  "CORIO",
  "CORLEONE",
  "CORLETO MONFORTE",
  "CORLETO PERTICARA",
  "CORMANO",
  "CORMONS",
  "CORNA IMAGNA",
  "CORNALBA",
  "CORNALE E BASTIDA",
  "CORNAREDO",
  "CORNATE D'ADDA",
  "CORNEDO VICENTINO",
  "CORNEDO ALL'ISARCO",
  "CORNEGLIANO LAUDENSE",
  "CORNELIANO D'ALBA",
  "CORNIGLIO",
  "CORNO GIOVINE",
  "CORNO DI ROSAZZO",
  "CORNOVECCHIO",
  "CORNUDA",
  "CORREGGIO",
  "CORREZZANA",
  "CORREZZOLA",
  "CORRIDO",
  "CORRIDONIA",
  "CORROPOLI",
  "CORSANO",
  "CORSICO",
  "CORSIONE",
  "CORTACCIA SULLA STRADA DEL VINO",
  "CORTALE",
  "CORTANDONE",
  "CORTANZE",
  "CORTAZZONE",
  "CORTE BRUGNATELLA",
  "CORTE FRANCA",
  "CORTE PALASIO",
  "CORTE DE' CORTESI CON CIGNONE",
  "CORTE DE' FRATI",
  "CORTEMAGGIORE",
  "CORTEMILIA",
  "CORTENO GOLGI",
  "CORTENOVA",
  "CORTENUOVA",
  "CORTEOLONA E GENZONE",
  "CORTIGLIONE",
  "CORTINA D'AMPEZZO",
  "CORTINA SULLA STRADA DEL VINO",
  "CORTINO",
  "CORTONA",
  "CORVARA",
  "CORVARA IN BADIA",
  "CORVINO SAN QUIRICO",
  "CORZANO",
  "COSEANO",
  "COSENZA",
  "COSIO VALTELLINO",
  "COSIO D'ARROSCIA",
  "COSOLETO",
  "COSSANO BELBO",
  "COSSANO CANAVESE",
  "COSSATO",
  "COSSERIA",
  "COSSIGNANO",
  "COSSOGNO",
  "COSSOINE",
  "COSSOMBRATO",
  "COSTA MASNAGA",
  "COSTA SERINA",
  "COSTA VALLE IMAGNA",
  "COSTA VESCOVATO",
  "COSTA VOLPINO",
  "COSTA DE' NOBILI",
  "COSTA DI MEZZATE",
  "COSTA DI ROVIGO",
  "COSTABISSARA",
  "COSTACCIARO",
  "COSTANZANA",
  "COSTARAINERA",
  "COSTERMANO SUL GARDA",
  "COSTIGLIOLE SALUZZO",
  "COSTIGLIOLE D'ASTI",
  "COTIGNOLA",
  "COTRONEI",
  "COTTANELLO",
  "COURMAYEUR",
  "COVO",
  "COZZO",
  "CRACO",
  "CRANDOLA VALSASSINA",
  "CRAVAGLIANA",
  "CRAVANZANA",
  "CRAVEGGIA",
  "CREAZZO",
  "CRECCHIO",
  "CREDARO",
  "CREDERA RUBBIANO",
  "CREMA",
  "CREMELLA",
  "CREMENAGA",
  "CREMENO",
  "CREMIA",
  "CREMOLINO",
  "CREMONA",
  "CREMOSANO",
  "CRESCENTINO",
  "CRESPADORO",
  "CRESPIATICA",
  "CRESPINA LORENZANA",
  "CRESPINO",
  "CRESSA",
  "CREVACUORE",
  "CREVALCORE",
  "CREVOLADOSSOLA",
  "CRISPANO",
  "CRISPIANO",
  "CRISSOLO",
  "CROCEFIESCHI",
  "CROCETTA DEL MONTELLO",
  "CRODO",
  "CROGNALETO",
  "CROPALATI",
  "CROPANI",
  "CROSIA",
  "CROSIO DELLA VALLE",
  "CROTONE",
  "CROTTA D'ADDA",
  "CROVA",
  "CROVIANA",
  "CRUCOLI",
  "CUASSO AL MONTE",
  "CUCCARO VETERE",
  "CUCCIAGO",
  "CUCEGLIO",
  "CUGGIONO",
  "CUGLIATE-FABIASCO",
  "CUGLIERI",
  "CUGNOLI",
  "CUMIANA",
  "CUMIGNANO SUL NAVIGLIO",
  "CUNARDO",
  "CUNEO",
  "CUNICO",
  "CUORGNÈ",
  "CUPELLO",
  "CUPRA MARITTIMA",
  "CUPRAMONTANA",
  "CURA CARPIGNANO",
  "CURCURIS",
  "CUREGGIO",
  "CURIGLIA CON MONTEVIASCO",
  "CURINGA",
  "CURINO",
  "CURNO",
  "CURON VENOSTA",
  "CURSI",
  "CURTAROLO",
  "CURTATONE",
  "CURTI",
  "CUSAGO",
  "CUSANO MILANINO",
  "CUSANO MUTRI",
  "CUSINO",
  "CUSIO",
  "CUSTONACI",
  "CUTRO",
  "CUTROFIANO",
  "CUVEGLIO",
  "CUVIO",
  "DAIRAGO",
  "DALMINE",
  "DAMBEL",
  "DANTA DI CADORE",
  "DARFO BOARIO TERME",
  "DASÀ",
  "DAVAGNA",
  "DAVERIO",
  "DAVOLI",
  "DAZIO",
  "DECIMOMANNU",
  "DECIMOPUTZU",
  "DECOLLATURA",
  "DEGO",
  "DEIVA MARINA",
  "DELEBIO",
  "DELIA",
  "DELIANUOVA",
  "DELICETO",
  "DELLO",
  "DEMONTE",
  "DENICE",
  "DENNO",
  "DERNICE",
  "DEROVERE",
  "DERUTA",
  "DERVIO",
  "DESANA",
  "DESENZANO DEL GARDA",
  "DESIO",
  "DESULO",
  "DIAMANTE",
  "DIANO ARENTINO",
  "DIANO CASTELLO",
  "DIANO MARINA",
  "DIANO SAN PIETRO",
  "DIANO D'ALBA",
  "DICOMANO",
  "DIGNANO",
  "DIMARO FOLGARIDA",
  "DINAMI",
  "DIPIGNANO",
  "DISO",
  "DIVIGNANO",
  "DIZZASCO",
  "DOBBIACO",
  "DOBERDÒ DEL LAGO",
  "DOGLIANI",
  "DOGLIOLA",
  "DOGNA",
  "DOLCEACQUA",
  "DOLCEDO",
  "DOLCÈ",
  "DOLEGNA DEL COLLIO",
  "DOLIANOVA",
  "DOLO",
  "DOLZAGO",
  "DOMANICO",
  "DOMASO",
  "DOMEGGE DI CADORE",
  "DOMICELLA",
  "DOMODOSSOLA",
  "DOMUS DE MARIA",
  "DOMUSNOVAS",
  "DONATO",
  "DONGO",
  "DONNAS",
  "DONORI",
  "DORGALI",
  "DORIO",
  "DORMELLETTO",
  "DORNO",
  "DORZANO",
  "DOSOLO",
  "DOSSENA",
  "DOSSO DEL LIRO",
  "DOUES",
  "DOVADOLA",
  "DOVERA",
  "DOZZA",
  "DRAGONI",
  "DRAPIA",
  "DRENA",
  "DRENCHIA",
  "DRESANO",
  "DRO",
  "DRONERO",
  "DRUENTO",
  "DRUOGNO",
  "DUALCHI",
  "DUBINO",
  "DUE CARRARE",
  "DUEVILLE",
  "DUGENTA",
  "DUINO AURISINA",
  "DUMENZA",
  "DUNO",
  "DURAZZANO",
  "DURONIA",
  "DUSINO SAN MICHELE",
  "EBOLI",
  "EDOLO",
  "EGNA",
  "ELICE",
  "ELINI",
  "ELLO",
  "ELMAS",
  "ELVA",
  "EMARÈSE",
  "EMPOLI",
  "ENDINE GAIANO",
  "ENEGO",
  "ENEMONZO",
  "ENNA",
  "ENTRACQUE",
  "ENTRATICO",
  "ENVIE",
  "EPISCOPIA",
  "ERACLEA",
  "ERBA",
  "ERBEZZO",
  "ERBUSCO",
  "ERBÈ",
  "ERCHIE",
  "ERCOLANO",
  "ERICE",
  "ERLI",
  "ERTO E CASSO",
  "ERULA",
  "ERVE",
  "ESANATOGLIA",
  "ESCALAPLANO",
  "ESCOLCA",
  "ESINE",
  "ESINO LARIO",
  "ESPERIA",
  "ESPORLATU",
  "ESTE",
  "ESTERZILI",
  "ETROUBLES",
  "EUPILIO",
  "EXILLES",
  "FABBRICA CURONE",
  "FABBRICHE DI VERGEMOLI",
  "FABBRICO",
  "FABRIANO",
  "FABRICA DI ROMA",
  "FABRIZIA",
  "FABRO",
  "FAEDIS",
  "FAEDO VALTELLINO",
  "FAENZA",
  "FAETO",
  "FAGAGNA",
  "FAGGETO LARIO",
  "FAGGIANO",
  "FAGNANO ALTO",
  "FAGNANO CASTELLO",
  "FAGNANO OLONA",
  "FAI DELLA PAGANELLA",
  "FAICCHIO",
  "FALCADE",
  "FALCIANO DEL MASSICO",
  "FALCONARA ALBANESE",
  "FALCONARA MARITTIMA",
  "FALCONE",
  "FALERIA",
  "FALERNA",
  "FALERONE",
  "FALLO",
  "FALOPPIO",
  "FALVATERRA",
  "FALZES",
  "FANANO",
  "FANNA",
  "FANO",
  "FANO ADRIANO",
  "FARA FILIORUM PETRI",
  "FARA GERA D'ADDA",
  "FARA NOVARESE",
  "FARA OLIVANA CON SOLA",
  "FARA SAN MARTINO",
  "FARA VICENTINO",
  "FARA IN SABINA",
  "FARDELLA",
  "FARIGLIANO",
  "FARINDOLA",
  "FARINI",
  "FARNESE",
  "FARRA D'ISONZO",
  "FARRA DI SOLIGO",
  "FASANO",
  "FASCIA",
  "FAUGLIA",
  "FAULE",
  "FAVALE DI MALVARO",
  "FAVARA",
  "FAVIGNANA",
  "FAVRIA",
  "FEISOGLIO",
  "FELETTO",
  "FELINO",
  "FELITTO",
  "FELIZZANO",
  "FELTRE",
  "FENEGRÒ",
  "FENESTRELLE",
  "FERENTILLO",
  "FERENTINO",
  "FERLA",
  "FERMIGNANO",
  "FERMO",
  "FERNO",
  "FEROLETO ANTICO",
  "FEROLETO DELLA CHIESA",
  "FERRANDINA",
  "FERRARA",
  "FERRARA DI MONTE BALDO",
  "FERRAZZANO",
  "FERRERA ERBOGNONE",
  "FERRERA DI VARESE",
  "FERRERE",
  "FERRIERE",
  "FERRUZZANO",
  "FIAMIGNANO",
  "FIANO",
  "FIANO ROMANO",
  "FIASTRA",
  "FIAVÈ",
  "FICARAZZI",
  "FICAROLO",
  "FICARRA",
  "FICULLE",
  "FIDENZA",
  "FIEROZZO",
  "FIESCO",
  "FIESOLE",
  "FIESSE",
  "FIESSO UMBERTIANO",
  "FIESSO D'ARTICO",
  "FIGINO SERENZA",
  "FIGLINE VEGLIATURO",
  "FIGLINE E INCISA VALDARNO",
  "FILACCIANO",
  "FILADELFIA",
  "FILAGO",
  "FILANDARI",
  "FILATTIERA",
  "FILETTINO",
  "FILETTO",
  "FILIANO",
  "FILIGHERA",
  "FILIGNANO",
  "FILOGASO",
  "FILOTTRANO",
  "FINALE EMILIA",
  "FINALE LIGURE",
  "FINO MORNASCO",
  "FINO DEL MONTE",
  "FIORANO CANAVESE",
  "FIORANO MODENESE",
  "FIORANO AL SERIO",
  "FIORENZUOLA D'ARDA",
  "FIRENZE",
  "FIRENZUOLA",
  "FIRMO",
  "FISCAGLIA",
  "FISCIANO",
  "FIUGGI",
  "FIUMALBO",
  "FIUMARA",
  "FIUME VENETO",
  "FIUMEDINISI",
  "FIUMEFREDDO BRUZIO",
  "FIUMEFREDDO DI SICILIA",
  "FIUMICELLO VILLA VICENTINA",
  "FIUMICINO",
  "FIUMINATA",
  "FIVIZZANO",
  "FIÈ ALLO SCILIAR",
  "FLAIBANO",
  "FLERO",
  "FLORESTA",
  "FLORIDIA",
  "FLORINAS",
  "FLUMERI",
  "FLUMINIMAGGIORE",
  "FLUSSIO",
  "FOBELLO",
  "FOGGIA",
  "FOGLIANISE",
  "FOGLIANO REDIPUGLIA",
  "FOGLIZZO",
  "FOIANO DELLA CHIANA",
  "FOIANO DI VAL FORTORE",
  "FOLGARIA",
  "FOLIGNANO",
  "FOLIGNO",
  "FOLLINA",
  "FOLLO",
  "FOLLONICA",
  "FOMBIO",
  "FONDACHELLI-FANTINA",
  "FONDI",
  "FONNI",
  "FONTAINEMORE",
  "FONTANA LIRI",
  "FONTANAFREDDA",
  "FONTANAROSA",
  "FONTANELICE",
  "FONTANELLA",
  "FONTANELLATO",
  "FONTANELLE",
  "FONTANETO D'AGOGNA",
  "FONTANETTO PO",
  "FONTANIGORDA",
  "FONTANILE",
  "FONTANIVA",
  "FONTE",
  "FONTE NUOVA",
  "FONTECCHIO",
  "FONTECHIARI",
  "FONTEGRECA",
  "FONTENO",
  "FONTEVIVO",
  "FONZASO",
  "FOPPOLO",
  "FORANO",
  "FORCE",
  "FORCHIA",
  "FORCOLA",
  "FORDONGIANUS",
  "FORENZA",
  "FORESTO SPARSO",
  "FORGARIA NEL FRIULI",
  "FORINO",
  "FORIO",
  "FORLIMPOPOLI",
  "FORLÌ",
  "FORLÌ DEL SANNIO",
  "FORMAZZA",
  "FORMELLO",
  "FORMIA",
  "FORMICOLA",
  "FORMIGARA",
  "FORMIGINE",
  "FORMIGLIANA",
  "FORNACE",
  "FORNELLI",
  "FORNI AVOLTRI",
  "FORNI DI SOPRA",
  "FORNI DI SOTTO",
  "FORNO CANAVESE",
  "FORNOVO SAN GIOVANNI",
  "FORNOVO DI TARO",
  "FORTE DEI MARMI",
  "FORTEZZA",
  "FORTUNAGO",
  "FORZA D'AGRÒ",
  "FOSCIANDORA",
  "FOSDINOVO",
  "FOSSA",
  "FOSSACESIA",
  "FOSSALTA DI PIAVE",
  "FOSSALTA DI PORTOGRUARO",
  "FOSSALTO",
  "FOSSANO",
  "FOSSATO SERRALTA",
  "FOSSATO DI VICO",
  "FOSSOMBRONE",
  "FOSSÒ",
  "FOZA",
  "FRABOSA SOPRANA",
  "FRABOSA SOTTANA",
  "FRACONALTO",
  "FRAGAGNANO",
  "FRAGNETO MONFORTE",
  "FRAGNETO L'ABATE",
  "FRAINE",
  "FRAMURA",
  "FRANCAVILLA ANGITOLA",
  "FRANCAVILLA BISIO",
  "FRANCAVILLA FONTANA",
  "FRANCAVILLA MARITTIMA",
  "FRANCAVILLA AL MARE",
  "FRANCAVILLA D'ETE",
  "FRANCAVILLA DI SICILIA",
  "FRANCAVILLA IN SINNI",
  "FRANCICA",
  "FRANCOFONTE",
  "FRANCOLISE",
  "FRASCARO",
  "FRASCAROLO",
  "FRASCATI",
  "FRASCINETO",
  "FRASSILONGO",
  "FRASSINELLE POLESINE",
  "FRASSINELLO MONFERRATO",
  "FRASSINETO PO",
  "FRASSINETTO",
  "FRASSINO",
  "FRASSINORO",
  "FRASSO SABINO",
  "FRASSO TELESINO",
  "FRATTA POLESINE",
  "FRATTA TODINA",
  "FRATTAMAGGIORE",
  "FRATTAMINORE",
  "FRATTE ROSA",
  "FRAZZANÒ",
  "FREGONA",
  "FRESAGRANDINARIA",
  "FRESONARA",
  "FRIGENTO",
  "FRIGNANO",
  "FRINCO",
  "FRISA",
  "FRISANCO",
  "FRONT",
  "FRONTINO",
  "FRONTONE",
  "FROSINONE",
  "FROSOLONE",
  "FROSSASCO",
  "FRUGAROLO",
  "FUBINE MONFERRATO",
  "FUCECCHIO",
  "FUIPIANO VALLE IMAGNA",
  "FUMANE",
  "FUMONE",
  "FUNES",
  "FURCI",
  "FURCI SICULO",
  "FURNARI",
  "FURORE",
  "FURTEI",
  "FUSCALDO",
  "FUSIGNANO",
  "FUSINE",
  "FUTANI",
  "FÉNIS",
  "GABBIONETA-BINANUOVA",
  "GABIANO",
  "GABICCE MARE",
  "GABY",
  "GADESCO-PIEVE DELMONA",
  "GADONI",
  "GAETA",
  "GAGGI",
  "GAGGIANO",
  "GAGGIO MONTANO",
  "GAGLIANICO",
  "GAGLIANO ATERNO",
  "GAGLIANO CASTELFERRATO",
  "GAGLIANO DEL CAPO",
  "GAGLIATO",
  "GAGLIOLE",
  "GAIARINE",
  "GAIBA",
  "GAIOLA",
  "GAIOLE IN CHIANTI",
  "GAIRO",
  "GAIS",
  "GALATI MAMERTINO",
  "GALATINA",
  "GALATONE",
  "GALATRO",
  "GALBIATE",
  "GALEATA",
  "GALGAGNANO",
  "GALLARATE",
  "GALLESE",
  "GALLIATE",
  "GALLIATE LOMBARDO",
  "GALLIAVOLA",
  "GALLICANO",
  "GALLICANO NEL LAZIO",
  "GALLICCHIO",
  "GALLIERA",
  "GALLIERA VENETA",
  "GALLINARO",
  "GALLIO",
  "GALLIPOLI",
  "GALLO MATESE",
  "GALLODORO",
  "GALLUCCIO",
  "GALTELLÌ",
  "GALZIGNANO TERME",
  "GAMALERO",
  "GAMBARA",
  "GAMBARANA",
  "GAMBASCA",
  "GAMBASSI TERME",
  "GAMBATESA",
  "GAMBELLARA",
  "GAMBERALE",
  "GAMBETTOLA",
  "GAMBOLÒ",
  "GAMBUGLIANO",
  "GANDELLINO",
  "GANDINO",
  "GANDOSSO",
  "GANGI",
  "GARAGUSO",
  "GARBAGNA",
  "GARBAGNA NOVARESE",
  "GARBAGNATE MILANESE",
  "GARBAGNATE MONASTERO",
  "GARDA",
  "GARDONE RIVIERA",
  "GARDONE VAL TROMPIA",
  "GARESSIO",
  "GARGALLO",
  "GARGAZZONE",
  "GARGNANO",
  "GARLASCO",
  "GARLATE",
  "GARLENDA",
  "GARNIGA TERME",
  "GARZENO",
  "GARZIGLIANA",
  "GASPERINA",
  "GASSINO TORINESE",
  "GATTATICO",
  "GATTEO",
  "GATTICO-VERUNO",
  "GATTINARA",
  "GAVARDO",
  "GAVELLO",
  "GAVERINA TERME",
  "GAVI",
  "GAVIGNANO",
  "GAVIRATE",
  "GAVOI",
  "GAVORRANO",
  "GAZOLDO DEGLI IPPOLITI",
  "GAZZADA SCHIANNO",
  "GAZZANIGA",
  "GAZZO",
  "GAZZO VERONESE",
  "GAZZOLA",
  "GAZZUOLO",
  "GELA",
  "GEMMANO",
  "GEMONA DEL FRIULI",
  "GEMONIO",
  "GENAZZANO",
  "GENGA",
  "GENIVOLTA",
  "GENOLA",
  "GENONI",
  "GENOVA",
  "GENURI",
  "GENZANO DI LUCANIA",
  "GENZANO DI ROMA",
  "GERA LARIO",
  "GERACE",
  "GERACI SICULO",
  "GERANO",
  "GERENZAGO",
  "GERENZANO",
  "GERGEI",
  "GERMAGNANO",
  "GERMAGNO",
  "GERMIGNAGA",
  "GEROCARNE",
  "GEROLA ALTA",
  "GERRE DE' CAPRIOLI",
  "GESICO",
  "GESSATE",
  "GESSOPALENA",
  "GESTURI",
  "GESUALDO",
  "GHEDI",
  "GHEMME",
  "GHIFFA",
  "GHILARZA",
  "GHISALBA",
  "GHISLARENGO",
  "GIACCIANO CON BARUCHELLA",
  "GIAGLIONE",
  "GIANICO",
  "GIANO VETUSTO",
  "GIANO DELL'UMBRIA",
  "GIARDINELLO",
  "GIARDINI-NAXOS",
  "GIAROLE",
  "GIARRATANA",
  "GIARRE",
  "GIAVE",
  "GIAVENO",
  "GIAVERA DEL MONTELLO",
  "GIBA",
  "GIBELLINA",
  "GIFFLENGA",
  "GIFFONE",
  "GIFFONI SEI CASALI",
  "GIFFONI VALLE PIANA",
  "GIGNESE",
  "GIGNOD",
  "GILDONE",
  "GIMIGLIANO",
  "GINESTRA",
  "GINESTRA DEGLI SCHIAVONI",
  "GINOSA",
  "GIOI",
  "GIOIA SANNITICA",
  "GIOIA TAURO",
  "GIOIA DEI MARSI",
  "GIOIA DEL COLLE",
  "GIOIOSA IONICA",
  "GIOIOSA MAREA",
  "GIOVE",
  "GIOVINAZZO",
  "GIOVO",
  "GIRASOLE",
  "GIRIFALCO",
  "GISSI",
  "GIUGGIANELLO",
  "GIUGLIANO IN CAMPANIA",
  "GIULIANA",
  "GIULIANO TEATINO",
  "GIULIANO DI ROMA",
  "GIULIANOVA",
  "GIUNGANO",
  "GIURDIGNANO",
  "GIUSSAGO",
  "GIUSSANO",
  "GIUSTENICE",
  "GIUSTINO",
  "GIUSVALLA",
  "GIVOLETTO",
  "GIZZERIA",
  "GLORENZA",
  "GODEGA DI SANT'URBANO",
  "GODIASCO SALICE TERME",
  "GODRANO",
  "GOITO",
  "GOLASECCA",
  "GOLFERENZO",
  "GOLFO ARANCI",
  "GOMBITO",
  "GONARS",
  "GONI",
  "GONNESA",
  "GONNOSCODINA",
  "GONNOSFANADIGA",
  "GONNOSNÒ",
  "GONNOSTRAMATZA",
  "GONZAGA",
  "GORDONA",
  "GORGA",
  "GORGO AL MONTICANO",
  "GORGOGLIONE",
  "GORGONZOLA",
  "GORIANO SICOLI",
  "GORIZIA",
  "GORLA MAGGIORE",
  "GORLA MINORE",
  "GORLAGO",
  "GORLE",
  "GORNATE OLONA",
  "GORNO",
  "GORO",
  "GORRETO",
  "GORZEGNO",
  "GOSALDO",
  "GOSSOLENGO",
  "GOTTASECCA",
  "GOTTOLENGO",
  "GOVONE",
  "GOZZANO",
  "GRADARA",
  "GRADISCA D'ISONZO",
  "GRADO",
  "GRADOLI",
  "GRAFFIGNANA",
  "GRAFFIGNANO",
  "GRAGLIA",
  "GRAGNANO",
  "GRAGNANO TREBBIENSE",
  "GRAMMICHELE",
  "GRANA",
  "GRANAROLO DELL'EMILIA",
  "GRANDATE",
  "GRANDOLA ED UNITI",
  "GRANITI",
  "GRANOZZO CON MONTICELLO",
  "GRANTOLA",
  "GRANTORTO",
  "GRANZE",
  "GRASSANO",
  "GRASSOBBIO",
  "GRATTERI",
  "GRAVEDONA ED UNITI",
  "GRAVELLONA LOMELLINA",
  "GRAVELLONA TOCE",
  "GRAVERE",
  "GRAVINA DI CATANIA",
  "GRAVINA IN PUGLIA",
  "GRAZZANISE",
  "GRAZZANO BADOGLIO",
  "GRECCIO",
  "GRECI",
  "GREGGIO",
  "GREMIASCO",
  "GRESSAN",
  "GRESSONEY-LA-TRINITÉ",
  "GRESSONEY-SAINT-JEAN",
  "GREVE IN CHIANTI",
  "GREZZAGO",
  "GREZZANA",
  "GRIANTE",
  "GRICIGNANO DI AVERSA",
  "GRIGNASCO",
  "GRIGNO",
  "GRIMACCO",
  "GRIMALDI",
  "GRINZANE CAVOUR",
  "GRISIGNANO DI ZOCCO",
  "GRISOLIA",
  "GRIZZANA MORANDI",
  "GROGNARDO",
  "GROMO",
  "GRONDONA",
  "GRONE",
  "GRONTARDO",
  "GROPELLO CAIROLI",
  "GROPPARELLO",
  "GROSCAVALLO",
  "GROSIO",
  "GROSOTTO",
  "GROSSETO",
  "GROSSO",
  "GROTTAFERRATA",
  "GROTTAGLIE",
  "GROTTAMINARDA",
  "GROTTAMMARE",
  "GROTTAZZOLINA",
  "GROTTE",
  "GROTTE DI CASTRO",
  "GROTTERIA",
  "GROTTOLE",
  "GROTTOLELLA",
  "GRUARO",
  "GRUGLIASCO",
  "GRUMELLO CREMONESE ED UNITI",
  "GRUMELLO DEL MONTE",
  "GRUMENTO NOVA",
  "GRUMO APPULA",
  "GRUMO NEVANO",
  "GRUMOLO DELLE ABBADESSE",
  "GUAGNANO",
  "GUALDO",
  "GUALDO CATTANEO",
  "GUALDO TADINO",
  "GUALTIERI",
  "GUALTIERI SICAMINÒ",
  "GUAMAGGIORE",
  "GUANZATE",
  "GUARCINO",
  "GUARDA VENETA",
  "GUARDABOSONE",
  "GUARDAMIGLIO",
  "GUARDAVALLE",
  "GUARDEA",
  "GUARDIA LOMBARDI",
  "GUARDIA PERTICARA",
  "GUARDIA PIEMONTESE",
  "GUARDIA SANFRAMONDI",
  "GUARDIAGRELE",
  "GUARDIALFIERA",
  "GUARDIAREGIA",
  "GUARDISTALLO",
  "GUARENE",
  "GUASILA",
  "GUASTALLA",
  "GUAZZORA",
  "GUBBIO",
  "GUDO VISCONTI",
  "GUGLIONESI",
  "GUIDIZZOLO",
  "GUIDONIA MONTECELIO",
  "GUIGLIA",
  "GUILMI",
  "GURRO",
  "GUSPINI",
  "GUSSAGO",
  "GUSSOLA",
  "HÔNE",
  "IDRO",
  "IGLESIAS",
  "IGLIANO",
  "ILBONO",
  "ILLASI",
  "ILLORAI",
  "IMBERSAGO",
  "IMER",
  "IMOLA",
  "IMPERIA",
  "IMPRUNETA",
  "INARZO",
  "INCISA SCAPACCINO",
  "INCUDINE",
  "INDUNO OLONA",
  "INGRIA",
  "INTRAGNA",
  "INTROBIO",
  "INTROD",
  "INTRODACQUA",
  "INVERIGO",
  "INVERNO E MONTELEONE",
  "INVERSO PINASCA",
  "INVERUNO",
  "INVORIO",
  "INZAGO",
  "IONADI",
  "IRGOLI",
  "IRMA",
  "IRSINA",
  "ISASCA",
  "ISCA SULLO IONIO",
  "ISCHIA",
  "ISCHIA DI CASTRO",
  "ISCHITELLA",
  "ISEO",
  "ISERA",
  "ISERNIA",
  "ISILI",
  "ISNELLO",
  "ISOLA DOVARESE",
  "ISOLA RIZZA",
  "ISOLA SANT'ANTONIO",
  "ISOLA VICENTINA",
  "ISOLA D'ASTI",
  "ISOLA DEL CANTONE",
  "ISOLA DEL GIGLIO",
  "ISOLA DEL GRAN SASSO D'ITALIA",
  "ISOLA DEL LIRI",
  "ISOLA DEL PIANO",
  "ISOLA DELLA SCALA",
  "ISOLA DELLE FEMMINE",
  "ISOLA DI CAPO RIZZUTO",
  "ISOLA DI FONDRA",
  "ISOLABELLA",
  "ISOLABONA",
  "ISOLE TREMITI",
  "ISORELLA",
  "ISPANI",
  "ISPICA",
  "ISPRA",
  "ISSIGLIO",
  "ISSIME",
  "ISSO",
  "ISSOGNE",
  "ISTRANA",
  "ITALA",
  "ITRI",
  "ITTIREDDU",
  "ITTIRI",
  "IVREA",
  "IZANO",
  "JACURSO",
  "JELSI",
  "JENNE",
  "JERAGO CON ORAGO",
  "JERZU",
  "JESI",
  "JESOLO",
  "JOLANDA DI SAVOIA",
  "JOPPOLO",
  "JOPPOLO GIANCAXIO",
  "JOVENÇAN",
  "L'AQUILA",
  "LA CASSA",
  "LA LOGGIA",
  "LA MADDALENA",
  "LA MAGDELEINE",
  "LA MORRA",
  "LA SALLE",
  "LA SPEZIA",
  "LA THUILE",
  "LA VALLE",
  "LA VALLE AGORDINA",
  "LA VALLETTA BRIANZA",
  "LABICO",
  "LABRO",
  "LACCHIARELLA",
  "LACCO AMENO",
  "LACEDONIA",
  "LACES",
  "LACONI",
  "LADISPOLI",
  "LAERRU",
  "LAGANADI",
  "LAGHI",
  "LAGLIO",
  "LAGNASCO",
  "LAGO",
  "LAGONEGRO",
  "LAGOSANTO",
  "LAGUNDO",
  "LAIGUEGLIA",
  "LAINATE",
  "LAINO",
  "LAINO BORGO",
  "LAINO CASTELLO",
  "LAION",
  "LAIVES",
  "LAJATICO",
  "LALLIO",
  "LAMA MOCOGNO",
  "LAMA DEI PELIGNI",
  "LAMBRUGO",
  "LAMEZIA TERME",
  "LAMON",
  "LAMPEDUSA E LINOSA",
  "LAMPORECCHIO",
  "LAMPORO",
  "LANA",
  "LANCIANO",
  "LANDIONA",
  "LANDRIANO",
  "LANGHIRANO",
  "LANGOSCO",
  "LANUSEI",
  "LANUVIO",
  "LANZADA",
  "LANZO TORINESE",
  "LAPEDONA",
  "LAPIO",
  "LAPPANO",
  "LARCIANO",
  "LARDIRAGO",
  "LARIANO",
  "LARINO",
  "LAS PLASSAS",
  "LASA",
  "LASCARI",
  "LASNIGO",
  "LASTEBASSE",
  "LASTRA A SIGNA",
  "LATERA",
  "LATERINA PERGINE VALDARNO",
  "LATERZA",
  "LATIANO",
  "LATINA",
  "LATISANA",
  "LATRONICO",
  "LATTARICO",
  "LAUCO",
  "LAUREANA CILENTO",
  "LAUREANA DI BORRELLO",
  "LAUREGNO",
  "LAURENZANA",
  "LAURIA",
  "LAURIANO",
  "LAURINO",
  "LAURITO",
  "LAURO",
  "LAVAGNA",
  "LAVAGNO",
  "LAVARONE",
  "LAVELLO",
  "LAVENA PONTE TRESA",
  "LAVENO-MOMBELLO",
  "LAVENONE",
  "LAVIANO",
  "LAVIS",
  "LAZISE",
  "LAZZATE",
  "LECCE",
  "LECCE NEI MARSI",
  "LECCO",
  "LEDRO",
  "LEFFE",
  "LEGGIUNO",
  "LEGNAGO",
  "LEGNANO",
  "LEGNARO",
  "LEI",
  "LEINI",
  "LEIVI",
  "LEMIE",
  "LENDINARA",
  "LENI",
  "LENNA",
  "LENO",
  "LENOLA",
  "LENTA",
  "LENTATE SUL SEVESO",
  "LENTELLA",
  "LENTINI",
  "LEONESSA",
  "LEONFORTE",
  "LEPORANO",
  "LEQUILE",
  "LEQUIO BERRIA",
  "LEQUIO TANARO",
  "LERCARA FRIDDI",
  "LERICI",
  "LERMA",
  "LESA",
  "LESEGNO",
  "LESIGNANO DE' BAGNI",
  "LESINA",
  "LESMO",
  "LESSOLO",
  "LESSONA",
  "LESTIZZA",
  "LETINO",
  "LETOJANNI",
  "LETTERE",
  "LETTOMANOPPELLO",
  "LETTOPALENA",
  "LEVANTO",
  "LEVATE",
  "LEVERANO",
  "LEVICE",
  "LEVICO TERME",
  "LEVONE",
  "LEZZENO",
  "LIBERI",
  "LIBRIZZI",
  "LICATA",
  "LICCIANA NARDI",
  "LICENZA",
  "LICODIA EUBEA",
  "LIERNA",
  "LIGNANA",
  "LIGNANO SABBIADORO",
  "LILLIANES",
  "LIMANA",
  "LIMATOLA",
  "LIMBADI",
  "LIMBIATE",
  "LIMENA",
  "LIMIDO COMASCO",
  "LIMINA",
  "LIMONE PIEMONTE",
  "LIMONE SUL GARDA",
  "LIMOSANO",
  "LINAROLO",
  "LINGUAGLOSSA",
  "LIONI",
  "LIPARI",
  "LIPOMO",
  "LIRIO",
  "LISCATE",
  "LISCIA",
  "LISCIANO NICCONE",
  "LISIO",
  "LISSONE",
  "LIVERI",
  "LIVIGNO",
  "LIVINALLONGO DEL COL DI LANA",
  "LIVO",
  "LIVORNO",
  "LIVORNO FERRARIS",
  "LIVRAGA",
  "LIZZANELLO",
  "LIZZANO",
  "LIZZANO IN BELVEDERE",
  "LOANO",
  "LOAZZOLO",
  "LOCANA",
  "LOCATE VARESINO",
  "LOCATE DI TRIULZI",
  "LOCATELLO",
  "LOCERI",
  "LOCOROTONDO",
  "LOCRI",
  "LOCULI",
  "LODI",
  "LODI VECCHIO",
  "LODINE",
  "LODRINO",
  "LODÈ",
  "LOGRATO",
  "LOIANO",
  "LOIRI PORTO SAN PAOLO",
  "LOMAGNA",
  "LOMAZZO",
  "LOMBARDORE",
  "LOMBRIASCO",
  "LOMELLO",
  "LONA-LASES",
  "LONATE CEPPINO",
  "LONATE POZZOLO",
  "LONATO DEL GARDA",
  "LONDA",
  "LONGANO",
  "LONGARE",
  "LONGARONE",
  "LONGHENA",
  "LONGI",
  "LONGIANO",
  "LONGOBARDI",
  "LONGOBUCCO",
  "LONGONE SABINO",
  "LONGONE AL SEGRINO",
  "LONIGO",
  "LORANZÈ",
  "LOREGGIA",
  "LOREGLIA",
  "LORENZAGO DI CADORE",
  "LOREO",
  "LORETO",
  "LORETO APRUTINO",
  "LORIA",
  "LORO CIUFFENNA",
  "LORO PICENO",
  "LORSICA",
  "LOSINE",
  "LOTZORAI",
  "LOVERE",
  "LOVERO",
  "LOZIO",
  "LOZZA",
  "LOZZO ATESTINO",
  "LOZZO DI CADORE",
  "LOZZOLO",
  "LU E CUCCARO MONFERRATO",
  "LUBRIANO",
  "LUCCA",
  "LUCCA SICULA",
  "LUCERA",
  "LUCIGNANO",
  "LUCINASCO",
  "LUCITO",
  "LUCO DEI MARSI",
  "LUCOLI",
  "LUGAGNANO VAL D'ARDA",
  "LUGNANO IN TEVERINA",
  "LUGO",
  "LUGO DI VICENZA",
  "LUINO",
  "LUISAGO",
  "LULA",
  "LUMARZO",
  "LUMEZZANE",
  "LUNAMATRONA",
  "LUNANO",
  "LUNGAVILLA",
  "LUNGRO",
  "LUNI",
  "LUOGOSANO",
  "LUOGOSANTO",
  "LUPARA",
  "LURAGO MARINONE",
  "LURAGO D'ERBA",
  "LURANO",
  "LURAS",
  "LURATE CACCIVIO",
  "LUSCIANO",
  "LUSERNA",
  "LUSERNA SAN GIOVANNI",
  "LUSERNETTA",
  "LUSEVERA",
  "LUSIA",
  "LUSIANA CONCO",
  "LUSIGLIÈ",
  "LUSON",
  "LUSTRA",
  "LUVINATE",
  "LUZZANA",
  "LUZZARA",
  "LUZZI",
  "MACCAGNO CON PINO E VEDDASCA",
  "MACCASTORNA",
  "MACCHIA VALFORTORE",
  "MACCHIA D'ISERNIA",
  "MACCHIAGODENA",
  "MACELLO",
  "MACERATA",
  "MACERATA CAMPANIA",
  "MACERATA FELTRIA",
  "MACHERIO",
  "MACLODIO",
  "MACOMER",
  "MACRA",
  "MACUGNAGA",
  "MADDALONI",
  "MADESIMO",
  "MADIGNANO",
  "MADONE",
  "MADONNA DEL SASSO",
  "MADRUZZO",
  "MAENZA",
  "MAFALDA",
  "MAGASA",
  "MAGENTA",
  "MAGGIORA",
  "MAGHERNO",
  "MAGIONE",
  "MAGISANO",
  "MAGLIANO ALFIERI",
  "MAGLIANO ALPI",
  "MAGLIANO ROMANO",
  "MAGLIANO SABINA",
  "MAGLIANO VETERE",
  "MAGLIANO DE' MARSI",
  "MAGLIANO DI TENNA",
  "MAGLIANO IN TOSCANA",
  "MAGLIE",
  "MAGLIOLO",
  "MAGLIONE",
  "MAGNACAVALLO",
  "MAGNAGO",
  "MAGNANO",
  "MAGNANO IN RIVIERA",
  "MAGOMADAS",
  "MAGREGLIO",
  "MAGRÈ SULLA STRADA DEL VINO",
  "MAIDA",
  "MAIERATO",
  "MAIERÀ",
  "MAIOLATI SPONTINI",
  "MAIOLO",
  "MAIORI",
  "MAIRAGO",
  "MAIRANO",
  "MAISSANA",
  "MAJANO",
  "MALAGNINO",
  "MALALBERGO",
  "MALBORGHETTO VALBRUNA",
  "MALCESINE",
  "MALEGNO",
  "MALEO",
  "MALESCO",
  "MALETTO",
  "MALFA",
  "MALGRATE",
  "MALITO",
  "MALLARE",
  "MALLES VENOSTA",
  "MALNATE",
  "MALO",
  "MALONNO",
  "MALTIGNANO",
  "MALVAGNA",
  "MALVICINO",
  "MALVITO",
  "MALÉ",
  "MAMMOLA",
  "MAMOIADA",
  "MANCIANO",
  "MANDANICI",
  "MANDAS",
  "MANDATORICCIO",
  "MANDELA",
  "MANDELLO VITTA",
  "MANDELLO DEL LARIO",
  "MANDURIA",
  "MANERBA DEL GARDA",
  "MANERBIO",
  "MANFREDONIA",
  "MANGO",
  "MANGONE",
  "MANIACE",
  "MANIAGO",
  "MANOCALZATI",
  "MANOPPELLO",
  "MANSUÈ",
  "MANTA",
  "MANTELLO",
  "MANTOVA",
  "MANZANO",
  "MANZIANA",
  "MAPELLO",
  "MAPPANO",
  "MARA",
  "MARACALAGONIS",
  "MARANELLO",
  "MARANO EQUO",
  "MARANO LAGUNARE",
  "MARANO MARCHESATO",
  "MARANO PRINCIPATO",
  "MARANO TICINO",
  "MARANO VICENTINO",
  "MARANO DI NAPOLI",
  "MARANO DI VALPOLICELLA",
  "MARANO SUL PANARO",
  "MARANZANA",
  "MARATEA",
  "MARCALLO CON CASONE",
  "MARCARIA",
  "MARCEDUSA",
  "MARCELLINA",
  "MARCELLINARA",
  "MARCETELLI",
  "MARCHENO",
  "MARCHIROLO",
  "MARCIANA",
  "MARCIANA MARINA",
  "MARCIANISE",
  "MARCIANO DELLA CHIANA",
  "MARCIGNAGO",
  "MARCON",
  "MAREBBE",
  "MARENE",
  "MARENO DI PIAVE",
  "MARENTINO",
  "MARETTO",
  "MARGARITA",
  "MARGHERITA DI SAVOIA",
  "MARGNO",
  "MARIANA MANTOVANA",
  "MARIANO COMENSE",
  "MARIANO DEL FRIULI",
  "MARIANOPOLI",
  "MARIGLIANELLA",
  "MARIGLIANO",
  "MARINA DI GIOIOSA IONICA",
  "MARINEO",
  "MARINO",
  "MARLENGO",
  "MARLIANA",
  "MARMENTINO",
  "MARMIROLO",
  "MARMORA",
  "MARNATE",
  "MARONE",
  "MAROPATI",
  "MAROSTICA",
  "MARRADI",
  "MARRUBIU",
  "MARSAGLIA",
  "MARSALA",
  "MARSCIANO",
  "MARSICO NUOVO",
  "MARSICOVETERE",
  "MARTA",
  "MARTANO",
  "MARTELLAGO",
  "MARTELLO",
  "MARTIGNACCO",
  "MARTIGNANA DI PO",
  "MARTIGNANO",
  "MARTINA FRANCA",
  "MARTINENGO",
  "MARTINIANA PO",
  "MARTINSICURO",
  "MARTIRANO",
  "MARTIRANO LOMBARDO",
  "MARTIS",
  "MARTONE",
  "MARUDO",
  "MARUGGIO",
  "MARZABOTTO",
  "MARZANO",
  "MARZANO APPIO",
  "MARZANO DI NOLA",
  "MARZI",
  "MARZIO",
  "MASAINAS",
  "MASATE",
  "MASCALI",
  "MASCALUCIA",
  "MASCHITO",
  "MASCIAGO PRIMO",
  "MASER",
  "MASERA",
  "MASERADA SUL PIAVE",
  "MASERÀ DI PADOVA",
  "MASI",
  "MASI TORELLO",
  "MASIO",
  "MASLIANICO",
  "MASONE",
  "MASSA",
  "MASSA FERMANA",
  "MASSA LOMBARDA",
  "MASSA LUBRENSE",
  "MASSA MARITTIMA",
  "MASSA MARTANA",
  "MASSA D'ALBE",
  "MASSA DI SOMMA",
  "MASSA E COZZILE",
  "MASSAFRA",
  "MASSALENGO",
  "MASSANZAGO",
  "MASSAROSA",
  "MASSAZZA",
  "MASSELLO",
  "MASSERANO",
  "MASSIGNANO",
  "MASSIMENO",
  "MASSIMINO",
  "MASSINO VISCONTI",
  "MASSIOLA",
  "MASULLAS",
  "MATELICA",
  "MATERA",
  "MATHI",
  "MATINO",
  "MATRICE",
  "MATTIE",
  "MATTINATA",
  "MAZARA DEL VALLO",
  "MAZZANO",
  "MAZZANO ROMANO",
  "MAZZARINO",
  "MAZZARRONE",
  "MAZZARRÀ SANT'ANDREA",
  "MAZZIN",
  "MAZZO DI VALTELLINA",
  "MAZZÈ",
  "MEANA SARDO",
  "MEANA DI SUSA",
  "MEDA",
  "MEDE",
  "MEDEA",
  "MEDESANO",
  "MEDICINA",
  "MEDIGLIA",
  "MEDOLAGO",
  "MEDOLE",
  "MEDOLLA",
  "MEDUNA DI LIVENZA",
  "MEDUNO",
  "MEGLIADINO SAN VITALE",
  "MEINA",
  "MELARA",
  "MELAZZO",
  "MELDOLA",
  "MELE",
  "MELEGNANO",
  "MELENDUGNO",
  "MELETI",
  "MELFI",
  "MELICUCCO",
  "MELICUCCÀ",
  "MELILLI",
  "MELISSA",
  "MELISSANO",
  "MELITO IRPINO",
  "MELITO DI NAPOLI",
  "MELITO DI PORTO SALVO",
  "MELIZZANO",
  "MELLE",
  "MELLO",
  "MELPIGNANO",
  "MELTINA",
  "MELZO",
  "MENAGGIO",
  "MENCONICO",
  "MENDATICA",
  "MENDICINO",
  "MENFI",
  "MENTANA",
  "MEOLO",
  "MERANA",
  "MERANO",
  "MERATE",
  "MERCALLO",
  "MERCATELLO SUL METAURO",
  "MERCATINO CONCA",
  "MERCATO SAN SEVERINO",
  "MERCATO SARACENO",
  "MERCENASCO",
  "MERCOGLIANO",
  "MERETO DI TOMBA",
  "MERGO",
  "MERGOZZO",
  "MERLARA",
  "MERLINO",
  "MERONE",
  "MERÌ",
  "MESAGNE",
  "MESE",
  "MESENZANA",
  "MESERO",
  "MESOLA",
  "MESORACA",
  "MESSINA",
  "MESTRINO",
  "META",
  "MEZZAGO",
  "MEZZANA",
  "MEZZANA BIGLI",
  "MEZZANA MORTIGLIENGO",
  "MEZZANA RABATTONE",
  "MEZZANE DI SOTTO",
  "MEZZANEGO",
  "MEZZANINO",
  "MEZZANO",
  "MEZZENILE",
  "MEZZOCORONA",
  "MEZZOJUSO",
  "MEZZOLDO",
  "MEZZOLOMBARDO",
  "MEZZOMERICO",
  "MIAGLIANO",
  "MIANE",
  "MIASINO",
  "MIAZZINA",
  "MICIGLIANO",
  "MIGGIANO",
  "MIGLIANICO",
  "MIGLIERINA",
  "MIGLIONICO",
  "MIGNANEGO",
  "MIGNANO MONTE LUNGO",
  "MILANO",
  "MILAZZO",
  "MILENA",
  "MILETO",
  "MILIS",
  "MILITELLO ROSMARINO",
  "MILITELLO IN VAL DI CATANIA",
  "MILLESIMO",
  "MILO",
  "MILZANO",
  "MINEO",
  "MINERBE",
  "MINERBIO",
  "MINERVINO MURGE",
  "MINERVINO DI LECCE",
  "MINORI",
  "MINTURNO",
  "MINUCCIANO",
  "MIOGLIA",
  "MIRA",
  "MIRABELLA ECLANO",
  "MIRABELLA IMBACCARI",
  "MIRABELLO MONFERRATO",
  "MIRABELLO SANNITICO",
  "MIRADOLO TERME",
  "MIRANDA",
  "MIRANDOLA",
  "MIRANO",
  "MIRTO",
  "MISANO ADRIATICO",
  "MISANO DI GERA D'ADDA",
  "MISILISCEMI",
  "MISILMERI",
  "MISINTO",
  "MISSAGLIA",
  "MISSANELLO",
  "MISTERBIANCO",
  "MISTRETTA",
  "MOASCA",
  "MOCONESI",
  "MODENA",
  "MODICA",
  "MODIGLIANA",
  "MODOLO",
  "MODUGNO",
  "MOENA",
  "MOGGIO",
  "MOGGIO UDINESE",
  "MOGLIA",
  "MOGLIANO",
  "MOGLIANO VENETO",
  "MOGORELLA",
  "MOGORO",
  "MOIANO",
  "MOIMACCO",
  "MOIO ALCANTARA",
  "MOIO DE' CALVI",
  "MOIO DELLA CIVITELLA",
  "MOIOLA",
  "MOLA DI BARI",
  "MOLARE",
  "MOLAZZANA",
  "MOLFETTA",
  "MOLINA ATERNO",
  "MOLINARA",
  "MOLINELLA",
  "MOLINI DI TRIORA",
  "MOLINO DEI TORTI",
  "MOLISE",
  "MOLITERNO",
  "MOLLIA",
  "MOLOCHIO",
  "MOLTENO",
  "MOLTRASIO",
  "MOLVENO",
  "MOMBALDONE",
  "MOMBARCARO",
  "MOMBAROCCIO",
  "MOMBARUZZO",
  "MOMBASIGLIO",
  "MOMBELLO MONFERRATO",
  "MOMBELLO DI TORINO",
  "MOMBERCELLI",
  "MOMO",
  "MOMPANTERO",
  "MOMPEO",
  "MOMPERONE",
  "MONACILIONI",
  "MONALE",
  "MONASTERACE",
  "MONASTERO BORMIDA",
  "MONASTERO DI LANZO",
  "MONASTERO DI VASCO",
  "MONASTEROLO CASOTTO",
  "MONASTEROLO DEL CASTELLO",
  "MONASTEROLO DI SAVIGLIANO",
  "MONASTIER DI TREVISO",
  "MONASTIR",
  "MONCALIERI",
  "MONCALVO",
  "MONCENISIO",
  "MONCESTINO",
  "MONCHIERO",
  "MONCHIO DELLE CORTI",
  "MONCRIVELLO",
  "MONCUCCO TORINESE",
  "MONDAINO",
  "MONDAVIO",
  "MONDOLFO",
  "MONDOVÌ",
  "MONDRAGONE",
  "MONEGLIA",
  "MONESIGLIO",
  "MONFALCONE",
  "MONFORTE SAN GIORGIO",
  "MONFORTE D'ALBA",
  "MONFUMO",
  "MONGARDINO",
  "MONGHIDORO",
  "MONGIANA",
  "MONGIARDINO LIGURE",
  "MONGIUFFI MELIA",
  "MONGRANDO",
  "MONGRASSANO",
  "MONGUELFO-TESIDO",
  "MONGUZZO",
  "MONIGA DEL GARDA",
  "MONLEALE",
  "MONNO",
  "MONOPOLI",
  "MONREALE",
  "MONRUPINO",
  "MONSAMPIETRO MORICO",
  "MONSAMPOLO DEL TRONTO",
  "MONSANO",
  "MONSELICE",
  "MONSERRATO",
  "MONSUMMANO TERME",
  "MONTABONE",
  "MONTACUTO",
  "MONTAFIA",
  "MONTAGANO",
  "MONTAGNA",
  "MONTAGNA IN VALTELLINA",
  "MONTAGNANA",
  "MONTAGNAREALE",
  "MONTAGUTO",
  "MONTAIONE",
  "MONTALBANO ELICONA",
  "MONTALBANO JONICO",
  "MONTALCINO",
  "MONTALDEO",
  "MONTALDO BORMIDA",
  "MONTALDO ROERO",
  "MONTALDO SCARAMPI",
  "MONTALDO TORINESE",
  "MONTALDO DI MONDOVÌ",
  "MONTALE",
  "MONTALENGHE",
  "MONTALLEGRO",
  "MONTALTO CARPASIO",
  "MONTALTO DORA",
  "MONTALTO PAVESE",
  "MONTALTO UFFUGO",
  "MONTALTO DELLE MARCHE",
  "MONTALTO DI CASTRO",
  "MONTANARO",
  "MONTANASO LOMBARDO",
  "MONTANERA",
  "MONTANO ANTILIA",
  "MONTANO LUCINO",
  "MONTAPPONE",
  "MONTAQUILA",
  "MONTASOLA",
  "MONTAURO",
  "MONTAZZOLI",
  "MONTE ARGENTARIO",
  "MONTE CASTELLO DI VIBIO",
  "MONTE CAVALLO",
  "MONTE CERIGNONE",
  "MONTE COMPATRI",
  "MONTE CREMASCO",
  "MONTE GIBERTO",
  "MONTE GRIMANO TERME",
  "MONTE ISOLA",
  "MONTE MARENZO",
  "MONTE PORZIO",
  "MONTE PORZIO CATONE",
  "MONTE RINALDO",
  "MONTE ROBERTO",
  "MONTE ROMANO",
  "MONTE SAN BIAGIO",
  "MONTE SAN GIACOMO",
  "MONTE SAN GIOVANNI CAMPANO",
  "MONTE SAN GIOVANNI IN SABINA",
  "MONTE SAN GIUSTO",
  "MONTE SAN MARTINO",
  "MONTE SAN PIETRANGELI",
  "MONTE SAN PIETRO",
  "MONTE SAN SAVINO",
  "MONTE SAN VITO",
  "MONTE SANT'ANGELO",
  "MONTE SANTA MARIA TIBERINA",
  "MONTE URANO",
  "MONTE VIDON COMBATTE",
  "MONTE VIDON CORRADO",
  "MONTE DI MALO",
  "MONTE DI PROCIDA",
  "MONTEBELLO JONICO",
  "MONTEBELLO VICENTINO",
  "MONTEBELLO DELLA BATTAGLIA",
  "MONTEBELLO DI BERTONA",
  "MONTEBELLO SUL SANGRO",
  "MONTEBELLUNA",
  "MONTEBRUNO",
  "MONTEBUONO",
  "MONTECALVO IRPINO",
  "MONTECALVO VERSIGGIA",
  "MONTECALVO IN FOGLIA",
  "MONTECARLO",
  "MONTECAROTTO",
  "MONTECASSIANO",
  "MONTECASTELLO",
  "MONTECASTRILLI",
  "MONTECATINI VAL DI CECINA",
  "MONTECATINI-TERME",
  "MONTECCHIA DI CROSARA",
  "MONTECCHIO",
  "MONTECCHIO EMILIA",
  "MONTECCHIO MAGGIORE",
  "MONTECCHIO PRECALCINO",
  "MONTECHIARO D'ACQUI",
  "MONTECHIARO D'ASTI",
  "MONTECHIARUGOLO",
  "MONTECILFONE",
  "MONTECOPIOLO",
  "MONTECORICE",
  "MONTECORVINO PUGLIANO",
  "MONTECORVINO ROVELLA",
  "MONTECOSARO",
  "MONTECRESTESE",
  "MONTECRETO",
  "MONTEDINOVE",
  "MONTEDORO",
  "MONTEFALCIONE",
  "MONTEFALCO",
  "MONTEFALCONE APPENNINO",
  "MONTEFALCONE DI VAL FORTORE",
  "MONTEFALCONE NEL SANNIO",
  "MONTEFANO",
  "MONTEFELCINO",
  "MONTEFERRANTE",
  "MONTEFIASCONE",
  "MONTEFINO",
  "MONTEFIORE CONCA",
  "MONTEFIORE DELL'ASO",
  "MONTEFIORINO",
  "MONTEFLAVIO",
  "MONTEFORTE CILENTO",
  "MONTEFORTE IRPINO",
  "MONTEFORTE D'ALPONE",
  "MONTEFORTINO",
  "MONTEFRANCO",
  "MONTEFREDANE",
  "MONTEFUSCO",
  "MONTEGABBIONE",
  "MONTEGALDA",
  "MONTEGALDELLA",
  "MONTEGALLO",
  "MONTEGIOCO",
  "MONTEGIORDANO",
  "MONTEGIORGIO",
  "MONTEGRANARO",
  "MONTEGRIDOLFO",
  "MONTEGRINO VALTRAVAGLIA",
  "MONTEGROSSO PIAN LATTE",
  "MONTEGROSSO D'ASTI",
  "MONTEGROTTO TERME",
  "MONTEIASI",
  "MONTELABBATE",
  "MONTELANICO",
  "MONTELAPIANO",
  "MONTELEONE ROCCA DORIA",
  "MONTELEONE SABINO",
  "MONTELEONE D'ORVIETO",
  "MONTELEONE DI FERMO",
  "MONTELEONE DI PUGLIA",
  "MONTELEONE DI SPOLETO",
  "MONTELEPRE",
  "MONTELIBRETTI",
  "MONTELLA",
  "MONTELLO",
  "MONTELONGO",
  "MONTELPARO",
  "MONTELUPO ALBESE",
  "MONTELUPO FIORENTINO",
  "MONTELUPONE",
  "MONTEMAGGIORE BELSITO",
  "MONTEMAGNO",
  "MONTEMALE DI CUNEO",
  "MONTEMARANO",
  "MONTEMARCIANO",
  "MONTEMARZINO",
  "MONTEMESOLA",
  "MONTEMEZZO",
  "MONTEMIGNAIO",
  "MONTEMILETTO",
  "MONTEMILONE",
  "MONTEMITRO",
  "MONTEMONACO",
  "MONTEMURLO",
  "MONTEMURRO",
  "MONTENARS",
  "MONTENERO SABINO",
  "MONTENERO VAL COCCHIARA",
  "MONTENERO DI BISACCIA",
  "MONTENERODOMO",
  "MONTEODORISIO",
  "MONTEPAONE",
  "MONTEPARANO",
  "MONTEPRANDONE",
  "MONTEPULCIANO",
  "MONTERCHI",
  "MONTEREALE",
  "MONTEREALE VALCELLINA",
  "MONTERENZIO",
  "MONTERIGGIONI",
  "MONTERODUNI",
  "MONTERONI D'ARBIA",
  "MONTERONI DI LECCE",
  "MONTEROSI",
  "MONTEROSSO ALMO",
  "MONTEROSSO CALABRO",
  "MONTEROSSO GRANA",
  "MONTEROSSO AL MARE",
  "MONTEROTONDO",
  "MONTEROTONDO MARITTIMO",
  "MONTERUBBIANO",
  "MONTESANO SALENTINO",
  "MONTESANO SULLA MARCELLANA",
  "MONTESARCHIO",
  "MONTESCAGLIOSO",
  "MONTESCANO",
  "MONTESCHENO",
  "MONTESCUDAIO",
  "MONTESCUDO-MONTE COLOMBO",
  "MONTESE",
  "MONTESEGALE",
  "MONTESILVANO",
  "MONTESPERTOLI",
  "MONTEU ROERO",
  "MONTEU DA PO",
  "MONTEVAGO",
  "MONTEVARCHI",
  "MONTEVECCHIA",
  "MONTEVERDE",
  "MONTEVERDI MARITTIMO",
  "MONTEVIALE",
  "MONTEZEMOLO",
  "MONTI",
  "MONTIANO",
  "MONTICELLI BRUSATI",
  "MONTICELLI PAVESE",
  "MONTICELLI D'ONGINA",
  "MONTICELLO BRIANZA",
  "MONTICELLO CONTE OTTO",
  "MONTICELLO D'ALBA",
  "MONTICHIARI",
  "MONTICIANO",
  "MONTIERI",
  "MONTIGLIO MONFERRATO",
  "MONTIGNOSO",
  "MONTIRONE",
  "MONTJOVET",
  "MONTODINE",
  "MONTOGGIO",
  "MONTONE",
  "MONTOPOLI DI SABINA",
  "MONTOPOLI IN VAL D'ARNO",
  "MONTORFANO",
  "MONTORIO ROMANO",
  "MONTORIO AL VOMANO",
  "MONTORIO NEI FRENTANI",
  "MONTORO",
  "MONTORSO VICENTINO",
  "MONTOTTONE",
  "MONTRESTA",
  "MONTÀ",
  "MONTÙ BECCARIA",
  "MONVALLE",
  "MONZA",
  "MONZAMBANO",
  "MONZUNO",
  "MORANO CALABRO",
  "MORANO SUL PO",
  "MORANSENGO-TONENGO",
  "MORARO",
  "MORAZZONE",
  "MORBEGNO",
  "MORBELLO",
  "MORCIANO DI LEUCA",
  "MORCIANO DI ROMAGNA",
  "MORCONE",
  "MORDANO",
  "MORENGO",
  "MORES",
  "MORESCO",
  "MORETTA",
  "MORFASSO",
  "MORGANO",
  "MORGEX",
  "MORGONGIORI",
  "MORI",
  "MORIAGO DELLA BATTAGLIA",
  "MORICONE",
  "MORIGERATI",
  "MORIMONDO",
  "MORINO",
  "MORIONDO TORINESE",
  "MORLUPO",
  "MORMANNO",
  "MORNAGO",
  "MORNESE",
  "MORNICO LOSANA",
  "MORNICO AL SERIO",
  "MOROLO",
  "MOROZZO",
  "MORRA DE SANCTIS",
  "MORRO REATINO",
  "MORRO D'ALBA",
  "MORRO D'ORO",
  "MORRONE DEL SANNIO",
  "MORROVALLE",
  "MORSANO AL TAGLIAMENTO",
  "MORSASCO",
  "MORTARA",
  "MORTEGLIANO",
  "MORTERONE",
  "MORUZZO",
  "MOSCAZZANO",
  "MOSCHIANO",
  "MOSCIANO SANT'ANGELO",
  "MOSCUFO",
  "MOSO IN PASSIRIA",
  "MOSSA",
  "MOTTA BALUFFI",
  "MOTTA CAMASTRA",
  "MOTTA MONTECORVINO",
  "MOTTA SAN GIOVANNI",
  "MOTTA SANT'ANASTASIA",
  "MOTTA SANTA LUCIA",
  "MOTTA VISCONTI",
  "MOTTA D'AFFERMO",
  "MOTTA DE' CONTI",
  "MOTTA DI LIVENZA",
  "MOTTAFOLLONE",
  "MOTTALCIATA",
  "MOTTEGGIANA",
  "MOTTOLA",
  "MOZZAGROGNA",
  "MOZZANICA",
  "MOZZATE",
  "MOZZECANE",
  "MOZZO",
  "MUCCIA",
  "MUGGIA",
  "MUGGIÒ",
  "MUGNANO DEL CARDINALE",
  "MUGNANO DI NAPOLI",
  "MULAZZANO",
  "MULAZZO",
  "MURA",
  "MURAVERA",
  "MURAZZANO",
  "MURELLO",
  "MURIALDO",
  "MURISENGO",
  "MURLO",
  "MURO LECCESE",
  "MURO LUCANO",
  "MUROS",
  "MUSCOLINE",
  "MUSEI",
  "MUSILE DI PIAVE",
  "MUSSO",
  "MUSSOLENTE",
  "MUSSOMELI",
  "MUZZANA DEL TURGNANO",
  "MUZZANO",
  "NAGO-TORBOLE",
  "NALLES",
  "NANTO",
  "NAPOLI",
  "NARBOLIA",
  "NARCAO",
  "NARDODIPACE",
  "NARDÒ",
  "NARNI",
  "NARO",
  "NARZOLE",
  "NASINO",
  "NASO",
  "NATURNO",
  "NAVE",
  "NAVELLI",
  "NAZ-SCIAVES",
  "NAZZANO",
  "NE",
  "NEBBIUNO",
  "NEGRAR DI VALPOLICELLA",
  "NEIRONE",
  "NEIVE",
  "NEMBRO",
  "NEMI",
  "NEMOLI",
  "NEONELI",
  "NEPI",
  "NERETO",
  "NEROLA",
  "NERVESA DELLA BATTAGLIA",
  "NERVIANO",
  "NESPOLO",
  "NESSO",
  "NETRO",
  "NETTUNO",
  "NEVIANO",
  "NEVIANO DEGLI ARDUINI",
  "NEVIGLIE",
  "NIARDO",
  "NIBBIOLA",
  "NIBIONNO",
  "NICHELINO",
  "NICOLOSI",
  "NICORVO",
  "NICOSIA",
  "NICOTERA",
  "NIELLA BELBO",
  "NIELLA TANARO",
  "NIMIS",
  "NISCEMI",
  "NISSORIA",
  "NIZZA MONFERRATO",
  "NIZZA DI SICILIA",
  "NOALE",
  "NOASCA",
  "NOCARA",
  "NOCCIANO",
  "NOCERA INFERIORE",
  "NOCERA SUPERIORE",
  "NOCERA TERINESE",
  "NOCERA UMBRA",
  "NOCETO",
  "NOCI",
  "NOCIGLIA",
  "NOEPOLI",
  "NOGARA",
  "NOGAREDO",
  "NOGAROLE ROCCA",
  "NOGAROLE VICENTINO",
  "NOICATTARO",
  "NOLA",
  "NOLE",
  "NOLI",
  "NOMAGLIO",
  "NOMI",
  "NONANTOLA",
  "NONE",
  "NONIO",
  "NORAGUGUME",
  "NORBELLO",
  "NORCIA",
  "NORMA",
  "NOSATE",
  "NOTARESCO",
  "NOTO",
  "NOVA LEVANTE",
  "NOVA MILANESE",
  "NOVA PONENTE",
  "NOVA SIRI",
  "NOVAFELTRIA",
  "NOVALEDO",
  "NOVALESA",
  "NOVARA",
  "NOVARA DI SICILIA",
  "NOVATE MEZZOLA",
  "NOVATE MILANESE",
  "NOVE",
  "NOVEDRATE",
  "NOVELLA",
  "NOVELLARA",
  "NOVELLO",
  "NOVENTA PADOVANA",
  "NOVENTA VICENTINA",
  "NOVENTA DI PIAVE",
  "NOVI LIGURE",
  "NOVI VELIA",
  "NOVI DI MODENA",
  "NOVIGLIO",
  "NOVOLI",
  "NUCETTO",
  "NUGHEDU SAN NICOLÒ",
  "NUGHEDU SANTA VITTORIA",
  "NULE",
  "NULVI",
  "NUMANA",
  "NUORO",
  "NURACHI",
  "NURAGUS",
  "NURALLAO",
  "NURAMINIS",
  "NURECI",
  "NURRI",
  "NUS",
  "NUSCO",
  "NUVOLENTO",
  "NUVOLERA",
  "NUXIS",
  "OCCHIEPPO INFERIORE",
  "OCCHIEPPO SUPERIORE",
  "OCCHIOBELLO",
  "OCCIMIANO",
  "OCRE",
  "ODALENGO GRANDE",
  "ODALENGO PICCOLO",
  "ODERZO",
  "ODOLO",
  "OFENA",
  "OFFAGNA",
  "OFFANENGO",
  "OFFIDA",
  "OFFLAGA",
  "OGGEBBIO",
  "OGGIONA CON SANTO STEFANO",
  "OGGIONO",
  "OGLIANICO",
  "OGLIASTRO CILENTO",
  "OLBIA",
  "OLCENENGO",
  "OLDENICO",
  "OLEGGIO",
  "OLEGGIO CASTELLO",
  "OLEVANO ROMANO",
  "OLEVANO DI LOMELLINA",
  "OLEVANO SUL TUSCIANO",
  "OLGIATE COMASCO",
  "OLGIATE MOLGORA",
  "OLGIATE OLONA",
  "OLGINATE",
  "OLIENA",
  "OLIVA GESSI",
  "OLIVADI",
  "OLIVERI",
  "OLIVETO CITRA",
  "OLIVETO LARIO",
  "OLIVETO LUCANO",
  "OLIVETTA SAN MICHELE",
  "OLIVOLA",
  "OLLASTRA",
  "OLLOLAI",
  "OLLOMONT",
  "OLMEDO",
  "OLMENETA",
  "OLMO GENTILE",
  "OLMO AL BREMBO",
  "OLTRE IL COLLE",
  "OLTRESSENDA ALTA",
  "OLTRONA DI SAN MAMETTE",
  "OLZAI",
  "OME",
  "OMEGNA",
  "OMIGNANO",
  "ONANO",
  "ONANÌ",
  "ONCINO",
  "ONETA",
  "ONIFAI",
  "ONIFERI",
  "ONO SAN PIETRO",
  "ONORE",
  "ONZO",
  "OPERA",
  "OPI",
  "OPPEANO",
  "OPPIDO LUCANO",
  "OPPIDO MAMERTINA",
  "ORA",
  "ORANI",
  "ORATINO",
  "ORBASSANO",
  "ORBETELLO",
  "ORCIANO PISANO",
  "ORCO FEGLINO",
  "ORDONA",
  "ORERO",
  "ORGIANO",
  "ORGOSOLO",
  "ORIA",
  "ORICOLA",
  "ORIGGIO",
  "ORINO",
  "ORIO CANAVESE",
  "ORIO LITTA",
  "ORIO AL SERIO",
  "ORIOLO",
  "ORIOLO ROMANO",
  "ORISTANO",
  "ORMEA",
  "ORMELLE",
  "ORNAGO",
  "ORNAVASSO",
  "ORNICA",
  "OROSEI",
  "OROTELLI",
  "ORRIA",
  "ORROLI",
  "ORSAGO",
  "ORSARA BORMIDA",
  "ORSARA DI PUGLIA",
  "ORSENIGO",
  "ORSOGNA",
  "ORSOMARSO",
  "ORTA NOVA",
  "ORTA SAN GIULIO",
  "ORTA DI ATELLA",
  "ORTACESUS",
  "ORTE",
  "ORTELLE",
  "ORTEZZANO",
  "ORTIGNANO RAGGIOLO",
  "ORTISEI",
  "ORTONA",
  "ORTONA DEI MARSI",
  "ORTOVERO",
  "ORTUCCHIO",
  "ORTUERI",
  "ORUNE",
  "ORVIETO",
  "ORVINIO",
  "ORZINUOVI",
  "ORZIVECCHI",
  "OSASCO",
  "OSASIO",
  "OSCHIRI",
  "OSIDDA",
  "OSIGLIA",
  "OSILO",
  "OSIMO",
  "OSINI",
  "OSIO SOPRA",
  "OSIO SOTTO",
  "OSNAGO",
  "OSOPPO",
  "OSPEDALETTI",
  "OSPEDALETTO",
  "OSPEDALETTO EUGANEO",
  "OSPEDALETTO LODIGIANO",
  "OSPEDALETTO D'ALPINOLO",
  "OSPITALE DI CADORE",
  "OSPITALETTO",
  "OSSAGO LODIGIANO",
  "OSSANA",
  "OSSI",
  "OSSIMO",
  "OSSONA",
  "OSTANA",
  "OSTELLATO",
  "OSTIANO",
  "OSTIGLIA",
  "OSTRA",
  "OSTRA VETERE",
  "OSTUNI",
  "OTRANTO",
  "OTRICOLI",
  "OTTANA",
  "OTTATI",
  "OTTAVIANO",
  "OTTIGLIO",
  "OTTOBIANO",
  "OTTONE",
  "OULX",
  "OVADA",
  "OVARO",
  "OVIGLIO",
  "OVINDOLI",
  "OVODDA",
  "OYACE",
  "OZEGNA",
  "OZIERI",
  "OZZANO MONFERRATO",
  "OZZANO DELL'EMILIA",
  "OZZERO",
  "PABILLONIS",
  "PACE DEL MELA",
  "PACECO",
  "PACENTRO",
  "PACHINO",
  "PACIANO",
  "PADENGHE SUL GARDA",
  "PADERNA",
  "PADERNO DUGNANO",
  "PADERNO FRANCIACORTA",
  "PADERNO PONCHIELLI",
  "PADERNO D'ADDA",
  "PADOVA",
  "PADRIA",
  "PADRU",
  "PADULA",
  "PADULI",
  "PAESANA",
  "PAESE",
  "PAGANI",
  "PAGANICO SABINO",
  "PAGAZZANO",
  "PAGLIARA",
  "PAGLIETA",
  "PAGNACCO",
  "PAGNO",
  "PAGNONA",
  "PAGO VEIANO",
  "PAGO DEL VALLO DI LAURO",
  "PAISCO LOVENO",
  "PAITONE",
  "PALADINA",
  "PALAGANO",
  "PALAGIANELLO",
  "PALAGIANO",
  "PALAGONIA",
  "PALAIA",
  "PALANZANO",
  "PALATA",
  "PALAU",
  "PALAZZAGO",
  "PALAZZO ADRIANO",
  "PALAZZO CANAVESE",
  "PALAZZO PIGNANO",
  "PALAZZO SAN GERVASIO",
  "PALAZZOLO ACREIDE",
  "PALAZZOLO VERCELLESE",
  "PALAZZOLO DELLO STELLA",
  "PALAZZOLO SULL'OGLIO",
  "PALAZZUOLO SUL SENIO",
  "PALENA",
  "PALERMITI",
  "PALERMO",
  "PALESTRINA",
  "PALESTRO",
  "PALIANO",
  "PALIZZI",
  "PALLAGORIO",
  "PALLANZENO",
  "PALLARE",
  "PALMA CAMPANIA",
  "PALMA DI MONTECHIARO",
  "PALMANOVA",
  "PALMARIGGI",
  "PALMAS ARBOREA",
  "PALMI",
  "PALMIANO",
  "PALMOLI",
  "PALO DEL COLLE",
  "PALOMBARA SABINA",
  "PALOMBARO",
  "PALOMONTE",
  "PALOSCO",
  "PALUDI",
  "PALUZZA",
  "PALÙ",
  "PALÙ DEL FERSINA",
  "PAMPARATO",
  "PANCALIERI",
  "PANCARANA",
  "PANCHIÀ",
  "PANDINO",
  "PANETTIERI",
  "PANICALE",
  "PANNARANO",
  "PANNI",
  "PANTELLERIA",
  "PANTIGLIATE",
  "PAOLA",
  "PAOLISI",
  "PAPASIDERO",
  "PAPOZZE",
  "PARABIAGO",
  "PARABITA",
  "PARATICO",
  "PARCINES",
  "PARELLA",
  "PARENTI",
  "PARETE",
  "PARETO",
  "PARGHELIA",
  "PARLASCO",
  "PARMA",
  "PARODI LIGURE",
  "PAROLDO",
  "PAROLISE",
  "PARONA",
  "PARRANO",
  "PARRE",
  "PARTANNA",
  "PARTINICO",
  "PARUZZARO",
  "PARZANICA",
  "PASIAN DI PRATO",
  "PASIANO DI PORDENONE",
  "PASPARDO",
  "PASSERANO MARMORITO",
  "PASSIGNANO SUL TRASIMENO",
  "PASSIRANO",
  "PASTENA",
  "PASTORANO",
  "PASTRENGO",
  "PASTURANA",
  "PASTURO",
  "PATERNO",
  "PATERNO CALABRO",
  "PATERNOPOLI",
  "PATERNÒ",
  "PATRICA",
  "PATTADA",
  "PATTI",
  "PATÙ",
  "PAU",
  "PAULARO",
  "PAULI ARBAREI",
  "PAULILATINO",
  "PAULLO",
  "PAUPISI",
  "PAVAROLO",
  "PAVIA",
  "PAVIA DI UDINE",
  "PAVONE CANAVESE",
  "PAVONE DEL MELLA",
  "PAVULLO NEL FRIGNANO",
  "PAZZANO",
  "PECCIOLI",
  "PECETTO TORINESE",
  "PECETTO DI VALENZA",
  "PEDARA",
  "PEDASO",
  "PEDAVENA",
  "PEDEMONTE",
  "PEDEROBBA",
  "PEDESINA",
  "PEDIVIGLIANO",
  "PEDRENGO",
  "PEGLIO",
  "PEGOGNAGA",
  "PEIA",
  "PEIO",
  "PELAGO",
  "PELLA",
  "PELLEGRINO PARMENSE",
  "PELLEZZANO",
  "PELLIZZANO",
  "PELUGO",
  "PENANGO",
  "PENNA SAN GIOVANNI",
  "PENNA SANT'ANDREA",
  "PENNA IN TEVERINA",
  "PENNABILLI",
  "PENNADOMO",
  "PENNAPIEDIMONTE",
  "PENNE",
  "PENTONE",
  "PERANO",
  "PERAROLO DI CADORE",
  "PERCA",
  "PERCILE",
  "PERDASDEFOGU",
  "PERDAXIUS",
  "PERDIFUMO",
  "PERETO",
  "PERFUGAS",
  "PERGINE VALSUGANA",
  "PERGOLA",
  "PERINALDO",
  "PERITO",
  "PERLEDO",
  "PERLETTO",
  "PERLO",
  "PERLOZ",
  "PERNUMIA",
  "PERO",
  "PEROSA ARGENTINA",
  "PEROSA CANAVESE",
  "PERRERO",
  "PERSICO DOSIMO",
  "PERTENGO",
  "PERTICA ALTA",
  "PERTICA BASSA",
  "PERTOSA",
  "PERTUSIO",
  "PERUGIA",
  "PESARO",
  "PESCAGLIA",
  "PESCANTINA",
  "PESCARA",
  "PESCAROLO ED UNITI",
  "PESCASSEROLI",
  "PESCATE",
  "PESCHE",
  "PESCHICI",
  "PESCHIERA BORROMEO",
  "PESCHIERA DEL GARDA",
  "PESCIA",
  "PESCINA",
  "PESCO SANNITA",
  "PESCOCOSTANZO",
  "PESCOLANCIANO",
  "PESCOPAGANO",
  "PESCOPENNATARO",
  "PESCOROCCHIANO",
  "PESCOSANSONESCO",
  "PESCOSOLIDO",
  "PESSANO CON BORNAGO",
  "PESSINA CREMONESE",
  "PESSINETTO",
  "PETACCIATO",
  "PETILIA POLICASTRO",
  "PETINA",
  "PETRALIA SOPRANA",
  "PETRALIA SOTTANA",
  "PETRELLA SALTO",
  "PETRELLA TIFERNINA",
  "PETRIANO",
  "PETRIOLO",
  "PETRITOLI",
  "PETRIZZI",
  "PETRONÀ",
  "PETROSINO",
  "PETRURO IRPINO",
  "PETTENASCO",
  "PETTINENGO",
  "PETTINEO",
  "PETTORANELLO DEL MOLISE",
  "PETTORANO SUL GIZIO",
  "PETTORAZZA GRIMANI",
  "PEVERAGNO",
  "PEZZANA",
  "PEZZAZE",
  "PEZZOLO VALLE UZZONE",
  "PIACENZA",
  "PIACENZA D'ADIGE",
  "PIADENA DRIZZONA",
  "PIAGGINE",
  "PIAN CAMUNO",
  "PIANA CRIXIA",
  "PIANA DEGLI ALBANESI",
  "PIANA DI MONTE VERNA",
  "PIANCASTAGNAIO",
  "PIANCOGNO",
  "PIANDIMELETO",
  "PIANE CRATI",
  "PIANELLA",
  "PIANELLO VAL TIDONE",
  "PIANELLO DEL LARIO",
  "PIANENGO",
  "PIANEZZA",
  "PIANEZZE",
  "PIANFEI",
  "PIANICO",
  "PIANIGA",
  "PIANO DI SORRENTO",
  "PIANOPOLI",
  "PIANORO",
  "PIANSANO",
  "PIANTEDO",
  "PIARIO",
  "PIASCO",
  "PIATEDA",
  "PIATTO",
  "PIAZZA ARMERINA",
  "PIAZZA BREMBANA",
  "PIAZZA AL SERCHIO",
  "PIAZZATORRE",
  "PIAZZOLA SUL BRENTA",
  "PIAZZOLO",
  "PICCIANO",
  "PICERNO",
  "PICINISCO",
  "PICO",
  "PIEA",
  "PIEDICAVALLO",
  "PIEDIMONTE ETNEO",
  "PIEDIMONTE MATESE",
  "PIEDIMONTE SAN GERMANO",
  "PIEDIMULERA",
  "PIEGARO",
  "PIENZA",
  "PIERANICA",
  "PIETRA LIGURE",
  "PIETRA MARAZZI",
  "PIETRA DE' GIORGI",
  "PIETRABBONDANTE",
  "PIETRABRUNA",
  "PIETRACAMELA",
  "PIETRACATELLA",
  "PIETRACUPA",
  "PIETRADEFUSI",
  "PIETRAFERRAZZANA",
  "PIETRAFITTA",
  "PIETRAGALLA",
  "PIETRALUNGA",
  "PIETRAMELARA",
  "PIETRAMONTECORVINO",
  "PIETRANICO",
  "PIETRAPAOLA",
  "PIETRAPERTOSA",
  "PIETRAPERZIA",
  "PIETRAPORZIO",
  "PIETRAROJA",
  "PIETRARUBBIA",
  "PIETRASANTA",
  "PIETRASTORNINA",
  "PIETRAVAIRANO",
  "PIETRELCINA",
  "PIEVE ALBIGNOLA",
  "PIEVE EMANUELE",
  "PIEVE FISSIRAGA",
  "PIEVE FOSCIANA",
  "PIEVE LIGURE",
  "PIEVE PORTO MORONE",
  "PIEVE SAN GIACOMO",
  "PIEVE SANTO STEFANO",
  "PIEVE TESINO",
  "PIEVE TORINA",
  "PIEVE VERGONTE",
  "PIEVE A NIEVOLE",
  "PIEVE D'OLMI",
  "PIEVE DEL CAIRO",
  "PIEVE DEL GRAPPA",
  "PIEVE DI BONO-PREZZO",
  "PIEVE DI CADORE",
  "PIEVE DI CENTO",
  "PIEVE DI SOLIGO",
  "PIEVE DI TECO",
  "PIEVEPELAGO",
  "PIGLIO",
  "PIGNA",
  "PIGNATARO INTERAMNA",
  "PIGNATARO MAGGIORE",
  "PIGNOLA",
  "PIGNONE",
  "PIGRA",
  "PILA",
  "PIMENTEL",
  "PIMONTE",
  "PINAROLO PO",
  "PINASCA",
  "PINCARA",
  "PINEROLO",
  "PINETO",
  "PINO TORINESE",
  "PINO D'ASTI",
  "PINZANO AL TAGLIAMENTO",
  "PINZOLO",
  "PIOBBICO",
  "PIOBESI TORINESE",
  "PIOBESI D'ALBA",
  "PIODE",
  "PIOLTELLO",
  "PIOMBINO",
  "PIOMBINO DESE",
  "PIORACO",
  "PIOSSASCO",
  "PIOVE DI SACCO",
  "PIOVENE ROCCHETTE",
  "PIOVÀ MASSAIA",
  "PIOZZANO",
  "PIOZZO",
  "PIRAINO",
  "PISA",
  "PISANO",
  "PISCINA",
  "PISCINAS",
  "PISCIOTTA",
  "PISOGNE",
  "PISONIANO",
  "PISTICCI",
  "PISTOIA",
  "PITIGLIANO",
  "PIUBEGA",
  "PIURO",
  "PIVERONE",
  "PIZZALE",
  "PIZZIGHETTONE",
  "PIZZO",
  "PIZZOFERRATO",
  "PIZZOLI",
  "PIZZONE",
  "PIZZONI",
  "PLACANICA",
  "PLATACI",
  "PLATANIA",
  "PLATÌ",
  "PLAUS",
  "PLESIO",
  "PLOAGHE",
  "PLODIO",
  "POCAPAGLIA",
  "POCENIA",
  "PODENZANA",
  "PODENZANO",
  "POFI",
  "POGGIARDO",
  "POGGIBONSI",
  "POGGIO BUSTONE",
  "POGGIO CATINO",
  "POGGIO IMPERIALE",
  "POGGIO MIRTETO",
  "POGGIO MOIANO",
  "POGGIO NATIVO",
  "POGGIO PICENZE",
  "POGGIO RENATICO",
  "POGGIO RUSCO",
  "POGGIO SAN LORENZO",
  "POGGIO SAN MARCELLO",
  "POGGIO SAN VICINO",
  "POGGIO SANNITA",
  "POGGIO TORRIANA",
  "POGGIO A CAIANO",
  "POGGIODOMO",
  "POGGIOFIORITO",
  "POGGIOMARINO",
  "POGGIOREALE",
  "POGGIORSINI",
  "POGGIRIDENTI",
  "POGLIANO MILANESE",
  "POGNANA LARIO",
  "POGNANO",
  "POGNO",
  "POIRINO",
  "POJANA MAGGIORE",
  "POLAVENO",
  "POLCENIGO",
  "POLESELLA",
  "POLESINE ZIBELLO",
  "POLI",
  "POLIA",
  "POLICORO",
  "POLIGNANO A MARE",
  "POLINAGO",
  "POLINO",
  "POLISTENA",
  "POLIZZI GENEROSA",
  "POLLA",
  "POLLEIN",
  "POLLENA TROCCHIA",
  "POLLENZA",
  "POLLICA",
  "POLLINA",
  "POLLONE",
  "POLLUTRI",
  "POLONGHERA",
  "POLPENAZZE DEL GARDA",
  "POLVERARA",
  "POLVERIGI",
  "POMARANCE",
  "POMARETTO",
  "POMARICO",
  "POMARO MONFERRATO",
  "POMAROLO",
  "POMBIA",
  "POMEZIA",
  "POMIGLIANO D'ARCO",
  "POMPEI",
  "POMPEIANA",
  "POMPIANO",
  "POMPONESCO",
  "POMPU",
  "PONCARALE",
  "PONDERANO",
  "PONNA",
  "PONSACCO",
  "PONSO",
  "PONT CANAVESE",
  "PONT-SAINT-MARTIN",
  "PONTASSIEVE",
  "PONTBOSET",
  "PONTE",
  "PONTE BUGGIANESE",
  "PONTE GARDENA",
  "PONTE LAMBRO",
  "PONTE NIZZA",
  "PONTE NOSSA",
  "PONTE SAN NICOLÒ",
  "PONTE SAN PIETRO",
  "PONTE DELL'OLIO",
  "PONTE DI LEGNO",
  "PONTE DI PIAVE",
  "PONTE IN VALTELLINA",
  "PONTE NELLE ALPI",
  "PONTEBBA",
  "PONTECAGNANO FAIANO",
  "PONTECCHIO POLESINE",
  "PONTECHIANALE",
  "PONTECORVO",
  "PONTECURONE",
  "PONTEDASSIO",
  "PONTEDERA",
  "PONTELANDOLFO",
  "PONTELATONE",
  "PONTELONGO",
  "PONTENURE",
  "PONTERANICA",
  "PONTESTURA",
  "PONTEVICO",
  "PONTEY",
  "PONTI",
  "PONTI SUL MINCIO",
  "PONTIDA",
  "PONTINIA",
  "PONTINVREA",
  "PONTIROLO NUOVO",
  "PONTOGLIO",
  "PONTREMOLI",
  "PONZA",
  "PONZANO MONFERRATO",
  "PONZANO ROMANO",
  "PONZANO VENETO",
  "PONZANO DI FERMO",
  "PONZONE",
  "POPOLI",
  "POPPI",
  "PORANO",
  "PORCARI",
  "PORCIA",
  "PORDENONE",
  "PORLEZZA",
  "PORNASSIO",
  "PORPETTO",
  "PORTACOMARO",
  "PORTALBERA",
  "PORTE",
  "PORTE DI RENDENA",
  "PORTICI",
  "PORTICO DI CASERTA",
  "PORTICO E SAN BENEDETTO",
  "PORTIGLIOLA",
  "PORTO AZZURRO",
  "PORTO CERESIO",
  "PORTO CESAREO",
  "PORTO EMPEDOCLE",
  "PORTO MANTOVANO",
  "PORTO RECANATI",
  "PORTO SAN GIORGIO",
  "PORTO SANT'ELPIDIO",
  "PORTO TOLLE",
  "PORTO TORRES",
  "PORTO VALTRAVAGLIA",
  "PORTO VIRO",
  "PORTOBUFFOLÈ",
  "PORTOCANNONE",
  "PORTOFERRAIO",
  "PORTOFINO",
  "PORTOGRUARO",
  "PORTOMAGGIORE",
  "PORTOPALO DI CAPO PASSERO",
  "PORTOSCUSO",
  "PORTOVENERE",
  "PORTULA",
  "POSADA",
  "POSINA",
  "POSITANO",
  "POSSAGNO",
  "POSTA",
  "POSTA FIBRENO",
  "POSTAL",
  "POSTALESIO",
  "POSTIGLIONE",
  "POSTUA",
  "POTENZA",
  "POTENZA PICENA",
  "POVE DEL GRAPPA",
  "POVEGLIANO",
  "POVEGLIANO VERONESE",
  "POVIGLIO",
  "POVOLETTO",
  "POZZAGLIA SABINA",
  "POZZAGLIO ED UNITI",
  "POZZALLO",
  "POZZILLI",
  "POZZO D'ADDA",
  "POZZOL GROPPO",
  "POZZOLENGO",
  "POZZOLEONE",
  "POZZOLO FORMIGARO",
  "POZZOMAGGIORE",
  "POZZONOVO",
  "POZZUOLI",
  "POZZUOLO MARTESANA",
  "POZZUOLO DEL FRIULI",
  "PRADALUNGA",
  "PRADAMANO",
  "PRADLEVES",
  "PRAGELATO",
  "PRAIA A MARE",
  "PRAIANO",
  "PRALBOINO",
  "PRALI",
  "PRALORMO",
  "PRALUNGO",
  "PRAMAGGIORE",
  "PRAMOLLO",
  "PRAROLO",
  "PRAROSTINO",
  "PRASCO",
  "PRASCORSANO",
  "PRATA CAMPORTACCIO",
  "PRATA SANNITA",
  "PRATA D'ANSIDONIA",
  "PRATA DI PORDENONE",
  "PRATA DI PRINCIPATO ULTRA",
  "PRATELLA",
  "PRATIGLIONE",
  "PRATO",
  "PRATO CARNICO",
  "PRATO SESIA",
  "PRATO ALLO STELVIO",
  "PRATOLA PELIGNA",
  "PRATOLA SERRA",
  "PRATOVECCHIO STIA",
  "PRAVISDOMINI",
  "PRAY",
  "PRAZZO",
  "PRECENICCO",
  "PRECI",
  "PREDAIA",
  "PREDAPPIO",
  "PREDAZZO",
  "PREDOI",
  "PREDORE",
  "PREDOSA",
  "PREGANZIOL",
  "PREGNANA MILANESE",
  "PRELÀ",
  "PREMANA",
  "PREMARIACCO",
  "PREMENO",
  "PREMIA",
  "PREMILCUORE",
  "PREMOLO",
  "PREMOSELLO-CHIOVENDA",
  "PREONE",
  "PREPOTTO",
  "PRESEGLIE",
  "PRESENZANO",
  "PRESEZZO",
  "PRESICCE-ACQUARICA",
  "PRESSANA",
  "PRETORO",
  "PREVALLE",
  "PREZZA",
  "PRIERO",
  "PRIGNANO CILENTO",
  "PRIGNANO SULLA SECCHIA",
  "PRIMALUNA",
  "PRIMIERO SAN MARTINO DI CASTROZZA",
  "PRIOCCA",
  "PRIOLA",
  "PRIOLO GARGALLO",
  "PRIVERNO",
  "PRIZZI",
  "PROCENO",
  "PROCIDA",
  "PROPATA",
  "PROSERPIO",
  "PROSSEDI",
  "PROVAGLIO VAL SABBIA",
  "PROVAGLIO D'ISEO",
  "PROVES",
  "PROVVIDENTI",
  "PRUNETTO",
  "PRÉ-SAINT-DIDIER",
  "PUEGNAGO DEL GARDA",
  "PUGLIANELLO",
  "PULA",
  "PULFERO",
  "PULSANO",
  "PUMENENGO",
  "PUSIANO",
  "PUTIFIGARI",
  "PUTIGNANO",
  "QUADRELLE",
  "QUADRI",
  "QUAGLIUZZO",
  "QUALIANO",
  "QUARANTI",
  "QUAREGNA CERRETO",
  "QUARGNENTO",
  "QUARNA SOPRA",
  "QUARNA SOTTO",
  "QUARONA",
  "QUARRATA",
  "QUART",
  "QUARTO",
  "QUARTO D'ALTINO",
  "QUARTU SANT'ELENA",
  "QUARTUCCIU",
  "QUASSOLO",
  "QUATTORDIO",
  "QUATTRO CASTELLA",
  "QUERO VAS",
  "QUILIANO",
  "QUINCINETTO",
  "QUINDICI",
  "QUINGENTOLE",
  "QUINTANO",
  "QUINTO VERCELLESE",
  "QUINTO VICENTINO",
  "QUINTO DI TREVISO",
  "QUINZANO D'OGLIO",
  "QUISTELLO",
  "RABBI",
  "RACALE",
  "RACALMUTO",
  "RACCONIGI",
  "RACCUJA",
  "RACINES",
  "RADDA IN CHIANTI",
  "RADDUSA",
  "RADICOFANI",
  "RADICONDOLI",
  "RAFFADALI",
  "RAGALNA",
  "RAGOGNA",
  "RAGUSA",
  "RAIANO",
  "RAMACCA",
  "RANCIO VALCUVIA",
  "RANCO",
  "RANDAZZO",
  "RANICA",
  "RANZANICO",
  "RANZO",
  "RAPAGNANO",
  "RAPALLO",
  "RAPINO",
  "RAPOLANO TERME",
  "RAPOLLA",
  "RAPONE",
  "RASSA",
  "RASUN-ANTERSELVA",
  "RASURA",
  "RAVANUSA",
  "RAVARINO",
  "RAVASCLETTO",
  "RAVELLO",
  "RAVENNA",
  "RAVEO",
  "RAVISCANINA",
  "RE",
  "REA",
  "REALMONTE",
  "REANA DEL ROJALE",
  "REANO",
  "RECALE",
  "RECANATI",
  "RECCO",
  "RECETTO",
  "RECOARO TERME",
  "REDAVALLE",
  "REDONDESCO",
  "REFRANCORE",
  "REFRONTOLO",
  "REGALBUTO",
  "REGGELLO",
  "REGGIO DI CALABRIA",
  "REGGIO NELL'EMILIA",
  "REGGIOLO",
  "REINO",
  "REITANO",
  "REMANZACCO",
  "REMEDELLO",
  "RENATE",
  "RENDE",
  "RENON",
  "RESANA",
  "RESCALDINA",
  "RESIA",
  "RESIUTTA",
  "RESUTTANO",
  "RETORBIDO",
  "REVELLO",
  "REVIGLIASCO D'ASTI",
  "REVINE LAGO",
  "REZZAGO",
  "REZZATO",
  "REZZO",
  "REZZOAGLIO",
  "RHO",
  "RHÊMES-NOTRE-DAME",
  "RHÊMES-SAINT-GEORGES",
  "RIACE",
  "RIALTO",
  "RIANO",
  "RIARDO",
  "RIBERA",
  "RIBORDONE",
  "RICADI",
  "RICALDONE",
  "RICCIA",
  "RICCIONE",
  "RICCÒ DEL GOLFO DI SPEZIA",
  "RICENGO",
  "RICIGLIANO",
  "RIESE PIO X",
  "RIESI",
  "RIETI",
  "RIFIANO",
  "RIFREDDO",
  "RIGNANO FLAMINIO",
  "RIGNANO GARGANICO",
  "RIGNANO SULL'ARNO",
  "RIGOLATO",
  "RIMELLA",
  "RIMINI",
  "RIO",
  "RIO SALICETO",
  "RIO DI PUSTERIA",
  "RIOFREDDO",
  "RIOLA SARDO",
  "RIOLO TERME",
  "RIOLUNATO",
  "RIOMAGGIORE",
  "RIONERO SANNITICO",
  "RIONERO IN VULTURE",
  "RIPA TEATINA",
  "RIPABOTTONI",
  "RIPACANDIDA",
  "RIPALIMOSANI",
  "RIPALTA ARPINA",
  "RIPALTA CREMASCA",
  "RIPALTA GUERINA",
  "RIPARBELLA",
  "RIPATRANSONE",
  "RIPE SAN GINESIO",
  "RIPI",
  "RIPOSTO",
  "RITTANA",
  "RIVA LIGURE",
  "RIVA DEL GARDA",
  "RIVA DEL PO",
  "RIVA DI SOLTO",
  "RIVA PRESSO CHIERI",
  "RIVALBA",
  "RIVALTA BORMIDA",
  "RIVALTA DI TORINO",
  "RIVAMONTE AGORDINO",
  "RIVANAZZANO TERME",
  "RIVARA",
  "RIVAROLO CANAVESE",
  "RIVAROLO MANTOVANO",
  "RIVAROLO DEL RE ED UNITI",
  "RIVARONE",
  "RIVAROSSA",
  "RIVE",
  "RIVE D'ARCANO",
  "RIVELLO",
  "RIVERGARO",
  "RIVIGNANO TEOR",
  "RIVISONDOLI",
  "RIVODUTRI",
  "RIVOLI",
  "RIVOLI VERONESE",
  "RIVOLTA D'ADDA",
  "RIZZICONI",
  "ROANA",
  "ROASCHIA",
  "ROASCIO",
  "ROASIO",
  "ROATTO",
  "ROBASSOMERO",
  "ROBBIATE",
  "ROBBIO",
  "ROBECCHETTO CON INDUNO",
  "ROBECCO PAVESE",
  "ROBECCO D'OGLIO",
  "ROBECCO SUL NAVIGLIO",
  "ROBELLA",
  "ROBILANTE",
  "ROBURENT",
  "ROCCA CANAVESE",
  "ROCCA CANTERANO",
  "ROCCA CIGLIÈ",
  "ROCCA GRIMALDA",
  "ROCCA IMPERIALE",
  "ROCCA MASSIMA",
  "ROCCA PIA",
  "ROCCA PIETORE",
  "ROCCA PRIORA",
  "ROCCA SAN CASCIANO",
  "ROCCA SAN FELICE",
  "ROCCA SAN GIOVANNI",
  "ROCCA SANTA MARIA",
  "ROCCA SANTO STEFANO",
  "ROCCA SINIBALDA",
  "ROCCA SUSELLA",
  "ROCCA D'ARAZZO",
  "ROCCA D'ARCE",
  "ROCCA D'EVANDRO",
  "ROCCA DE' BALDI",
  "ROCCA DE' GIORGI",
  "ROCCA DI BOTTE",
  "ROCCA DI CAMBIO",
  "ROCCA DI CAVE",
  "ROCCA DI MEZZO",
  "ROCCA DI NETO",
  "ROCCA DI PAPA",
  "ROCCABASCERANA",
  "ROCCABERNARDA",
  "ROCCABIANCA",
  "ROCCABRUNA",
  "ROCCACASALE",
  "ROCCADASPIDE",
  "ROCCAFIORITA",
  "ROCCAFLUVIONE",
  "ROCCAFORTE LIGURE",
  "ROCCAFORTE MONDOVÌ",
  "ROCCAFORTE DEL GRECO",
  "ROCCAFORZATA",
  "ROCCAFRANCA",
  "ROCCAGIOVINE",
  "ROCCAGLORIOSA",
  "ROCCAGORGA",
  "ROCCALBEGNA",
  "ROCCALUMERA",
  "ROCCAMANDOLFI",
  "ROCCAMENA",
  "ROCCAMONFINA",
  "ROCCAMONTEPIANO",
  "ROCCAMORICE",
  "ROCCANOVA",
  "ROCCANTICA",
  "ROCCAPALUMBA",
  "ROCCAPIEMONTE",
  "ROCCARAINOLA",
  "ROCCARASO",
  "ROCCAROMANA",
  "ROCCASCALEGNA",
  "ROCCASECCA",
  "ROCCASECCA DEI VOLSCI",
  "ROCCASICURA",
  "ROCCASPARVERA",
  "ROCCASPINALVETI",
  "ROCCASTRADA",
  "ROCCAVALDINA",
  "ROCCAVERANO",
  "ROCCAVIGNALE",
  "ROCCAVIONE",
  "ROCCAVIVARA",
  "ROCCELLA IONICA",
  "ROCCELLA VALDEMONE",
  "ROCCHETTA BELBO",
  "ROCCHETTA LIGURE",
  "ROCCHETTA NERVINA",
  "ROCCHETTA PALAFEA",
  "ROCCHETTA SANT'ANTONIO",
  "ROCCHETTA TANARO",
  "ROCCHETTA A VOLTURNO",
  "ROCCHETTA DI VARA",
  "ROCCHETTA E CROCE",
  "RODANO",
  "RODDI",
  "RODDINO",
  "RODELLO",
  "RODENGO",
  "RODENGO SAIANO",
  "RODERO",
  "RODI GARGANICO",
  "RODIGO",
  "RODÌ MILICI",
  "ROFRANO",
  "ROGENO",
  "ROGGIANO GRAVINA",
  "ROGHUDI",
  "ROGLIANO",
  "ROGNANO",
  "ROGNO",
  "ROGOLO",
  "ROIATE",
  "ROIO DEL SANGRO",
  "ROISAN",
  "ROLETTO",
  "ROLO",
  "ROMA",
  "ROMAGNANO SESIA",
  "ROMAGNANO AL MONTE",
  "ROMAGNESE",
  "ROMANA",
  "ROMANENGO",
  "ROMANO CANAVESE",
  "ROMANO D'EZZELINO",
  "ROMANO DI LOMBARDIA",
  "ROMANS D'ISONZO",
  "ROMBIOLO",
  "ROMENO",
  "ROMENTINO",
  "ROMETTA",
  "RONAGO",
  "RONCADE",
  "RONCADELLE",
  "RONCARO",
  "RONCEGNO TERME",
  "RONCELLO",
  "RONCHI VALSUGANA",
  "RONCHI DEI LEGIONARI",
  "RONCHIS",
  "RONCIGLIONE",
  "RONCO BIELLESE",
  "RONCO BRIANTINO",
  "RONCO CANAVESE",
  "RONCO SCRIVIA",
  "RONCO ALL'ADIGE",
  "RONCOBELLO",
  "RONCOFERRARO",
  "RONCOFREDDO",
  "RONCOLA",
  "RONCÀ",
  "RONDANINA",
  "RONDISSONE",
  "RONSECCO",
  "RONZO-CHIENIS",
  "RONZONE",
  "ROPPOLO",
  "RORÀ",
  "ROSARNO",
  "ROSASCO",
  "ROSATE",
  "ROSAZZA",
  "ROSCIANO",
  "ROSCIGNO",
  "ROSE",
  "ROSELLO",
  "ROSETO CAPO SPULICO",
  "ROSETO VALFORTORE",
  "ROSETO DEGLI ABRUZZI",
  "ROSIGNANO MARITTIMO",
  "ROSIGNANO MONFERRATO",
  "ROSOLINA",
  "ROSOLINI",
  "ROSORA",
  "ROSSA",
  "ROSSANA",
  "ROSSANO VENETO",
  "ROSSIGLIONE",
  "ROSTA",
  "ROSÀ",
  "ROTA GRECA",
  "ROTA D'IMAGNA",
  "ROTELLA",
  "ROTELLO",
  "ROTONDA",
  "ROTONDELLA",
  "ROTONDI",
  "ROTTOFRENO",
  "ROTZO",
  "ROURE",
  "ROVASENDA",
  "ROVATO",
  "ROVEGNO",
  "ROVELLASCA",
  "ROVELLO PORRO",
  "ROVERBELLA",
  "ROVERCHIARA",
  "ROVEREDO DI GUÀ",
  "ROVEREDO IN PIANO",
  "ROVERETO",
  "ROVERÈ VERONESE",
  "ROVERÈ DELLA LUNA",
  "ROVESCALA",
  "ROVETTA",
  "ROVIANO",
  "ROVIGO",
  "ROVITO",
  "ROVOLON",
  "ROZZANO",
  "ROÈ VOLCIANO",
  "RUBANO",
  "RUBIANA",
  "RUBIERA",
  "RUDA",
  "RUDIANO",
  "RUEGLIO",
  "RUFFANO",
  "RUFFIA",
  "RUFFRÈ-MENDOLA",
  "RUFINA",
  "RUINAS",
  "RUMO",
  "RUOTI",
  "RUSSI",
  "RUTIGLIANO",
  "RUTINO",
  "RUVIANO",
  "RUVO DEL MONTE",
  "RUVO DI PUGLIA",
  "SABAUDIA",
  "SABBIO CHIESE",
  "SABBIONETA",
  "SACCO",
  "SACCOLONGO",
  "SACILE",
  "SACROFANO",
  "SADALI",
  "SAGAMA",
  "SAGLIANO MICCA",
  "SAGRADO",
  "SAGRON MIS",
  "SAINT-CHRISTOPHE",
  "SAINT-DENIS",
  "SAINT-MARCEL",
  "SAINT-NICOLAS",
  "SAINT-OYEN",
  "SAINT-PIERRE",
  "SAINT-RHÉMY-EN-BOSSES",
  "SAINT-VINCENT",
  "SALA BAGANZA",
  "SALA BIELLESE",
  "SALA BOLOGNESE",
  "SALA COMACINA",
  "SALA CONSILINA",
  "SALA MONFERRATO",
  "SALANDRA",
  "SALAPARUTA",
  "SALARA",
  "SALASCO",
  "SALASSA",
  "SALBERTRAND",
  "SALCEDO",
  "SALCITO",
  "SALE",
  "SALE MARASINO",
  "SALE SAN GIOVANNI",
  "SALE DELLE LANGHE",
  "SALEMI",
  "SALENTO",
  "SALERANO CANAVESE",
  "SALERANO SUL LAMBRO",
  "SALERNO",
  "SALGAREDA",
  "SALI VERCELLESE",
  "SALICE SALENTINO",
  "SALICETO",
  "SALISANO",
  "SALIZZOLE",
  "SALLE",
  "SALMOUR",
  "SALORNO SULLA STRADA DEL VINO",
  "SALSOMAGGIORE TERME",
  "SALTRIO",
  "SALUDECIO",
  "SALUGGIA",
  "SALUSSOLA",
  "SALUZZO",
  "SALVE",
  "SALVIROLA",
  "SALVITELLE",
  "SALZA IRPINA",
  "SALZA DI PINEROLO",
  "SALZANO",
  "SALÒ",
  "SAMARATE",
  "SAMASSI",
  "SAMATZAI",
  "SAMBUCA PISTOIESE",
  "SAMBUCA DI SICILIA",
  "SAMBUCI",
  "SAMBUCO",
  "SAMMICHELE DI BARI",
  "SAMO",
  "SAMOLACO",
  "SAMONE",
  "SAMPEYRE",
  "SAMUGHEO",
  "SAN BARTOLOMEO VAL CAVARGNA",
  "SAN BARTOLOMEO AL MARE",
  "SAN BARTOLOMEO IN GALDO",
  "SAN BASILE",
  "SAN BASILIO",
  "SAN BASSANO",
  "SAN BELLINO",
  "SAN BENEDETTO BELBO",
  "SAN BENEDETTO PO",
  "SAN BENEDETTO ULLANO",
  "SAN BENEDETTO VAL DI SAMBRO",
  "SAN BENEDETTO DEI MARSI",
  "SAN BENEDETTO DEL TRONTO",
  "SAN BENEDETTO IN PERILLIS",
  "SAN BENIGNO CANAVESE",
  "SAN BERNARDINO VERBANO",
  "SAN BIAGIO PLATANI",
  "SAN BIAGIO SARACINISCO",
  "SAN BIAGIO DELLA CIMA",
  "SAN BIAGIO DI CALLALTA",
  "SAN BIASE",
  "SAN BONIFACIO",
  "SAN BUONO",
  "SAN CALOGERO",
  "SAN CANDIDO",
  "SAN CANZIAN D'ISONZO",
  "SAN CARLO CANAVESE",
  "SAN CASCIANO DEI BAGNI",
  "SAN CASCIANO IN VAL DI PESA",
  "SAN CASSIANO",
  "SAN CATALDO",
  "SAN CESAREO",
  "SAN CESARIO DI LECCE",
  "SAN CESARIO SUL PANARO",
  "SAN CHIRICO NUOVO",
  "SAN CHIRICO RAPARO",
  "SAN CIPIRELLO",
  "SAN CIPRIANO PICENTINO",
  "SAN CIPRIANO PO",
  "SAN CIPRIANO D'AVERSA",
  "SAN CLEMENTE",
  "SAN COLOMBANO BELMONTE",
  "SAN COLOMBANO CERTENOLI",
  "SAN COLOMBANO AL LAMBRO",
  "SAN CONO",
  "SAN COSMO ALBANESE",
  "SAN COSTANTINO ALBANESE",
  "SAN COSTANTINO CALABRO",
  "SAN COSTANZO",
  "SAN CRISTOFORO",
  "SAN DAMIANO MACRA",
  "SAN DAMIANO AL COLLE",
  "SAN DAMIANO D'ASTI",
  "SAN DANIELE PO",
  "SAN DANIELE DEL FRIULI",
  "SAN DEMETRIO CORONE",
  "SAN DEMETRIO NE' VESTINI",
  "SAN DIDERO",
  "SAN DONACI",
  "SAN DONATO MILANESE",
  "SAN DONATO VAL DI COMINO",
  "SAN DONATO DI LECCE",
  "SAN DONATO DI NINEA",
  "SAN DONÀ DI PIAVE",
  "SAN DORLIGO DELLA VALLE",
  "SAN FELE",
  "SAN FELICE CIRCEO",
  "SAN FELICE A CANCELLO",
  "SAN FELICE DEL BENACO",
  "SAN FELICE DEL MOLISE",
  "SAN FELICE SUL PANARO",
  "SAN FERDINANDO",
  "SAN FERDINANDO DI PUGLIA",
  "SAN FERMO DELLA BATTAGLIA",
  "SAN FILI",
  "SAN FILIPPO DEL MELA",
  "SAN FIOR",
  "SAN FIORANO",
  "SAN FLORIANO DEL COLLIO",
  "SAN FLORO",
  "SAN FRANCESCO AL CAMPO",
  "SAN FRATELLO",
  "SAN GAVINO MONREALE",
  "SAN GEMINI",
  "SAN GENESIO ATESINO",
  "SAN GENESIO ED UNITI",
  "SAN GENNARO VESUVIANO",
  "SAN GERMANO CHISONE",
  "SAN GERMANO VERCELLESE",
  "SAN GERVASIO BRESCIANO",
  "SAN GIACOMO FILIPPO",
  "SAN GIACOMO VERCELLESE",
  "SAN GIACOMO DEGLI SCHIAVONI",
  "SAN GIACOMO DELLE SEGNATE",
  "SAN GILLIO",
  "SAN GIMIGNANO",
  "SAN GINESIO",
  "SAN GIORGIO ALBANESE",
  "SAN GIORGIO BIGARELLO",
  "SAN GIORGIO CANAVESE",
  "SAN GIORGIO IONICO",
  "SAN GIORGIO LA MOLARA",
  "SAN GIORGIO LUCANO",
  "SAN GIORGIO MONFERRATO",
  "SAN GIORGIO MORGETO",
  "SAN GIORGIO PIACENTINO",
  "SAN GIORGIO SCARAMPI",
  "SAN GIORGIO A CREMANO",
  "SAN GIORGIO A LIRI",
  "SAN GIORGIO DEL SANNIO",
  "SAN GIORGIO DELLA RICHINVELDA",
  "SAN GIORGIO DELLE PERTICHE",
  "SAN GIORGIO DI LOMELLINA",
  "SAN GIORGIO DI NOGARO",
  "SAN GIORGIO DI PIANO",
  "SAN GIORGIO IN BOSCO",
  "SAN GIORGIO SU LEGNANO",
  "SAN GIORIO DI SUSA",
  "SAN GIOVANNI BIANCO",
  "SAN GIOVANNI GEMINI",
  "SAN GIOVANNI ILARIONE",
  "SAN GIOVANNI INCARICO",
  "SAN GIOVANNI LIPIONI",
  "SAN GIOVANNI LUPATOTO",
  "SAN GIOVANNI ROTONDO",
  "SAN GIOVANNI SUERGIU",
  "SAN GIOVANNI TEATINO",
  "SAN GIOVANNI VALDARNO",
  "SAN GIOVANNI A PIRO",
  "SAN GIOVANNI AL NATISONE",
  "SAN GIOVANNI DEL DOSSO",
  "SAN GIOVANNI DI FASSA",
  "SAN GIOVANNI DI GERACE",
  "SAN GIOVANNI IN CROCE",
  "SAN GIOVANNI IN FIORE",
  "SAN GIOVANNI IN GALDO",
  "SAN GIOVANNI IN MARIGNANO",
  "SAN GIOVANNI IN PERSICETO",
  "SAN GIOVANNI LA PUNTA",
  "SAN GIULIANO MILANESE",
  "SAN GIULIANO TERME",
  "SAN GIULIANO DEL SANNIO",
  "SAN GIULIANO DI PUGLIA",
  "SAN GIUSEPPE JATO",
  "SAN GIUSEPPE VESUVIANO",
  "SAN GIUSTINO",
  "SAN GIUSTO CANAVESE",
  "SAN GODENZO",
  "SAN GREGORIO MAGNO",
  "SAN GREGORIO MATESE",
  "SAN GREGORIO D'IPPONA",
  "SAN GREGORIO DA SASSOLA",
  "SAN GREGORIO DI CATANIA",
  "SAN GREGORIO NELLE ALPI",
  "SAN LAZZARO DI SAVENA",
  "SAN LEO",
  "SAN LEONARDO",
  "SAN LEONARDO IN PASSIRIA",
  "SAN LEUCIO DEL SANNIO",
  "SAN LORENZELLO",
  "SAN LORENZO",
  "SAN LORENZO BELLIZZI",
  "SAN LORENZO DORSINO",
  "SAN LORENZO ISONTINO",
  "SAN LORENZO MAGGIORE",
  "SAN LORENZO NUOVO",
  "SAN LORENZO AL MARE",
  "SAN LORENZO DEL VALLO",
  "SAN LORENZO DI SEBATO",
  "SAN LORENZO IN CAMPO",
  "SAN LUCA",
  "SAN LUCIDO",
  "SAN LUPO",
  "SAN MANGO PIEMONTE",
  "SAN MANGO D'AQUINO",
  "SAN MANGO SUL CALORE",
  "SAN MARCELLINO",
  "SAN MARCELLO",
  "SAN MARCELLO PITEGLIO",
  "SAN MARCO ARGENTANO",
  "SAN MARCO EVANGELISTA",
  "SAN MARCO D'ALUNZIO",
  "SAN MARCO DEI CAVOTI",
  "SAN MARCO IN LAMIS",
  "SAN MARCO LA CATOLA",
  "SAN MARTINO ALFIERI",
  "SAN MARTINO BUON ALBERGO",
  "SAN MARTINO CANAVESE",
  "SAN MARTINO SANNITA",
  "SAN MARTINO SICCOMARIO",
  "SAN MARTINO VALLE CAUDINA",
  "SAN MARTINO AL TAGLIAMENTO",
  "SAN MARTINO D'AGRI",
  "SAN MARTINO DALL'ARGINE",
  "SAN MARTINO DEL LAGO",
  "SAN MARTINO DI FINITA",
  "SAN MARTINO DI LUPARI",
  "SAN MARTINO DI VENEZZE",
  "SAN MARTINO IN BADIA",
  "SAN MARTINO IN PASSIRIA",
  "SAN MARTINO IN PENSILIS",
  "SAN MARTINO IN RIO",
  "SAN MARTINO IN STRADA",
  "SAN MARTINO SULLA MARRUCINA",
  "SAN MARZANO OLIVETO",
  "SAN MARZANO DI SAN GIUSEPPE",
  "SAN MARZANO SUL SARNO",
  "SAN MASSIMO",
  "SAN MAURIZIO CANAVESE",
  "SAN MAURIZIO D'OPAGLIO",
  "SAN MAURO CASTELVERDE",
  "SAN MAURO CILENTO",
  "SAN MAURO FORTE",
  "SAN MAURO MARCHESATO",
  "SAN MAURO PASCOLI",
  "SAN MAURO TORINESE",
  "SAN MAURO DI SALINE",
  "SAN MAURO LA BRUCA",
  "SAN MICHELE MONDOVÌ",
  "SAN MICHELE SALENTINO",
  "SAN MICHELE AL TAGLIAMENTO",
  "SAN MICHELE ALL'ADIGE",
  "SAN MICHELE DI GANZARIA",
  "SAN MICHELE DI SERINO",
  "SAN MINIATO",
  "SAN NAZZARO",
  "SAN NAZZARO SESIA",
  "SAN NAZZARO VAL CAVARGNA",
  "SAN NICANDRO GARGANICO",
  "SAN NICOLA ARCELLA",
  "SAN NICOLA BARONIA",
  "SAN NICOLA MANFREDI",
  "SAN NICOLA DA CRISSA",
  "SAN NICOLA DELL'ALTO",
  "SAN NICOLA LA STRADA",
  "SAN NICOLÒ GERREI",
  "SAN NICOLÒ D'ARCIDANO",
  "SAN NICOLÒ DI COMELICO",
  "SAN PANCRAZIO",
  "SAN PANCRAZIO SALENTINO",
  "SAN PAOLO",
  "SAN PAOLO ALBANESE",
  "SAN PAOLO BEL SITO",
  "SAN PAOLO SOLBRITO",
  "SAN PAOLO D'ARGON",
  "SAN PAOLO DI CIVITATE",
  "SAN PAOLO DI JESI",
  "SAN PELLEGRINO TERME",
  "SAN PIER NICETO",
  "SAN PIER D'ISONZO",
  "SAN PIERO PATTI",
  "SAN PIETRO APOSTOLO",
  "SAN PIETRO AVELLANA",
  "SAN PIETRO CLARENZA",
  "SAN PIETRO INFINE",
  "SAN PIETRO MOSEZZO",
  "SAN PIETRO MUSSOLINO",
  "SAN PIETRO VAL LEMINA",
  "SAN PIETRO VERNOTICO",
  "SAN PIETRO VIMINARIO",
  "SAN PIETRO A MAIDA",
  "SAN PIETRO AL NATISONE",
  "SAN PIETRO AL TANAGRO",
  "SAN PIETRO DI CADORE",
  "SAN PIETRO DI CARIDÀ",
  "SAN PIETRO DI FELETTO",
  "SAN PIETRO DI MORUBIO",
  "SAN PIETRO IN AMANTEA",
  "SAN PIETRO IN CARIANO",
  "SAN PIETRO IN CASALE",
  "SAN PIETRO IN CERRO",
  "SAN PIETRO IN GU",
  "SAN PIETRO IN GUARANO",
  "SAN PIETRO IN LAMA",
  "SAN PIO DELLE CAMERE",
  "SAN POLO MATESE",
  "SAN POLO D'ENZA",
  "SAN POLO DEI CAVALIERI",
  "SAN POLO DI PIAVE",
  "SAN PONSO",
  "SAN POSSIDONIO",
  "SAN POTITO SANNITICO",
  "SAN POTITO ULTRA",
  "SAN PRISCO",
  "SAN PROCOPIO",
  "SAN PROSPERO",
  "SAN QUIRICO D'ORCIA",
  "SAN QUIRINO",
  "SAN RAFFAELE CIMENA",
  "SAN ROBERTO",
  "SAN ROCCO AL PORTO",
  "SAN ROMANO IN GARFAGNANA",
  "SAN RUFO",
  "SAN SALVATORE MONFERRATO",
  "SAN SALVATORE TELESINO",
  "SAN SALVATORE DI FITALIA",
  "SAN SALVO",
  "SAN SEBASTIANO CURONE",
  "SAN SEBASTIANO AL VESUVIO",
  "SAN SEBASTIANO DA PO",
  "SAN SECONDO PARMENSE",
  "SAN SECONDO DI PINEROLO",
  "SAN SEVERINO LUCANO",
  "SAN SEVERINO MARCHE",
  "SAN SEVERO",
  "SAN SIRO",
  "SAN SOSSIO BARONIA",
  "SAN SOSTENE",
  "SAN SOSTI",
  "SAN SPERATE",
  "SAN STINO DI LIVENZA",
  "SAN TAMMARO",
  "SAN TEODORO",
  "SAN TOMASO AGORDINO",
  "SAN VALENTINO TORIO",
  "SAN VALENTINO IN ABRUZZO CITERIORE",
  "SAN VENANZO",
  "SAN VENDEMIANO",
  "SAN VERO MILIS",
  "SAN VINCENZO",
  "SAN VINCENZO LA COSTA",
  "SAN VINCENZO VALLE ROVETO",
  "SAN VITALIANO",
  "SAN VITO",
  "SAN VITO CHIETINO",
  "SAN VITO LO CAPO",
  "SAN VITO ROMANO",
  "SAN VITO AL TAGLIAMENTO",
  "SAN VITO AL TORRE",
  "SAN VITO DEI NORMANNI",
  "SAN VITO DI CADORE",
  "SAN VITO DI FAGAGNA",
  "SAN VITO DI LEGUZZANO",
  "SAN VITO SULLO IONIO",
  "SAN VITTORE OLONA",
  "SAN VITTORE DEL LAZIO",
  "SAN ZENO NAVIGLIO",
  "SAN ZENO DI MONTAGNA",
  "SAN ZENONE AL LAMBRO",
  "SAN ZENONE AL PO",
  "SAN ZENONE DEGLI EZZELINI",
  "SANARICA",
  "SANDIGLIANO",
  "SANDRIGO",
  "SANFRONT",
  "SANFRÈ",
  "SANGANO",
  "SANGIANO",
  "SANGINETO",
  "SANGUINETTO",
  "SANLURI",
  "SANNAZZARO DE' BURGONDI",
  "SANNICANDRO DI BARI",
  "SANNICOLA",
  "SANREMO",
  "SANSEPOLCRO",
  "SANT'AGAPITO",
  "SANT'AGATA BOLOGNESE",
  "SANT'AGATA FELTRIA",
  "SANT'AGATA FOSSILI",
  "SANT'AGATA DE' GOTI",
  "SANT'AGATA DEL BIANCO",
  "SANT'AGATA DI ESARO",
  "SANT'AGATA DI MILITELLO",
  "SANT'AGATA DI PUGLIA",
  "SANT'AGATA LI BATTIATI",
  "SANT'AGATA SUL SANTERNO",
  "SANT'AGNELLO",
  "SANT'ALBANO STURA",
  "SANT'ALESSIO SICULO",
  "SANT'ALESSIO CON VIALONE",
  "SANT'ALESSIO IN ASPROMONTE",
  "SANT'ALFIO",
  "SANT'AMBROGIO DI TORINO",
  "SANT'AMBROGIO DI VALPOLICELLA",
  "SANT'AMBROGIO SUL GARIGLIANO",
  "SANT'ANASTASIA",
  "SANT'ANATOLIA DI NARCO",
  "SANT'ANDREA APOSTOLO DELLO IONIO",
  "SANT'ANDREA FRIUS",
  "SANT'ANDREA DEL GARIGLIANO",
  "SANT'ANDREA DI CONZA",
  "SANT'ANGELO LE FRATTE",
  "SANT'ANGELO LIMOSANO",
  "SANT'ANGELO LODIGIANO",
  "SANT'ANGELO LOMELLINA",
  "SANT'ANGELO MUXARO",
  "SANT'ANGELO ROMANO",
  "SANT'ANGELO A CUPOLO",
  "SANT'ANGELO A FASANELLA",
  "SANT'ANGELO A SCALA",
  "SANT'ANGELO ALL'ESCA",
  "SANT'ANGELO D'ALIFE",
  "SANT'ANGELO DEI LOMBARDI",
  "SANT'ANGELO DEL PESCO",
  "SANT'ANGELO DI BROLO",
  "SANT'ANGELO DI PIOVE DI SACCO",
  "SANT'ANGELO IN PONTANO",
  "SANT'ANGELO IN VADO",
  "SANT'ANNA ARRESI",
  "SANT'ANNA D'ALFAEDO",
  "SANT'ANTIMO",
  "SANT'ANTIOCO",
  "SANT'ANTONINO DI SUSA",
  "SANT'ANTONIO ABATE",
  "SANT'ANTONIO DI GALLURA",
  "SANT'APOLLINARE",
  "SANT'ARCANGELO",
  "SANT'ARCANGELO TRIMONTE",
  "SANT'ARPINO",
  "SANT'ARSENIO",
  "SANT'EGIDIO ALLA VIBRATA",
  "SANT'EGIDIO DEL MONTE ALBINO",
  "SANT'ELENA",
  "SANT'ELENA SANNITA",
  "SANT'ELIA FIUMERAPIDO",
  "SANT'ELIA A PIANISI",
  "SANT'ELPIDIO A MARE",
  "SANT'EUFEMIA A MAIELLA",
  "SANT'EUFEMIA D'ASPROMONTE",
  "SANT'EUSANIO FORCONESE",
  "SANT'EUSANIO DEL SANGRO",
  "SANT'ILARIO D'ENZA",
  "SANT'ILARIO DELLO IONIO",
  "SANT'IPPOLITO",
  "SANT'OLCESE",
  "SANT'OMERO",
  "SANT'OMOBONO TERME",
  "SANT'ONOFRIO",
  "SANT'ORESTE",
  "SANT'ORSOLA TERME",
  "SANT'URBANO",
  "SANTA BRIGIDA",
  "SANTA CATERINA ALBANESE",
  "SANTA CATERINA VILLARMOSA",
  "SANTA CATERINA DELLO IONIO",
  "SANTA CESAREA TERME",
  "SANTA CRISTINA GELA",
  "SANTA CRISTINA VALGARDENA",
  "SANTA CRISTINA D'ASPROMONTE",
  "SANTA CRISTINA E BISSONE",
  "SANTA CROCE CAMERINA",
  "SANTA CROCE DEL SANNIO",
  "SANTA CROCE DI MAGLIANO",
  "SANTA CROCE SULL'ARNO",
  "SANTA DOMENICA TALAO",
  "SANTA DOMENICA VITTORIA",
  "SANTA ELISABETTA",
  "SANTA FIORA",
  "SANTA FLAVIA",
  "SANTA GIULETTA",
  "SANTA GIUSTA",
  "SANTA GIUSTINA",
  "SANTA GIUSTINA IN COLLE",
  "SANTA LUCE",
  "SANTA LUCIA DEL MELA",
  "SANTA LUCIA DI PIAVE",
  "SANTA LUCIA DI SERINO",
  "SANTA MARGHERITA LIGURE",
  "SANTA MARGHERITA DI BELICE",
  "SANTA MARGHERITA DI STAFFORA",
  "SANTA MARIA CAPUA VETERE",
  "SANTA MARIA COGHINAS",
  "SANTA MARIA HOÈ",
  "SANTA MARIA IMBARO",
  "SANTA MARIA MAGGIORE",
  "SANTA MARIA NUOVA",
  "SANTA MARIA A MONTE",
  "SANTA MARIA A VICO",
  "SANTA MARIA DEL CEDRO",
  "SANTA MARIA DEL MOLISE",
  "SANTA MARIA DELLA VERSA",
  "SANTA MARIA DI LICODIA",
  "SANTA MARIA DI SALA",
  "SANTA MARIA LA CARITÀ",
  "SANTA MARIA LA FOSSA",
  "SANTA MARIA LA LONGA",
  "SANTA MARINA",
  "SANTA MARINA SALINA",
  "SANTA MARINELLA",
  "SANTA NINFA",
  "SANTA PAOLINA",
  "SANTA SEVERINA",
  "SANTA SOFIA",
  "SANTA SOFIA D'EPIRO",
  "SANTA TERESA GALLURA",
  "SANTA TERESA DI RIVA",
  "SANTA VENERINA",
  "SANTA VITTORIA D'ALBA",
  "SANTA VITTORIA IN MATENANO",
  "SANTADI",
  "SANTARCANGELO DI ROMAGNA",
  "SANTE MARIE",
  "SANTENA",
  "SANTERAMO IN COLLE",
  "SANTHIÀ",
  "SANTI COSMA E DAMIANO",
  "SANTO STEFANO BELBO",
  "SANTO STEFANO LODIGIANO",
  "SANTO STEFANO QUISQUINA",
  "SANTO STEFANO ROERO",
  "SANTO STEFANO TICINO",
  "SANTO STEFANO AL MARE",
  "SANTO STEFANO D'AVETO",
  "SANTO STEFANO DEL SOLE",
  "SANTO STEFANO DI CADORE",
  "SANTO STEFANO DI CAMASTRA",
  "SANTO STEFANO DI MAGRA",
  "SANTO STEFANO DI ROGLIANO",
  "SANTO STEFANO DI SESSANIO",
  "SANTO STEFANO IN ASPROMONTE",
  "SANTOMENNA",
  "SANTOPADRE",
  "SANTORSO",
  "SANTU LUSSURGIU",
  "SANZA",
  "SANZENO",
  "SAONARA",
  "SAPONARA",
  "SAPPADA",
  "SAPRI",
  "SARACENA",
  "SARACINESCO",
  "SARCEDO",
  "SARCONI",
  "SARDARA",
  "SARDIGLIANO",
  "SAREGO",
  "SARENTINO",
  "SAREZZANO",
  "SAREZZO",
  "SARMATO",
  "SARMEDE",
  "SARNANO",
  "SARNICO",
  "SARNO",
  "SARNONICO",
  "SARONNO",
  "SARRE",
  "SARROCH",
  "SARSINA",
  "SARTEANO",
  "SARTIRANA LOMELLINA",
  "SARULE",
  "SARZANA",
  "SASSANO",
  "SASSARI",
  "SASSELLO",
  "SASSETTA",
  "SASSINORO",
  "SASSO MARCONI",
  "SASSO DI CASTALDA",
  "SASSOCORVARO AUDITORE",
  "SASSOFELTRIO",
  "SASSOFERRATO",
  "SASSUOLO",
  "SATRIANO",
  "SATRIANO DI LUCANIA",
  "SAURIS",
  "SAUZE D'OULX",
  "SAUZE DI CESANA",
  "SAVA",
  "SAVELLI",
  "SAVIANO",
  "SAVIGLIANO",
  "SAVIGNANO IRPINO",
  "SAVIGNANO SUL PANARO",
  "SAVIGNANO SUL RUBICONE",
  "SAVIGNONE",
  "SAVIORE DELL'ADAMELLO",
  "SAVOCA",
  "SAVOGNA",
  "SAVOGNA D'ISONZO",
  "SAVOIA DI LUCANIA",
  "SAVONA",
  "SCAFA",
  "SCAFATI",
  "SCAGNELLO",
  "SCALA",
  "SCALA COELI",
  "SCALDASOLE",
  "SCALEA",
  "SCALENGHE",
  "SCALETTA ZANCLEA",
  "SCAMPITELLA",
  "SCANDALE",
  "SCANDIANO",
  "SCANDICCI",
  "SCANDOLARA RAVARA",
  "SCANDOLARA RIPA D'OGLIO",
  "SCANDRIGLIA",
  "SCANNO",
  "SCANO DI MONTIFERRO",
  "SCANSANO",
  "SCANZANO JONICO",
  "SCANZOROSCIATE",
  "SCAPOLI",
  "SCARLINO",
  "SCARMAGNO",
  "SCARNAFIGI",
  "SCARPERIA E SAN PIERO",
  "SCENA",
  "SCERNI",
  "SCHEGGIA E PASCELUPO",
  "SCHEGGINO",
  "SCHIAVI DI ABRUZZO",
  "SCHIAVON",
  "SCHIGNANO",
  "SCHILPARIO",
  "SCHIO",
  "SCHIVENOGLIA",
  "SCIACCA",
  "SCIARA",
  "SCICLI",
  "SCIDO",
  "SCIGLIANO",
  "SCILLA",
  "SCILLATO",
  "SCIOLZE",
  "SCISCIANO",
  "SCLAFANI BAGNI",
  "SCONTRONE",
  "SCOPA",
  "SCOPELLO",
  "SCOPPITO",
  "SCORDIA",
  "SCORRANO",
  "SCORZÈ",
  "SCURCOLA MARSICANA",
  "SCURELLE",
  "SCURZOLENGO",
  "SEBORGA",
  "SECINARO",
  "SECLÌ",
  "SECUGNAGO",
  "SEDEGLIANO",
  "SEDICO",
  "SEDILO",
  "SEDINI",
  "SEDRIANO",
  "SEDRINA",
  "SEFRO",
  "SEGARIU",
  "SEGGIANO",
  "SEGNI",
  "SEGONZANO",
  "SEGRATE",
  "SEGUSINO",
  "SELARGIUS",
  "SELCI",
  "SELEGAS",
  "SELLA GIUDICARIE",
  "SELLANO",
  "SELLERO",
  "SELLIA",
  "SELLIA MARINA",
  "SELVA DEI MOLINI",
  "SELVA DI CADORE",
  "SELVA DI PROGNO",
  "SELVA DI VAL GARDENA",
  "SELVAZZANO DENTRO",
  "SELVINO",
  "SEMESTENE",
  "SEMIANA",
  "SEMINARA",
  "SEMPRONIANO",
  "SENAGO",
  "SENALE-SAN FELICE",
  "SENALES",
  "SENEGHE",
  "SENERCHIA",
  "SENIGA",
  "SENIGALLIA",
  "SENIS",
  "SENISE",
  "SENNA COMASCO",
  "SENNA LODIGIANA",
  "SENNARIOLO",
  "SENNORI",
  "SENORBÌ",
  "SEPINO",
  "SEQUALS",
  "SERAVEZZA",
  "SERDIANA",
  "SEREGNO",
  "SEREN DEL GRAPPA",
  "SERGNANO",
  "SERIATE",
  "SERINA",
  "SERINO",
  "SERLE",
  "SERMIDE E FELONICA",
  "SERMONETA",
  "SERNAGLIA DELLA BATTAGLIA",
  "SERNIO",
  "SEROLE",
  "SERRA RICCÒ",
  "SERRA SAN BRUNO",
  "SERRA SAN QUIRICO",
  "SERRA SANT'ABBONDIO",
  "SERRA D'AIELLO",
  "SERRA DE' CONTI",
  "SERRACAPRIOLA",
  "SERRADIFALCO",
  "SERRALUNGA D'ALBA",
  "SERRALUNGA DI CREA",
  "SERRAMANNA",
  "SERRAMAZZONI",
  "SERRAMEZZANA",
  "SERRAMONACESCA",
  "SERRAPETRONA",
  "SERRARA FONTANA",
  "SERRASTRETTA",
  "SERRATA",
  "SERRAVALLE LANGHE",
  "SERRAVALLE PISTOIESE",
  "SERRAVALLE SCRIVIA",
  "SERRAVALLE SESIA",
  "SERRAVALLE A PO",
  "SERRAVALLE DI CHIENTI",
  "SERRE",
  "SERRENTI",
  "SERRI",
  "SERRONE",
  "SERSALE",
  "SERVIGLIANO",
  "SESSA AURUNCA",
  "SESSA CILENTO",
  "SESSAME",
  "SESSANO DEL MOLISE",
  "SESTA GODANO",
  "SESTINO",
  "SESTO",
  "SESTO CALENDE",
  "SESTO CAMPANO",
  "SESTO FIORENTINO",
  "SESTO SAN GIOVANNI",
  "SESTO AL REGHENA",
  "SESTO ED UNITI",
  "SESTOLA",
  "SESTRI LEVANTE",
  "SESTRIERE",
  "SESTU",
  "SETTALA",
  "SETTEFRATI",
  "SETTIME",
  "SETTIMO MILANESE",
  "SETTIMO ROTTARO",
  "SETTIMO SAN PIETRO",
  "SETTIMO TORINESE",
  "SETTIMO VITTONE",
  "SETTINGIANO",
  "SETZU",
  "SEUI",
  "SEULO",
  "SEVESO",
  "SEZZADIO",
  "SEZZE",
  "SFRUZ",
  "SGONICO",
  "SGURGOLA",
  "SIAMAGGIORE",
  "SIAMANNA",
  "SIANO",
  "SIAPICCIA",
  "SICIGNANO DEGLI ALBURNI",
  "SICULIANA",
  "SIDDI",
  "SIDERNO",
  "SIENA",
  "SIGILLO",
  "SIGNA",
  "SILANDRO",
  "SILANUS",
  "SILEA",
  "SILIGO",
  "SILIQUA",
  "SILIUS",
  "SILLANO GIUNCUGNANO",
  "SILLAVENGO",
  "SILVANO PIETRA",
  "SILVANO D'ORBA",
  "SILVI",
  "SIMALA",
  "SIMAXIS",
  "SIMBARIO",
  "SIMERI CRICHI",
  "SINAGRA",
  "SINALUNGA",
  "SINDIA",
  "SINI",
  "SINIO",
  "SINISCOLA",
  "SINNAI",
  "SINOPOLI",
  "SIRACUSA",
  "SIRIGNANO",
  "SIRIS",
  "SIRMIONE",
  "SIROLO",
  "SIRONE",
  "SIRTORI",
  "SISSA TRECASALI",
  "SIURGUS DONIGALA",
  "SIZIANO",
  "SIZZANO",
  "SLUDERNO",
  "SMERILLO",
  "SOAVE",
  "SOCCHIEVE",
  "SODDÌ",
  "SOGLIANO CAVOUR",
  "SOGLIANO AL RUBICONE",
  "SOGLIO",
  "SOIANO DEL LAGO",
  "SOLAGNA",
  "SOLARINO",
  "SOLARO",
  "SOLAROLO",
  "SOLAROLO RAINERIO",
  "SOLARUSSA",
  "SOLBIATE ARNO",
  "SOLBIATE OLONA",
  "SOLBIATE CON CAGNO",
  "SOLDANO",
  "SOLEMINIS",
  "SOLERO",
  "SOLESINO",
  "SOLETO",
  "SOLFERINO",
  "SOLIERA",
  "SOLIGNANO",
  "SOLOFRA",
  "SOLONGHELLO",
  "SOLOPACA",
  "SOLTO COLLINA",
  "SOLZA",
  "SOMAGLIA",
  "SOMANO",
  "SOMMA LOMBARDO",
  "SOMMA VESUVIANA",
  "SOMMACAMPAGNA",
  "SOMMARIVA PERNO",
  "SOMMARIVA DEL BOSCO",
  "SOMMATINO",
  "SOMMO",
  "SONA",
  "SONCINO",
  "SONDALO",
  "SONDRIO",
  "SONGAVAZZO",
  "SONICO",
  "SONNINO",
  "SORA",
  "SORAGA DI FASSA",
  "SORAGNA",
  "SORANO",
  "SORBO SAN BASILE",
  "SORBO SERPICO",
  "SORBOLO MEZZANI",
  "SORDEVOLO",
  "SORDIO",
  "SORESINA",
  "SORGONO",
  "SORGÀ",
  "SORI",
  "SORIANELLO",
  "SORIANO CALABRO",
  "SORIANO NEL CIMINO",
  "SORICO",
  "SORISO",
  "SORISOLE",
  "SORMANO",
  "SORRADILE",
  "SORRENTO",
  "SORSO",
  "SORTINO",
  "SOSPIRO",
  "SOSPIROLO",
  "SOSSANO",
  "SOSTEGNO",
  "SOTTO IL MONTE GIOVANNI XXIII",
  "SOVER",
  "SOVERATO",
  "SOVERE",
  "SOVERIA MANNELLI",
  "SOVERIA SIMERI",
  "SOVERZENE",
  "SOVICILLE",
  "SOVICO",
  "SOVIZZO",
  "SOVRAMONTE",
  "SOZZAGO",
  "SPADAFORA",
  "SPADOLA",
  "SPARANISE",
  "SPARONE",
  "SPECCHIA",
  "SPELLO",
  "SPERLINGA",
  "SPERLONGA",
  "SPERONE",
  "SPESSA",
  "SPEZZANO ALBANESE",
  "SPEZZANO DELLA SILA",
  "SPIAZZO",
  "SPIGNO MONFERRATO",
  "SPIGNO SATURNIA",
  "SPILAMBERTO",
  "SPILIMBERGO",
  "SPILINGA",
  "SPINADESCO",
  "SPINAZZOLA",
  "SPINEA",
  "SPINEDA",
  "SPINETE",
  "SPINETO SCRIVIA",
  "SPINETOLI",
  "SPINO D'ADDA",
  "SPINONE AL LAGO",
  "SPINOSO",
  "SPIRANO",
  "SPOLETO",
  "SPOLTORE",
  "SPONGANO",
  "SPORMAGGIORE",
  "SPORMINORE",
  "SPOTORNO",
  "SPRESIANO",
  "SPRIANA",
  "SQUILLACE",
  "SQUINZANO",
  "STAFFOLO",
  "STAGNO LOMBARDO",
  "STAITI",
  "STALETTÌ",
  "STANGHELLA",
  "STARANZANO",
  "STATTE",
  "STAZZANO",
  "STAZZEMA",
  "STAZZONA",
  "STEFANACONI",
  "STELLA",
  "STELLA CILENTO",
  "STELLANELLO",
  "STELVIO",
  "STENICO",
  "STERNATIA",
  "STEZZANO",
  "STIENTA",
  "STIGLIANO",
  "STIGNANO",
  "STILO",
  "STIMIGLIANO",
  "STINTINO",
  "STIO",
  "STORNARA",
  "STORNARELLA",
  "STORO",
  "STRA",
  "STRADELLA",
  "STRAMBINELLO",
  "STRAMBINO",
  "STRANGOLAGALLI",
  "STREGNA",
  "STREMBO",
  "STRESA",
  "STREVI",
  "STRIANO",
  "STRONA",
  "STRONCONE",
  "STRONGOLI",
  "STROPPIANA",
  "STROPPO",
  "STROZZA",
  "STURNO",
  "SUARDI",
  "SUBBIANO",
  "SUBIACO",
  "SUCCIVO",
  "SUEGLIO",
  "SUELLI",
  "SUELLO",
  "SUISIO",
  "SULBIATE",
  "SULMONA",
  "SULZANO",
  "SUMIRAGO",
  "SUMMONTE",
  "SUNI",
  "SUNO",
  "SUPERSANO",
  "SUPINO",
  "SURANO",
  "SURBO",
  "SUSA",
  "SUSEGANA",
  "SUSTINENTE",
  "SUTERA",
  "SUTRI",
  "SUTRIO",
  "SUVERETO",
  "SUZZARA",
  "TACENO",
  "TADASUNI",
  "TAGGIA",
  "TAGLIACOZZO",
  "TAGLIO DI PO",
  "TAGLIOLO MONFERRATO",
  "TAIBON AGORDINO",
  "TAINO",
  "TAIPANA",
  "TALAMELLO",
  "TALAMONA",
  "TALANA",
  "TALEGGIO",
  "TALLA",
  "TALMASSONS",
  "TAMBRE",
  "TAORMINA",
  "TARANO",
  "TARANTA PELIGNA",
  "TARANTASCA",
  "TARANTO",
  "TARCENTO",
  "TARQUINIA",
  "TARSIA",
  "TARTANO",
  "TARVISIO",
  "TARZO",
  "TASSAROLO",
  "TAURANO",
  "TAURASI",
  "TAURIANOVA",
  "TAURISANO",
  "TAVAGNACCO",
  "TAVAGNASCO",
  "TAVAZZANO CON VILLAVESCO",
  "TAVENNA",
  "TAVERNA",
  "TAVERNERIO",
  "TAVERNOLA BERGAMASCA",
  "TAVERNOLE SUL MELLA",
  "TAVIANO",
  "TAVIGLIANO",
  "TAVOLETO",
  "TAVULLIA",
  "TEANA",
  "TEANO",
  "TEGGIANO",
  "TEGLIO",
  "TEGLIO VENETO",
  "TELESE TERME",
  "TELGATE",
  "TELTI",
  "TELVE",
  "TELVE DI SOPRA",
  "TEMPIO PAUSANIA",
  "TEMÙ",
  "TENNA",
  "TENNO",
  "TEOLO",
  "TEORA",
  "TERAMO",
  "TERDOBBIATE",
  "TERELLE",
  "TERENTO",
  "TERENZO",
  "TERGU",
  "TERLANO",
  "TERLIZZI",
  "TERME VIGLIATORE",
  "TERMENO SULLA STRADA DEL VINO",
  "TERMINI IMERESE",
  "TERMOLI",
  "TERNATE",
  "TERNENGO",
  "TERNI",
  "TERNO D'ISOLA",
  "TERRACINA",
  "TERRAGNOLO",
  "TERRALBA",
  "TERRANOVA SAPPO MINULIO",
  "TERRANOVA DA SIBARI",
  "TERRANOVA DEI PASSERINI",
  "TERRANOVA DI POLLINO",
  "TERRANUOVA BRACCIOLINI",
  "TERRASINI",
  "TERRASSA PADOVANA",
  "TERRAVECCHIA",
  "TERRAZZO",
  "TERRE ROVERESCHE",
  "TERRE D'ADIGE",
  "TERRE DEL RENO",
  "TERRICCIOLA",
  "TERRUGGIA",
  "TERTENIA",
  "TERZIGNO",
  "TERZO",
  "TERZO D'AQUILEIA",
  "TERZOLAS",
  "TERZORIO",
  "TESERO",
  "TESIMO",
  "TESSENNANO",
  "TESTICO",
  "TETI",
  "TEULADA",
  "TEVEROLA",
  "TEZZE SUL BRENTA",
  "THIENE",
  "THIESI",
  "TIANA",
  "TICENGO",
  "TICINETO",
  "TIGGIANO",
  "TIGLIETO",
  "TIGLIOLE",
  "TIGNALE",
  "TINNURA",
  "TIONE DEGLI ABRUZZI",
  "TIONE DI TRENTO",
  "TIRANO",
  "TIRES",
  "TIRIOLO",
  "TIROLO",
  "TISSI",
  "TITO",
  "TIVOLI",
  "TIZZANO VAL PARMA",
  "TOANO",
  "TOCCO CAUDIO",
  "TOCCO DA CASAURIA",
  "TOCENO",
  "TODI",
  "TOFFIA",
  "TOIRANO",
  "TOLENTINO",
  "TOLFA",
  "TOLLEGNO",
  "TOLLO",
  "TOLMEZZO",
  "TOLVE",
  "TOMBOLO",
  "TON",
  "TONARA",
  "TONCO",
  "TONEZZA DEL CIMONE",
  "TORA E PICCILLI",
  "TORANO CASTELLO",
  "TORANO NUOVO",
  "TORBOLE CASAGLIA",
  "TORCEGNO",
  "TORCHIARA",
  "TORCHIAROLO",
  "TORELLA DEI LOMBARDI",
  "TORELLA DEL SANNIO",
  "TORGIANO",
  "TORGNON",
  "TORINO",
  "TORINO DI SANGRO",
  "TORITTO",
  "TORLINO VIMERCATI",
  "TORNACO",
  "TORNARECCIO",
  "TORNATA",
  "TORNIMPARTE",
  "TORNO",
  "TORNOLO",
  "TORO",
  "TORPÈ",
  "TORRACA",
  "TORRALBA",
  "TORRAZZA COSTE",
  "TORRAZZA PIEMONTE",
  "TORRAZZO",
  "TORRE ANNUNZIATA",
  "TORRE BERETTI E CASTELLARO",
  "TORRE BOLDONE",
  "TORRE BORMIDA",
  "TORRE CAJETANI",
  "TORRE CANAVESE",
  "TORRE LE NOCELLE",
  "TORRE MONDOVÌ",
  "TORRE ORSAIA",
  "TORRE PALLAVICINA",
  "TORRE PELLICE",
  "TORRE SAN GIORGIO",
  "TORRE SAN PATRIZIO",
  "TORRE SANTA SUSANNA",
  "TORRE D'ARESE",
  "TORRE D'ISOLA",
  "TORRE DE' BUSI",
  "TORRE DE' NEGRI",
  "TORRE DE' PASSERI",
  "TORRE DE' PICENARDI",
  "TORRE DE' ROVERI",
  "TORRE DEL GRECO",
  "TORRE DI MOSTO",
  "TORRE DI RUGGIERO",
  "TORRE DI SANTA MARIA",
  "TORREANO",
  "TORREBELVICINO",
  "TORREBRUNA",
  "TORRECUSO",
  "TORREGLIA",
  "TORREGROTTA",
  "TORREMAGGIORE",
  "TORRENOVA",
  "TORRESINA",
  "TORRETTA",
  "TORREVECCHIA PIA",
  "TORREVECCHIA TEATINA",
  "TORRI DEL BENACO",
  "TORRI DI QUARTESOLO",
  "TORRI IN SABINA",
  "TORRICE",
  "TORRICELLA",
  "TORRICELLA PELIGNA",
  "TORRICELLA SICURA",
  "TORRICELLA VERZATE",
  "TORRICELLA DEL PIZZO",
  "TORRICELLA IN SABINA",
  "TORRIGLIA",
  "TORRILE",
  "TORRIONI",
  "TORRITA TIBERINA",
  "TORRITA DI SIENA",
  "TORTOLÌ",
  "TORTONA",
  "TORTORA",
  "TORTORELLA",
  "TORTORETO",
  "TORTORICI",
  "TORVISCOSA",
  "TOSCOLANO-MADERNO",
  "TOSSICIA",
  "TOVO SAN GIACOMO",
  "TOVO DI SANT'AGATA",
  "TRABIA",
  "TRADATE",
  "TRAMATZA",
  "TRAMBILENO",
  "TRAMONTI",
  "TRAMONTI DI SOPRA",
  "TRAMONTI DI SOTTO",
  "TRAMUTOLA",
  "TRANA",
  "TRANI",
  "TRAONA",
  "TRAPANI",
  "TRAPPETO",
  "TRAREGO VIGGIONA",
  "TRASACCO",
  "TRASAGHIS",
  "TRASQUERA",
  "TRATALIAS",
  "TRAVACÒ SICCOMARIO",
  "TRAVAGLIATO",
  "TRAVEDONA-MONATE",
  "TRAVERSELLA",
  "TRAVERSETOLO",
  "TRAVES",
  "TRAVESIO",
  "TRAVO",
  "TRE VILLE",
  "TREBASELEGHE",
  "TREBISACCE",
  "TRECASE",
  "TRECASTAGNI",
  "TRECASTELLI",
  "TRECATE",
  "TRECCHINA",
  "TRECENTA",
  "TREDOZIO",
  "TREGLIO",
  "TREGNAGO",
  "TREIA",
  "TREISO",
  "TREMESTIERI ETNEO",
  "TREMEZZINA",
  "TREMOSINE SUL GARDA",
  "TRENTINARA",
  "TRENTO",
  "TRENTOLA DUCENTA",
  "TRENZANO",
  "TREPPO GRANDE",
  "TREPPO LIGOSULLO",
  "TREPUZZI",
  "TREQUANDA",
  "TRESANA",
  "TRESCORE BALNEARIO",
  "TRESCORE CREMASCO",
  "TRESIGNANA",
  "TRESIVIO",
  "TRESNURAGHES",
  "TREVENZUOLO",
  "TREVI",
  "TREVI NEL LAZIO",
  "TREVICO",
  "TREVIGLIO",
  "TREVIGNANO",
  "TREVIGNANO ROMANO",
  "TREVILLE",
  "TREVIOLO",
  "TREVISO",
  "TREVISO BRESCIANO",
  "TREZZANO ROSA",
  "TREZZANO SUL NAVIGLIO",
  "TREZZO TINELLA",
  "TREZZO SULL'ADDA",
  "TREZZONE",
  "TRIBANO",
  "TRIBIANO",
  "TRIBOGNA",
  "TRICARICO",
  "TRICASE",
  "TRICERRO",
  "TRICESIMO",
  "TRIEI",
  "TRIESTE",
  "TRIGGIANO",
  "TRIGOLO",
  "TRINITAPOLI",
  "TRINITÀ",
  "TRINITÀ D'AGULTU E VIGNOLA",
  "TRINO",
  "TRIORA",
  "TRIPI",
  "TRISOBBIO",
  "TRISSINO",
  "TRIUGGIO",
  "TRIVENTO",
  "TRIVIGLIANO",
  "TRIVIGNANO UDINESE",
  "TRIVIGNO",
  "TRIVOLZIO",
  "TRODENA NEL PARCO NATURALE",
  "TROFARELLO",
  "TROIA",
  "TROINA",
  "TROMELLO",
  "TRONTANO",
  "TRONZANO LAGO MAGGIORE",
  "TRONZANO VERCELLESE",
  "TROPEA",
  "TROVO",
  "TRUCCAZZANO",
  "TUBRE",
  "TUFARA",
  "TUFILLO",
  "TUFINO",
  "TUFO",
  "TUGLIE",
  "TUILI",
  "TULA",
  "TUORO SUL TRASIMENO",
  "TURANIA",
  "TURANO LODIGIANO",
  "TURATE",
  "TURBIGO",
  "TURI",
  "TURRI",
  "TURRIACO",
  "TURRIVALIGNANI",
  "TURSI",
  "TUSA",
  "TUSCANIA",
  "UBIALE CLANEZZO",
  "UBOLDO",
  "UCRIA",
  "UDINE",
  "UGENTO",
  "UGGIANO LA CHIESA",
  "UGGIATE-TREVANO",
  "ULASSAI",
  "ULTIMO",
  "ULÀ TIRSO",
  "UMBERTIDE",
  "UMBRIATICO",
  "URAGO D'OGLIO",
  "URAS",
  "URBANA",
  "URBANIA",
  "URBE",
  "URBINO",
  "URBISAGLIA",
  "URGNANO",
  "URI",
  "URURI",
  "URZULEI",
  "USCIO",
  "USELLUS",
  "USINI",
  "USMATE VELATE",
  "USSANA",
  "USSARAMANNA",
  "USSASSAI",
  "USSEAUX",
  "USSEGLIO",
  "USSITA",
  "USTICA",
  "UTA",
  "UZZANO",
  "VACCARIZZO ALBANESE",
  "VACONE",
  "VACRI",
  "VADENA",
  "VADO LIGURE",
  "VAGLI SOTTO",
  "VAGLIA",
  "VAGLIO BASILICATA",
  "VAGLIO SERRA",
  "VAIANO",
  "VAIANO CREMASCO",
  "VAIE",
  "VAILATE",
  "VAIRANO PATENORA",
  "VAJONT",
  "VAL BREMBILLA",
  "VAL LIONA",
  "VAL MASINO",
  "VAL REZZO",
  "VAL DELLA TORRE",
  "VAL DI CHY",
  "VAL DI NIZZA",
  "VAL DI VIZZE",
  "VAL DI ZOLDO",
  "VALBONDIONE",
  "VALBREMBO",
  "VALBRENTA",
  "VALBREVENNA",
  "VALBRONA",
  "VALCHIUSA",
  "VALDAGNO",
  "VALDAONE",
  "VALDAORA",
  "VALDASTICO",
  "VALDENGO",
  "VALDERICE",
  "VALDIDENTRO",
  "VALDIERI",
  "VALDILANA",
  "VALDINA",
  "VALDISOTTO",
  "VALDOBBIADENE",
  "VALDUGGIA",
  "VALEGGIO",
  "VALEGGIO SUL MINCIO",
  "VALENTANO",
  "VALENZA",
  "VALENZANO",
  "VALERA FRATTA",
  "VALFABBRICA",
  "VALFENERA",
  "VALFLORIANA",
  "VALFORNACE",
  "VALFURVA",
  "VALGANNA",
  "VALGIOIE",
  "VALGOGLIO",
  "VALGRANA",
  "VALGREGHENTINO",
  "VALGRISENCHE",
  "VALGUARNERA CAROPEPE",
  "VALLADA AGORDINA",
  "VALLANZENGO",
  "VALLARSA",
  "VALLATA",
  "VALLE AGRICOLA",
  "VALLE AURINA",
  "VALLE CANNOBINA",
  "VALLE CASTELLANA",
  "VALLE LOMELLINA",
  "VALLE SALIMBENE",
  "VALLE SAN NICOLAO",
  "VALLE DELL'ANGELO",
  "VALLE DI CADORE",
  "VALLE DI CASIES",
  "VALLE DI MADDALONI",
  "VALLEBONA",
  "VALLECORSA",
  "VALLECROSIA",
  "VALLEDOLMO",
  "VALLEDORIA",
  "VALLEFIORITA",
  "VALLEFOGLIA",
  "VALLELAGHI",
  "VALLELONGA",
  "VALLELUNGA PRATAMENO",
  "VALLEMAIO",
  "VALLEPIETRA",
  "VALLERANO",
  "VALLERMOSA",
  "VALLEROTONDA",
  "VALLESACCARDA",
  "VALLEVE",
  "VALLI DEL PASUBIO",
  "VALLINFREDA",
  "VALLIO TERME",
  "VALLO TORINESE",
  "VALLO DELLA LUCANIA",
  "VALLO DI NERA",
  "VALLORIATE",
  "VALMACCA",
  "VALMADRERA",
  "VALMONTONE",
  "VALMOREA",
  "VALMOZZOLA",
  "VALNEGRA",
  "VALPELLINE",
  "VALPERGA",
  "VALPRATO SOANA",
  "VALSAMOGGIA",
  "VALSAVARENCHE",
  "VALSINNI",
  "VALSOLDA",
  "VALSTRONA",
  "VALTOPINA",
  "VALTORTA",
  "VALTOURNENCHE",
  "VALVA",
  "VALVARRONE",
  "VALVASONE ARZENE",
  "VALVERDE",
  "VALVESTINO",
  "VANDOIES",
  "VANZAGHELLO",
  "VANZAGO",
  "VANZONE CON SAN CARLO",
  "VAPRIO D'ADDA",
  "VAPRIO D'AGOGNA",
  "VARALLO",
  "VARALLO POMBIA",
  "VARANO BORGHI",
  "VARANO DE' MELEGARI",
  "VARAPODIO",
  "VARAZZE",
  "VARCO SABINO",
  "VAREDO",
  "VARENNA",
  "VARESE",
  "VARESE LIGURE",
  "VARISELLA",
  "VARMO",
  "VARNA",
  "VARSI",
  "VARZI",
  "VARZO",
  "VASANELLO",
  "VASIA",
  "VASTO",
  "VASTOGIRARDI",
  "VAUDA CANAVESE",
  "VAZZANO",
  "VAZZOLA",
  "VECCHIANO",
  "VEDANO OLONA",
  "VEDANO AL LAMBRO",
  "VEDELAGO",
  "VEDESETA",
  "VEDUGGIO CON COLZANO",
  "VEGGIANO",
  "VEGLIE",
  "VEGLIO",
  "VEJANO",
  "VELESO",
  "VELEZZO LOMELLINA",
  "VELLETRI",
  "VELLEZZO BELLINI",
  "VELO VERONESE",
  "VELO D'ASTICO",
  "VELTURNO",
  "VENAFRO",
  "VENARIA REALE",
  "VENAROTTA",
  "VENASCA",
  "VENAUS",
  "VENDONE",
  "VENEGONO INFERIORE",
  "VENEGONO SUPERIORE",
  "VENETICO",
  "VENEZIA",
  "VENIANO",
  "VENOSA",
  "VENTASSO",
  "VENTICANO",
  "VENTIMIGLIA",
  "VENTIMIGLIA DI SICILIA",
  "VENTOTENE",
  "VENZONE",
  "VERANO",
  "VERANO BRIANZA",
  "VERBANIA",
  "VERBICARO",
  "VERCANA",
  "VERCEIA",
  "VERCELLI",
  "VERCURAGO",
  "VERDELLINO",
  "VERDELLO",
  "VERDERIO",
  "VERDUNO",
  "VERGATO",
  "VERGHERETO",
  "VERGIATE",
  "VERMEZZO CON ZELO",
  "VERMIGLIO",
  "VERNANTE",
  "VERNASCA",
  "VERNATE",
  "VERNAZZA",
  "VERNIO",
  "VERNOLE",
  "VEROLANUOVA",
  "VEROLAVECCHIA",
  "VEROLENGO",
  "VEROLI",
  "VERONA",
  "VERONELLA",
  "VERRAYES",
  "VERRETTO",
  "VERRONE",
  "VERRUA PO",
  "VERRUA SAVOIA",
  "VERRÈS",
  "VERTEMATE CON MINOPRIO",
  "VERTOVA",
  "VERUCCHIO",
  "VERVIO",
  "VERZEGNIS",
  "VERZINO",
  "VERZUOLO",
  "VESCOVANA",
  "VESCOVATO",
  "VESIME",
  "VESPOLATE",
  "VESSALICO",
  "VESTENANOVA",
  "VESTIGNÈ",
  "VESTONE",
  "VETRALLA",
  "VETTO",
  "VEZZA D'ALBA",
  "VEZZA D'OGLIO",
  "VEZZANO LIGURE",
  "VEZZANO SUL CROSTOLO",
  "VEZZI PORTIO",
  "VIADANA",
  "VIADANICA",
  "VIAGRANDE",
  "VIALE",
  "VIALFRÈ",
  "VIANO",
  "VIAREGGIO",
  "VIARIGI",
  "VIBO VALENTIA",
  "VIBONATI",
  "VICALVI",
  "VICARI",
  "VICCHIO",
  "VICENZA",
  "VICO EQUENSE",
  "VICO DEL GARGANO",
  "VICO NEL LAZIO",
  "VICOFORTE",
  "VICOLI",
  "VICOLUNGO",
  "VICOPISANO",
  "VICOVARO",
  "VIDDALBA",
  "VIDIGULFO",
  "VIDOR",
  "VIDRACCO",
  "VIESTE",
  "VIETRI DI POTENZA",
  "VIETRI SUL MARE",
  "VIGANO SAN MARTINO",
  "VIGANÒ",
  "VIGARANO MAINARDA",
  "VIGASIO",
  "VIGEVANO",
  "VIGGIANELLO",
  "VIGGIANO",
  "VIGGIÙ",
  "VIGHIZZOLO D'ESTE",
  "VIGLIANO BIELLESE",
  "VIGLIANO D'ASTI",
  "VIGNALE MONFERRATO",
  "VIGNANELLO",
  "VIGNATE",
  "VIGNOLA",
  "VIGNOLA-FALESINA",
  "VIGNOLE BORBERA",
  "VIGNOLO",
  "VIGNONE",
  "VIGO DI CADORE",
  "VIGODARZERE",
  "VIGOLO",
  "VIGOLZONE",
  "VIGONE",
  "VIGONOVO",
  "VIGONZA",
  "VIGUZZOLO",
  "VILLA BARTOLOMEA",
  "VILLA BASILICA",
  "VILLA BISCOSSI",
  "VILLA CARCINA",
  "VILLA CASTELLI",
  "VILLA CELIERA",
  "VILLA COLLEMANDINA",
  "VILLA CORTESE",
  "VILLA ESTENSE",
  "VILLA FARALDI",
  "VILLA GUARDIA",
  "VILLA LAGARINA",
  "VILLA LATINA",
  "VILLA LITERNO",
  "VILLA MINOZZO",
  "VILLA SAN GIOVANNI",
  "VILLA SAN GIOVANNI IN TUSCIA",
  "VILLA SAN PIETRO",
  "VILLA SAN SECONDO",
  "VILLA SANT'ANGELO",
  "VILLA SANT'ANTONIO",
  "VILLA SANTA LUCIA",
  "VILLA SANTA LUCIA DEGLI ABRUZZI",
  "VILLA SANTA MARIA",
  "VILLA SANTINA",
  "VILLA SANTO STEFANO",
  "VILLA VERDE",
  "VILLA D'ADDA",
  "VILLA D'ALMÈ",
  "VILLA D'OGNA",
  "VILLA DEL BOSCO",
  "VILLA DEL CONTE",
  "VILLA DI BRIANO",
  "VILLA DI CHIAVENNA",
  "VILLA DI SERIO",
  "VILLA DI TIRANO",
  "VILLABASSA",
  "VILLABATE",
  "VILLACHIARA",
  "VILLACIDRO",
  "VILLADEATI",
  "VILLADOSE",
  "VILLADOSSOLA",
  "VILLAFALLETTO",
  "VILLAFRANCA PADOVANA",
  "VILLAFRANCA PIEMONTE",
  "VILLAFRANCA SICULA",
  "VILLAFRANCA TIRRENA",
  "VILLAFRANCA D'ASTI",
  "VILLAFRANCA DI VERONA",
  "VILLAFRANCA IN LUNIGIANA",
  "VILLAFRATI",
  "VILLAGA",
  "VILLAGRANDE STRISAILI",
  "VILLALAGO",
  "VILLALBA",
  "VILLALFONSINA",
  "VILLALVERNIA",
  "VILLAMAGNA",
  "VILLAMAINA",
  "VILLAMAR",
  "VILLAMARZANA",
  "VILLAMASSARGIA",
  "VILLAMIROGLIO",
  "VILLANDRO",
  "VILLANOVA BIELLESE",
  "VILLANOVA CANAVESE",
  "VILLANOVA MARCHESANA",
  "VILLANOVA MONDOVÌ",
  "VILLANOVA MONFERRATO",
  "VILLANOVA MONTELEONE",
  "VILLANOVA SOLARO",
  "VILLANOVA TRUSCHEDU",
  "VILLANOVA TULO",
  "VILLANOVA D'ALBENGA",
  "VILLANOVA D'ARDENGHI",
  "VILLANOVA D'ASTI",
  "VILLANOVA DEL BATTISTA",
  "VILLANOVA DEL GHEBBO",
  "VILLANOVA DEL SILLARO",
  "VILLANOVA DI CAMPOSAMPIERO",
  "VILLANOVA SULL'ARDA",
  "VILLANOVAFORRU",
  "VILLANOVAFRANCA",
  "VILLANTERIO",
  "VILLANUOVA SUL CLISI",
  "VILLAPERUCCIO",
  "VILLAPIANA",
  "VILLAPUTZU",
  "VILLAR DORA",
  "VILLAR FOCCHIARDO",
  "VILLAR PELLICE",
  "VILLAR PEROSA",
  "VILLAR SAN COSTANZO",
  "VILLARBASSE",
  "VILLARBOIT",
  "VILLAREGGIA",
  "VILLARICCA",
  "VILLAROMAGNANO",
  "VILLAROSA",
  "VILLASALTO",
  "VILLASANTA",
  "VILLASIMIUS",
  "VILLASOR",
  "VILLASPECIOSA",
  "VILLASTELLONE",
  "VILLATA",
  "VILLAURBANA",
  "VILLAVALLELONGA",
  "VILLAVERLA",
  "VILLE D'ANAUNIA",
  "VILLE DI FIEMME",
  "VILLENEUVE",
  "VILLESSE",
  "VILLETTA BARREA",
  "VILLETTE",
  "VILLIMPENTA",
  "VILLONGO",
  "VILLORBA",
  "VILMINORE DI SCALVE",
  "VIMERCATE",
  "VIMODRONE",
  "VINADIO",
  "VINCHIATURO",
  "VINCHIO",
  "VINCI",
  "VINOVO",
  "VINZAGLIO",
  "VIOLA",
  "VIONE",
  "VIPITENO",
  "VIRLE PIEMONTE",
  "VISANO",
  "VISCHE",
  "VISCIANO",
  "VISCO",
  "VISONE",
  "VISSO",
  "VISTARINO",
  "VISTRORIO",
  "VITA",
  "VITERBO",
  "VITICUSO",
  "VITO D'ASIO",
  "VITORCHIANO",
  "VITTORIA",
  "VITTORIO VENETO",
  "VITTORITO",
  "VITTUONE",
  "VITULANO",
  "VITULAZIO",
  "VIVARO",
  "VIVARO ROMANO",
  "VIVERONE",
  "VIZZINI",
  "VIZZOLA TICINO",
  "VIZZOLO PREDABISSI",
  "VIÙ",
  "VO'",
  "VOBARNO",
  "VOBBIA",
  "VOCCA",
  "VODO CADORE",
  "VOGHERA",
  "VOGHIERA",
  "VOGOGNA",
  "VOLANO",
  "VOLLA",
  "VOLONGO",
  "VOLPAGO DEL MONTELLO",
  "VOLPARA",
  "VOLPEDO",
  "VOLPEGLINO",
  "VOLPIANO",
  "VOLTA MANTOVANA",
  "VOLTAGGIO",
  "VOLTAGO AGORDINO",
  "VOLTERRA",
  "VOLTIDO",
  "VOLTURARA APPULA",
  "VOLTURARA IRPINA",
  "VOLTURINO",
  "VOLVERA",
  "VOTTIGNASCO",
  "ZACCANOPOLI",
  "ZAFFERANA ETNEA",
  "ZAGARISE",
  "ZAGAROLO",
  "ZAMBRONE",
  "ZANDOBBIO",
  "ZANICA",
  "ZANÈ",
  "ZAPPONETA",
  "ZAVATTARELLO",
  "ZECCONE",
  "ZEDDIANI",
  "ZELBIO",
  "ZELO BUON PERSICO",
  "ZEME",
  "ZENEVREDO",
  "ZENSON DI PIAVE",
  "ZERBA",
  "ZERBO",
  "ZERBOLÒ",
  "ZERFALIU",
  "ZERI",
  "ZERMEGHEDO",
  "ZERO BRANCO",
  "ZEVIO",
  "ZIANO PIACENTINO",
  "ZIANO DI FIEMME",
  "ZIBIDO SAN GIACOMO",
  "ZIGNAGO",
  "ZIMELLA",
  "ZIMONE",
  "ZINASCO",
  "ZOAGLI",
  "ZOCCA",
  "ZOGNO",
  "ZOLA PREDOSA",
  "ZOLLINO",
  "ZONE",
  "ZOPPOLA",
  "ZOPPÈ DI CADORE",
  "ZOVENCEDO",
  "ZUBIENA",
  "ZUCCARELLO",
  "ZUGLIANO",
  "ZUGLIO",
  "ZUMAGLIA",
  "ZUMPANO",
  "ZUNGOLI",
  "ZUNGRI",
];

export { comuni, nazioni };
