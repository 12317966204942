import React from "react";
import clsx from "clsx";
import { OptionType, SelectProps } from "../types";

export default function Select({
  register,
  name,
  values,
  label,
  className,
  error,
  defaultValue,
  readOnly,
  readOnlyValue,
}: SelectProps) {
  return (
    <div>
      {label && (
        <label className="label" htmlFor={name}>
          <span className="label-text">{label}</span>
        </label>
      )}
      {!readOnly ? (
        <select
          className={clsx(
            "block select select-bordered select-sm w-full",
            className
          )}
          {...register(name)}
          name={name}
          defaultValue={defaultValue}
        >
          {values.map((option: string | OptionType) => {
            if (typeof option === "object") {
              const { label: labelOption, value, disabled } = option;
              return (
                <option key={value} value={value} disabled={disabled}>
                  {labelOption || value}
                </option>
              );
            }
            return (
              <option key={option} value={option}>
                {label || option}
              </option>
            );
          })}
        </select>
      ) : (
        <p className="subtitle1 pb-2 pt-1">{readOnlyValue}</p>
      )}
      {error && (
        <span className="text-xs text-red-700 inline-block">{error}</span>
      )}
    </div>
  );
}
