import { Toaster } from "react-hot-toast";

function AppToaster({ containerStyle }) {
  return (
    <Toaster
      position="bottom-right"
      containerStyle={containerStyle}
      toastOptions={{
        // width 100% - larghezza sidebar (320px) su desktop
        className: "w-full lg:w-[calc(100%-320px)]",
        style: {
          color: "#FFF",
          maxWidth: "none",
          borderRadius: 0,
        },
        icon: "",
        success: {
          style: {
            background: "#005D98",
          },
        },
        error: {
          style: {
            background: "#D32F2F",
          },
        },
      }}
    />
  );
}

AppToaster.defaultProps = {
  containerStyle: {
    inset: 0,
  },
};

export default AppToaster;
