import { gql } from "@apollo/client";

const updateUserMutation = gql(`
    mutation UpdateUser(
      $input: UserInput
    ) {
      updateUser(
        input: $input
      ) {
        email
        firstName
        lastName
        company
      }
    }
  `);

export default updateUserMutation;
