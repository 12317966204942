import { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import useDebounce from "helpers/useDebounce";
import { AutocompleteProps } from "../types";

export default function Autocomplete({
  suggestions,
  className,
  register,
  name,
  label,
  readOnly,
  readOnlyValue,
  setValue,
  value,
  error,
  placeholder,
}: AutocompleteProps) {
  // input testuale dell'utente
  const [inputValue, setInputValue] = useState("");
  // suggestions da mostrare
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  // Determina se ho appena selezionato un item dalla finestra dell'autocomplete
  const refClicked = useRef(false);

  const debouncedSearchTerm = useDebounce(inputValue, 300);
  const onChange = (e) => {
    refClicked.current = false;
    setValue(name, "");
    setInputValue(e.currentTarget.value);
  };

  useEffect(() => {
    // Se ho appena selezionato un item dalla finestra dell'autocomplete, esco
    if (refClicked.current === true) {
      return;
    }

    if (debouncedSearchTerm.length < 3) {
      setFilteredSuggestions([]);
      return;
    }
    const newFilteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toUpperCase().substring(0, debouncedSearchTerm.length) ===
        debouncedSearchTerm.toUpperCase()
    );
    setFilteredSuggestions(newFilteredSuggestions);
  }, [debouncedSearchTerm]);

  const onClick = (e) => {
    refClicked.current = true;
    setFilteredSuggestions([]);
    setInputValue(e.currentTarget.innerText);
    setValue(name, e.currentTarget.innerText);
  };

  const ref = useRef(null);

  useEffect(() => {
    // Chiudo la select dell'autocomplete quando clicco al di fuori dell'input text.
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilteredSuggestions([]);
        setInputValue("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (readOnly && !readOnlyValue) {
    return null;
  }

  return (
    <div ref={ref} className="form-control w-full relative">
      {label && (
        <label className="label" htmlFor={name}>
          {label && <span className="label-text">{label}</span>}
        </label>
      )}

      {!readOnly ? (
        <>
          <input type="hidden" {...register(name)} />
          <input
            className={clsx(
              "block input input-sm input-bordered w-full",
              className
            )}
            type="text"
            onChange={onChange}
            value={
              value && suggestions.includes(value.toUpperCase())
                ? value
                : inputValue
            }
            autoComplete="off"
            placeholder={placeholder}
          />
          {filteredSuggestions.length > 0 && inputValue.length > 0 && (
            <ul className="suggestions">
              {filteredSuggestions.map((suggestion) => {
                return (
                  <li
                    key={suggestion}
                    onClick={onClick}
                    onKeyDown={onClick}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    tabIndex={0}
                  >
                    {suggestion}
                  </li>
                );
              })}
            </ul>
          )}{" "}
        </>
      ) : (
        <p className="subtitle1 pb-2 pt-1">{readOnlyValue}</p>
      )}
      {error && (
        <span className="text-xs text-red-700 inline-block mt-1">{error}</span>
      )}
    </div>
  );
}
