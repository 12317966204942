import { gql } from "@apollo/client";

const getProfileInfo = gql(`query getProfileData {
  profile: me {
    firstName
    lastName
    company
    email
    city
    nazione
    phone
    age
    sesso
    lavoro
    settore
    tipologia
    area
    privacyTerzi
    privacyProfilazione
    qualifica
  }
}`);

export default getProfileInfo;
