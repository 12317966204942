import { OptionType } from "./types";

export const AgeFields: OptionType[] = [
  { label: "Seleziona età", value: "", disabled: true },
  { label: "Fino a 18 anni", value: "Fino a 18 anni" },
  { label: "19-25", value: "19-25" },
  { label: "26-35", value: "26-35" },
  { label: "36-45", value: "36-45" },
  { label: "46-55", value: "46-55" },
  { label: "56-65", value: "56-65" },
  { label: "oltre 65", value: "oltre 65" },
];

export const Settore: OptionType[] = [
  { label: "Seleziona settore", value: "", disabled: true },
  { label: "Pubblico", value: "Pubblico" },
  { label: "Privato", value: "Privato" },
  { label: "Terzo Settore", value: "Terzo Settore" },
  { label: "Non Occupato/Altro", value: "Non Occupato/Altro" },
];

export const AreaPubblica: OptionType[] = [
  { label: "Seleziona area", value: "", disabled: true },
  { label: "Affari Generali", value: "Affari Generali" },
  { label: "Altro", value: "Altro" },
  {
    label: "Comunicazione, Relazioni Istituzionali, Ufficio stampa",
    value: "Comunicazione, Relazioni Istituzionali, Ufficio stampa",
  },
  { label: "Direzione Generale", value: "Direzione Generale" },
  {
    label: "Organismo Indipendente di Valutazione",
    value: "Organismo Indipendente di Valutazione",
  },
  {
    label: "Risorse Umane, Organizzazione",
    value: "Risorse Umane, Organizzazione",
  },
  {
    label: "Relazioni Internazionali e Cooperazione",
    value: "Relazioni Internazionali e Cooperazione",
  },
  { label: "Sistemi Informativi", value: "Sistemi Informativi" },
  {
    label: "Uffici di Diretta Collaborazione",
    value: "Uffici di Diretta Collaborazione",
  },
  {
    label: "Ufficio Legislativo/Legale",
    value: "Ufficio Legislativo/Legale",
  },
  { label: "Acquisti, Procurement", value: "Acquisti, Procurement" },
  { label: "Bilancio", value: "Bilancio" },
];

export const AreaPrivata: OptionType[] = [
  { label: "Seleziona area", value: "", disabled: true },
  { label: "Altro", value: "Altro" },
  {
    label: "Commerciale, Business Development",
    value: "Commerciale, Business Development",
  },
  { label: "Marketing, Comunicazione", value: "Marketing, Comunicazione" },
  {
    label: "Amministrazione, Finanza, Controllo",
    value: "Amministrazione, Finanza, Controllo",
  },
  {
    label: "Risorse Umane, Organizzazione",
    value: "Risorse Umane, Organizzazione",
  },
  { label: "Ricerca e Sviluppo", value: "Ricerca e Sviluppo" },
  { label: "Sistemi Informativi", value: "Sistemi Informativi" },
  { label: "Produzione/Qualita'", value: "Produzione/Qualita'" },
  { label: "Ufficio Legale", value: "Ufficio Legale" },
  { label: "Acquisti, Procurement", value: "Acquisti, Procurement" },
  { label: "Logistica, Supply Chain", value: "Logistica, Supply Chain" },
  { label: "Professionista", value: "Professionista" },
  { label: "Top Management", value: "Top Management" },
];

export const SettorePubblico: OptionType[] = [
  { label: "Seleziona tipologia", value: "", disabled: true },
  { label: "Agenzia", value: "Agenzia" },
  { label: "Altro", value: "Altro" },
  { label: "Camera di Commercio", value: "Camera di Commercio" },
  { label: "Consorzio", value: "Consorzio" },
  { label: "Enti/Associazioni", value: "Enti/Associazioni" },
  { label: "Istituzione europea", value: "Istituzione europea" },
  { label: "Istruzione/Formazione", value: "Istruzione/Formazione" },
  { label: "Multiutility", value: "Multiutility" },
  { label: "PAC", value: "PAC" },
  { label: "PAL", value: "PAL" },
  {
    label: "Parchi Distretti Consorzi Patti",
    value: "Parchi Distretti Consorzi Patti",
  },
  { label: "Sanità - SSN", value: "Sanità - SSN" },
  {
    label: "Società a Partecipazione Pubblica",
    value: "Società a Partecipazione Pubblica",
  },
];

export const SettorePrivato: OptionType[] = [
  { label: "Seleziona tipologia", value: "", disabled: true },
  { label: "Agricoltura", value: "Agricoltura" },
  { label: "Altro", value: "Altro" },
  { label: "Automotive", value: "Automotive" },
  { label: "Chimico/Farmaceutico", value: "Chimico/Farmaceutico" },
  {
    label: "Commercio al dettaglio e ingrosso",
    value: "Commercio al dettaglio e ingrosso",
  },
  { label: "Consorzio", value: "Consorzio" },
  {
    label: "Edilizia/Costruzioni/Immobiliare",
    value: "Edilizia/Costruzioni/Immobiliare",
  },
  { label: "Enti/Associazioni", value: "Enti/Associazioni" },
  { label: "Finanza/Assicurazioni", value: "Finanza/Assicurazioni" },
  { label: "ICT & Telco", value: "ICT & Telco" },
  { label: "Istruzione/Formazione", value: "Istruzione/Formazione" },
  { label: "Logistica/Trasporti", value: "Logistica/Trasporti" },
  { label: "Manifatturiero/Produzione", value: "Manifatturiero/Produzione" },
  { label: "Media", value: "Media" },
  {
    label: "Professionisti/Studi professionali",
    value: "Professionisti/Studi professionali",
  },
  { label: "Pubblicita", value: "Pubblicita" },
  { label: "Sanita", value: "Sanita" },
  {
    label: "Servizi di consulenza per le aziende",
    value: "Servizi di consulenza per le aziende",
  },
  { label: "Servizi", value: "Servizi" },
  { label: "Turismo/Hospitality/Food", value: "Turismo/Hospitality/Food" },
  { label: "Utility/Energy", value: "Utility/Energy" },
];

export const Qualifica: OptionType[] = [
  { label: "Seleziona qualifica", value: "", disabled: true },
  { label: "addetto", value: "addetto" },
  { label: "addetto/responsabile", value: "addetto/responsabile" },
  { label: "altro", value: "altro" },
  { label: "amministratore delegato", value: "amministratore delegato" },
  { label: "assessore", value: "assessore" },
  { label: "consigliere", value: "consigliere" },
  { label: "consulente", value: "consulente" },
  {
    label: "consulente/libero professionista",
    value: "consulente/libero professionista",
  },
  { label: "deputato/senatore", value: "deputato/senatore" },
  { label: "direttore generale", value: "direttore generale" },
  {
    label: "direttore generale/vice direttore generale",
    value: "direttore generale/vice direttore generale",
  },
  { label: "direttore", value: "direttore" },
  { label: "direttore/alto ufficiale", value: "direttore/alto ufficiale" },
  {
    label: "dirigente/ufficiale superiore",
    value: "dirigente/ufficiale superiore",
  },
  { label: "esperto", value: "esperto" },
  { label: "funzionario/ufficiale", value: "funzionario/ufficiale" },
  { label: "impiegato", value: "impiegato" },
  {
    label: "insegnante/docente/ricercatore",
    value: "insegnante/docente/ricercatore",
  },
  { label: "magistrato", value: "magistrato" },
  { label: "manager", value: "manager" },
  { label: "presidente", value: "presidente" },
  { label: "presidente/vice presidente", value: "presidente/vice presidente" },
  { label: "responsabile", value: "responsabile" },
  {
    label: "segretario generale/vice segretario generale",
    value: "segretario generale/vice segretario generale",
  },
  { label: "sindaco/vice sindaco", value: "sindaco/vice sindaco" },
  { label: "vice direttore generale", value: "vice direttore generale" },
  { label: "vice direttore", value: "vice direttore" },
  { label: "vice presidente", value: "vice presidente" },
  { label: "volontario", value: "volontario" },
];
