import { useState, useEffect } from "react";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Aggiorno il valore dopo un delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancella il timeout se i valori sono cambiati (anche quando il delay cambia o all'unmount)
      // Questo evita di aggiornare il valore debounces su ogni update entro il periodo di delay. Il timeout
      // infatti viene rimosso e resettato.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Viene richiamata solo quando il valore o il delay cambiano.
  );
  return debouncedValue;
};

export default useDebounce;
