import React from "react";
import clsx from "clsx";
import { CheckboxProps } from "../types";

export default function Checkbox({
  register,
  containerClassname,
  className,
  label,
  error,
  name,
  children,
  ...rest
}: CheckboxProps) {
  return (
    <>
      <div className={clsx(containerClassname)}>
        <label htmlFor={name} className="flex">
          <input
            id={name}
            {...register(name)}
            type="checkbox"
            className={clsx(
              "checkbox checkbox-sm mr-2 align-middle bottom-[1px] relative",
              className
            )}
            {...rest}
          />
          {children}
        </label>
      </div>
      {error && (
        <span className="text-xs text-red-700 inline-block mt-1">{error}</span>
      )}
    </>
  );
}
